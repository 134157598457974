import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CodeIcon, XCircleIcon, ClipboardCopyIcon, ClockIcon, TrashIcon } from '@heroicons/react/outline'
import { DeleteClientTokenUseCase, IAccessToken } from '@axelity/actasign-sic'
import { useRecoilState, useRecoilValue } from 'recoil'
import { apiKeyListState, userInfoState } from '../recoil/atoms'
import { message } from 'antd'
import moment from 'moment'

type ApiKeyItemProps = {
  apiKey: IAccessToken
}
const ApiKeyItem = ({ apiKey }: ApiKeyItemProps) => {
  const { t } = useTranslation(['apiKeyItem'])

  const userInfo = useRecoilValue(userInfoState)
  const [apiKeys, setApiKeys] = useRecoilState(apiKeyListState)

  const history = useHistory()

  let expires = ''
  if (apiKey && apiKey.durationDays) {
    expires = Intl.DateTimeFormat(userInfo.language, { dateStyle: 'medium' }).format(
      moment(apiKey.created).add(apiKey.durationDays, 'days').toDate(),
    )

    if (moment(apiKey.created).add(apiKey.durationDays, 'days').toDate() < new Date()) {
      apiKey.status = 'SUSPENDED'
    }
  }

  const handleCopyToClipboard = () => {
    const cb = navigator.clipboard
    cb.writeText(apiKey.tokenCode).then(() => message.info(t('apiKeyItem:copySuccessful')))
  }

  const handleDelete = () => {
    new DeleteClientTokenUseCase({
      tokenId: apiKey._id,
    })
      .delete()
      .then(() => {
        setApiKeys([
          ...apiKeys.filter((_apiKey) => {
            return _apiKey._id !== apiKey._id
          }),
        ])
        history.push('/settings/apikeys')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <div className="container my-2 border-2 shadow-sm rounded-md border-gray-200 hover:bg-gray-50 hover:border-acs-blue-600">
      <div className="flex justify-start p-2">
        <CodeIcon className="w-7 h-7 text-acs-blue-600" />
        <div className="ml-5 w-full">
          <div className="flex justify-between items-center">
            <div className="flex flex-row justify-start">
              <div className="truncate text-md font-semibold">{apiKey.displayName}</div>
            </div>
            <div className="flex justify-end">
              <div
                className={`rounded-md ml-2 px-2 py-1 ${
                  apiKey.status === 'ACTIVE' ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'
                }  text-xs font-semibold`}
              >
                {t(`apiKeyItem:status.${apiKey.status.toLowerCase()}`)}
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-1">
            <div className="text-xs font-semibold">{apiKey.description}</div>
            <div className="flex">
              <ClockIcon className="w-4 h-4 mr-1" />
              <div className="text-xs">{expires}</div>
            </div>
          </div>
        </div>
      </div>

      {/**
       * Actions
       */}
      <div className="flex justify-end rounded-b-md w-full pr-2 bg-acs-blue-50">
        {apiKey.status === 'ACTIVE' ? (
          <div className="flex">
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleCopyToClipboard}
            >
              <ClipboardCopyIcon className="w-4 h-4 mr-1" />
              {t('apiKeyItem:action.copyToClipboard')}
            </div>
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleDelete}
            >
              <TrashIcon className="w-4 h-4 mr-1" />
              {t('apiKeyItem:action.remove')}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ApiKeyItem
