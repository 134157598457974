/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Button, Input, message } from 'antd'
import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { useRecoilState } from 'recoil'
import { userInfoState } from '../recoil/atoms'
import { ConfirmUserEmailUseCase, GetUserUseCase, VerifyUserEmailUseCase } from '@axelity/actasign-sic'
import SectionTitle from '../elements/SectionTitle'
import FieldTitle from '../elements/FieldTitle'
import * as yup from 'yup'
import useSignIn from '../hooks/useSignIn'

const ProfileEmail = () => {
  const { t } = useTranslation('profileEmail')

  const [noAccessToken] = useSignIn()

  const confirmationCodeRef = useRef(null)

  const history = useHistory()

  const [userInfo, setUserInfo] = useRecoilState(userInfoState)

  const [emailAddress, setEmailAddress] = useState<string>('')
  const [confirmationCode, setConfirmationCode] = useState<string>('')
  const [validEmail, setValidEmail] = useState<boolean>(true)
  const [toBeConfirmed, setToBeConfirmed] = useState<boolean>(false)
  const [isConfirmed, setIsConfirmed] = useState<boolean>(true)

  type TFormErrors = {
    emailAddress: string
  }

  const [formErrors, setFormErrors] = useState<TFormErrors>({
    emailAddress: '',
  })

  const validateForm = (): boolean => {
    let valid = true
    let errors: TFormErrors = {
      emailAddress: '',
    }

    if (!yup.string().required().matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).required().isValidSync(emailAddress)) {
      errors = { ...errors, emailAddress: t('profileEmail:validation.emailRequired') }
      valid = false
    }

    setFormErrors(errors)

    return valid
  }

  const handleChangeEmail = (value: string) => {
    setEmailAddress(value)
    setIsConfirmed(false)
    if (value.trim() === '') {
      setValidEmail(false)
      return
    }

    if (!yup.string().trim().required().required().matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).isValidSync(emailAddress)) {
      setValidEmail(false)
    } else {
      setValidEmail(true)
    }
  }

  const confirmationHandler = async (e: any) => {
    e.preventDefault()
    new ConfirmUserEmailUseCase({
      userId: userInfo._id as string,
    })
      .confirm({
        challengeResponse: confirmationCode,
        emailAddress,
      })
      .then(() => {
        setIsConfirmed(true)
        setToBeConfirmed(false)
        setConfirmationCode('')
        message.success(t('verificationSuccessful'))
      })
      .then(() => {
        return new GetUserUseCase().getUser()
      })
      .then((userInfo) => {
        setUserInfo(userInfo)
        history.push('/home')
      })
      .catch((error) => {
        message.error(t('verificationFailed'))
        console.error(error)
      })
  }

  const verifyHandler = async (e: any) => {
    e.preventDefault()
    if (!validateForm()) return

    new VerifyUserEmailUseCase({
      userId: userInfo._id as string,
    })
      .verify({
        emailAddress,
        language: userInfo.language,
      })
      .then(() => {
        setToBeConfirmed(true)
        confirmationCodeRef.current.focus()
        message.info(t('messageSent'))
      })
  }

  const backHandler = () => {
    history.push('/home')
  }

  useEffect(() => {
    if (userInfo) {
      setEmailAddress(userInfo.email ? (userInfo.email.emailAddress as string) : '')
    }
  }, [userInfo])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('profile:emailAddress.name')}
        pages={[
          {
            name: t('profile:userProfile.name'),
          },
          {
            name: t('profile:emailAddress.name'),
          },
        ]}
      />

      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        <SectionTitle title={t('title')} />

        <FieldTitle title={t('title')} />
        <div className="inline-flex w-full">
          <Input
            className="rounded"
            autoComplete="email"
            type="email"
            size="large"
            placeholder={t('title')}
            value={emailAddress}
            onChange={(e) => handleChangeEmail(e.target.value)}
            onPressEnter={(e) => verifyHandler(e)}
          ></Input>

          <Button
            disabled={!validEmail || toBeConfirmed}
            className="ml-2 rounded font-semibold"
            size="large"
            type="primary"
            onClick={(e) => verifyHandler(e)}
          >
            {t('verify')}
          </Button>
        </div>
        <div className="my-1 font-normal text-md text-red-500">{formErrors.emailAddress}</div>

        <div>
          <p className="mt-2">{t('instructions')}</p>
        </div>

        <div className="mt-10">
          <FieldTitle title={t('confirmationCode')} />
          <div className="inline-flex mt-1">
            <Input
              ref={confirmationCodeRef}
              disabled={!toBeConfirmed}
              className="rounded"
              type="text"
              size="large"
              placeholder={t('confirmationCode')}
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              onPressEnter={confirmationHandler}
            ></Input>

            <div>
              {toBeConfirmed ? (
                <Button className="ml-2 rounded font-semibold" size="large" type="primary" onClick={confirmationHandler}>
                  {t('profileEmail:confirm')}
                </Button>
              ) : null}
            </div>
          </div>

          {toBeConfirmed ? (
            <div className="flex mt-2">
              <p>{t('codeNotReceived')}</p>
              <p className="ml-2 underline text-antd-blue">{t('sendAgain')}</p>
            </div>
          ) : null}

          {isConfirmed ? (
            <div className="flex flex-auto mt-4 justify-end">
              <Button className="ml-2 rounded font-semibold" size="large" type="ghost" onClick={backHandler}>
                {t('profileEmail:back')}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ProfileEmail
