/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { activityListState, activityState, requestState, showRequestActivityDialogState } from '../recoil/atoms'
import RequestActivity from './RequestActivity'
import NoData from './NoData'
import ActivityItem from './ActivityItem'
import { useEffect, useState } from 'react'
import { AssignProcessActivityOrderUseCase, IActivity, QueryProcessesUseCase } from '@axelity/actasign-sic'
import SectionTitle from '../elements/SectionTitle'

type TActivtyListProps = {
  canAdd?: boolean
  canChange?: boolean
  canDelete?: boolean
  canRearrange?: boolean
}

const ActivityList = ({
  canAdd = false,
  canChange = false,
  canDelete = false,
  canRearrange = false,
}: TActivtyListProps) => {
  const { t } = useTranslation(['activityList'])

  const [request, setRequest] = useRecoilState(requestState)
  const resetActivity = useResetRecoilState(activityState)
  const activityList = useRecoilValue(activityListState)

  const [orderedActivityList, setOrderedActivityList] = useState<IActivity[]>([])

  const [showRequestActivityDialog, setShowRequestActivityDialog] = useRecoilState(showRequestActivityDialogState)

  const handleShowNewActivityComponent = () => {
    resetActivity()
    setShowRequestActivityDialog(true)
  }

  const handleApplyActivity = () => {
    setShowRequestActivityDialog(false)
  }

  const handleCancelActivity = () => {
    setShowRequestActivityDialog(false)
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return

    let items = [...orderedActivityList]
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    const activityIds = items.map((_activity) => {
      return _activity._id
    })

    new AssignProcessActivityOrderUseCase({
      processId: request._id,
    })
      .assign({
        activities: activityIds,
      })
      .then(() => {
        return new QueryProcessesUseCase().query({
          id: request._id,
        })
      })
      .then((_requests) => {
        setRequest(_requests[0])
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    if (request && activityList) {
      const orderedActivities: IActivity[] = []
      for (let i = 0; i < request.activities.length; i++) {
        const activityId = request.activities[i]
        const activity = activityList.find((_activity) => {
          return _activity._id === activityId
        })
        if (activity) {
          orderedActivities.push(activity)
        }
      }
      setOrderedActivityList(orderedActivities)
    }
  }, [activityList])

  return (
    <div className="w-full">
      {/**
       * Title
       */}
      <div className="flex justify-between items-center">
        <SectionTitle title={t('activityList:title')} />

        {canAdd ? (
          <Button
            className="rounded font-semibold"
            size="large"
            type="primary"
            disabled={showRequestActivityDialog}
            onClick={handleShowNewActivityComponent}
          >
            <div className="flex items-center">
              <PlusOutlined />
              <p className="ml-2">{t('activityList:newActivity')}</p>
            </div>
          </Button>
        ) : null}
      </div>

      {/**
       * Add/change activity
       */}
      <RequestActivity onApply={handleApplyActivity} onCancel={handleCancelActivity} />

      {!showRequestActivityDialog ? (
        <div>
          {orderedActivityList && orderedActivityList.length > 0 ? (
            <div className="container max-w-4xl mt-4">
              {canRearrange ? (
                <div>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="processActivity">
                      {(provided) => (
                        <div className="processActivity" {...provided.droppableProps} ref={provided.innerRef}>
                          {orderedActivityList.map((activity, index) => {
                            return (
                              <Draggable key={activity._id} draggableId={activity._id} index={index}>
                                {(provided) => (
                                  <div
                                    className="processActivity"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <ActivityItem activity={activity} canChange={canChange} canDelete={canDelete} />
                                  </div>
                                )}
                              </Draggable>
                            )
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              ) : (
                <div>
                  {orderedActivityList.map((activity) => {
                    return (
                      <ActivityItem
                        key={activity._id}
                        activity={activity}
                        canChange={canChange}
                        canDelete={canDelete}
                      />
                    )
                  })}
                </div>
              )}
            </div>
          ) : (
            <NoData note={t('activityList:noActivities')} />
          )}
        </div>
      ) : null}
    </div>
  )
}

export default ActivityList
