/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { dragObjectShiftState, visualState } from '../../recoil/atoms'
import { TVisualImage } from '../../types/appTypes'
import TextLine from './TextLine'
import ImageItem from './ImageItem'
import { cloneDeep } from 'lodash'

const VisualPreview = () => {
  const [visual, setVisual] = useRecoilState(visualState)

  const shift = useRecoilValue(dragObjectShiftState)

  const [customStyle, setCustomStyle] = useState<React.CSSProperties>({})

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    // Retrieve data provided by dragging
    const data = JSON.parse(e.dataTransfer.getData('text/plain'))

    // Specify position (top left corner) of the text line within the preview
    // The shift values are determined when drag of text line starts
    const left = e.nativeEvent.offsetX - shift.shiftX
    const top = e.nativeEvent.offsetY - shift.shiftY

    const _visual = cloneDeep(visual)
    if (data.type === 'textLine') {
      if (_visual.textLines) {
        _visual.textLines[data.lineNo] = {
          displayText: data.textLine.displayText,
          fontStyle: data.textLine.fontStyle,
          x: left,
          y: top,
        }

        setVisual(_visual)
      }
    } else {
      if (_visual.images) {
        _visual.images[data.imageNo] = {
          imageDataUrl: data.image.imageDataUrl,
          height: data.image.height,
          width: data.image.width,
          x: left,
          y: top,
        }

        setVisual(_visual)
      }
    }
  }

  useEffect(() => {
    if (visual) {
      let style: React.CSSProperties = {
        width: '720px',
        height: '200px',
        position: 'relative',
        borderWidth: '1px',
        borderColor: '#f3f4f6',
      }
      if (visual.border && visual.border.isVisible) {
        if (visual.border.width) {
          style = Object.assign(style, { borderWidth: visual.border.width })
        }
        if (visual.border.colorHex) {
          style = Object.assign(style, { borderColor: visual.border.colorHex })
        }
      }
      setCustomStyle(style)
    }
  }, [visual])

  return (
    <div>
      {visual ? (
        <div style={customStyle} onDragOver={handleDragOver} onDrop={handleDrop}>
          {visual && visual.background && visual.background.imageDataUrl ? (
            <img className="object-none w-full z-10 absolute" src={visual.background.imageDataUrl} alt="background" />
          ) : null}

          {visual && visual.background && visual.background.colorHex ? (
            <div style={{ backgroundColor: `${visual.background.colorHex}` }} className="w-full h-full absolute" />
          ) : null}

          {visual.textLines?.map((textLine, index) => {
            return <TextLine key={`textLine_${index}`} lineNo={index} textLine={textLine} />
          })}

          {visual.images?.map((_image: TVisualImage, index: number) => {
            return <ImageItem key={`image_${index}`} imageNo={index} image={_image} />
          })}
        </div>
      ) : null}
    </div>
  )
}

export default VisualPreview
