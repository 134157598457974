import { TSignatureFields } from '../types/appTypes'
import SignatureField from './SignatureField'

type TSignatureFieldListProps = {
  fields: TSignatureFields
}

const SignatureFieldList = (props: TSignatureFieldListProps) => {
  return (
    <div id="signatureFieldList" className="m-2 p-2 grid grid-cols-1 gap-2 auto-rows-min">
      {props.fields.map((field) => {
        return <SignatureField key={field.taskId} fieldKey={field.taskId as string} field={field} />
      })}
    </div>
  )
}

export default SignatureFieldList
