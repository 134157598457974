import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { currentProcessStepState, requestState } from '../../recoil/atoms'
import { documentListState } from '../../recoil/atoms'
import DocumentList from '../DocumentList'

const RequestStep3 = () => {
  const { t } = useTranslation(['common', 'request', 'settings'])

  const request = useRecoilValue(requestState)
  const documentList = useRecoilValue(documentListState)
  const setCurrentProcessStep = useSetRecoilState(currentProcessStepState)

  const nextHandler = () => {
    setCurrentProcessStep(3)
  }

  const previousHandler = () => {
    setCurrentProcessStep(1)
  }

  return (
    <div>
      <div className="flex flex-auto my-8 justify-between">
        <Button className="rounded font-semibold" size="large" type="primary" onClick={previousHandler}>
          <div className="inline-flex items-center whitespace-nowrap">
            <LeftCircleOutlined />
            <p className="ml-2">{t('request:step.activities.title')}</p>
          </div>
        </Button>

        {request && request.status === 'PENDING' && documentList.length > 0 ? (
          <Button className="rounded font-semibold" size="large" type="primary" onClick={nextHandler}>
            <div className="inline-flex items-center whitespace-nowrap">
              <p className="mr-2">{t('request:step.send.title_alt')}</p>
              <RightCircleOutlined />
            </div>
          </Button>
        ) : null}
      </div>

      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        <DocumentList
          canAdd={request && request.status === 'PENDING'}
          canPrepare={request && request.status === 'PENDING'}
          canRemove={request && request.status === 'PENDING'}
          canDownload={request && request.status !== 'PENDING'}
        />
      </div>
    </div>
  )
}

export default RequestStep3
