/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { TFileInfo } from '../types/appTypes'
import { Spin } from 'antd'
import Dropzone from './Dropzone'
import DocumentItem from './DocumentItem'
import NoData from './NoData'
import {
  currentAccountState,
  currentWorkspaceState,
  documentListState,
  documentState,
  pageListState,
  requestState,
} from '../recoil/atoms'
import { IDocument, RegisterDocumentUseCase, UploadDocumentUseCase } from '@axelity/actasign-sic'
import { useEffect, useState } from 'react'
import SectionTitle from '../elements/SectionTitle'

type TDocumentListProps = {
  canAdd?: boolean
  canView?: boolean
  canPrepare?: boolean
  canDownload?: boolean
  canRemove?: boolean
  showStatus?: boolean
}

const CONTENT_TYPE = 'application/pdf'

const DocumentList = ({
  canAdd = false,
  canRemove = false,
  canDownload = false,
  canPrepare = false,
  canView = false,
  showStatus = true,
}: TDocumentListProps) => {
  const { t } = useTranslation(['documentList'])

  const account = useRecoilValue(currentAccountState)
  const workspace = useRecoilValue(currentWorkspaceState)
  const request = useRecoilValue(requestState)
  const resetDocument = useResetRecoilState(documentState)
  const resetPages = useResetRecoilState(pageListState)

  const [documentList, setDocumentList] = useRecoilState(documentListState)

  const [inProgress, setInProgress] = useState<boolean>(false)

  const handleSelectedFiles = async (files: TFileInfo[]) => {
    setInProgress(true)
    let docs: IDocument[] = [...documentList]
    for (let i = 0; i < files.length; i++) {
      try {
        const _document = await new RegisterDocumentUseCase().register({
          account: account._id,
          workspace: workspace._id,
          process: request._id,
          contentType: CONTENT_TYPE,
          filename: files[i].filename,
          toBeSigned: true,
          displayName: files[i].filename.toLowerCase().endsWith('.pdf')
            ? files[i].filename.substr(0, files[i].filename.length - 4)
            : files[i].filename,
        })

        const content = new Blob([files[i].content as ArrayBuffer])
        await new UploadDocumentUseCase({
          documentId: _document._id,
        }).upload({
          content,
        })

        docs = [...docs, _document]
      } catch (error) {
        console.error(error)
      }
    }

    setDocumentList([...docs])
    setInProgress(false)
  }

  useEffect(() => {
    resetDocument()
    resetPages()
  }, [])

  return (
    <div className="w-full">
      <Spin tip={t('documentList:action.loading')} spinning={inProgress} size="large">
        <SectionTitle title={t('title')} />

        {canAdd ? (
          <Dropzone
            mimeType={CONTENT_TYPE}
            multiple={true}
            onSelect={handleSelectedFiles}
            upLoadInstructions={t('uploadInstructions')}
          />
        ) : null}

        {documentList.length !== 0 ? (
          <div className="flex flex-wrap justify-evenly max-w-4xl mx-auto text-base p-2 mt-4 rounded-md">
            {documentList.map((item) => {
              return (
                <DocumentItem
                  key={item._id}
                  canPrepare={canPrepare}
                  canDownload={canDownload}
                  canRemove={canRemove}
                  canView={canView}
                  content={item}
                  showStatus={showStatus}
                />
              )
            })}
          </div>
        ) : (
          <NoData note={t('noDocuments')} />
        )}
      </Spin>
    </div>
  )
}

export default DocumentList
