import { TFileInfo } from '../types/appTypes'
import { InboxInIcon } from '@heroicons/react/outline'

type TDropzoneProps = {
  mimeType: string
  multiple?: boolean
  upLoadInstructions: string
  onSelect: (selectFiles: TFileInfo[]) => void
}

const Dropzone = ({ mimeType, upLoadInstructions, multiple = false, onSelect }: TDropzoneProps) => {

  const handleDragOver = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    event.dataTransfer.dropEffect = 'copy'
  }

  const openDocumentsDialog = () => {
    const ele = document.getElementById('dropzoneInputFiles')
    if (ele) ele.click()
  }

  const handleDropDocuments = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    let nonPdf = false
    if (event.dataTransfer?.files) {
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        const file = event.dataTransfer?.files[i];
        if (file.type !== mimeType) {
          nonPdf = true
        }
      }
    }

    if (!nonPdf && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      loadFiles(event.dataTransfer.files).then((selectFiles: TFileInfo[]) => {
        onSelect(selectFiles)
      })
    }
  }

  const handleDocumentSelect = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    if (event.target && event.target.files && event.target.files.length > 0) {
      loadFiles(event.target.files).then((selectFiles: TFileInfo[]) => {
        onSelect(selectFiles)
      })
    }
  }

  const loadFiles = async (files: any): Promise<TFileInfo[]> => {
    let loadedFiles: TFileInfo[] = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      let promise: any = new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = (result: any) => {
          let fileInfo: TFileInfo = {
            content: reader.result,
            mimeType: file.type,
            filename: file.name,
            filesize: file.size,
            lastModified: file.lastModified,
          }
          resolve(fileInfo)
        }
        reader.readAsArrayBuffer(file)
      })

      let fileInfo = await promise
      loadedFiles = [...loadedFiles, fileInfo]
    }
    return loadedFiles
  }

  return (
    <div>
      <div
        style={{borderColor: '#1890ff'}}
        className="w-full my-4 relative cursor-pointer p-4 border-2 border-dotted text-acs-blue-600 rounded-md bg-acs-blue-50 flex flex-col content-center items-center justify-center"
        onDragOver={handleDragOver}
        onDrop={handleDropDocuments}
        onClick={openDocumentsDialog}
      >
        <InboxInIcon className="h-6 w-6 text-acs-blue-600" />
        <p className="text-base text-center mt-2">{upLoadInstructions}</p>
        <input
          type="file"
          id="dropzoneInputFiles"
          accept={mimeType}
          multiple={multiple}
          onChange={handleDocumentSelect}
          hidden
        />
      </div>
    </div>
  )
}

export default Dropzone
