/* eslint-disable import/first */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Steps, Spin, Comment, message } from 'antd'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import RequestStep1 from '../components/Request/RequestStep1'
import RequestStep2 from '../components/Request/RequestStep2'
import RequestStep3 from '../components/Request/RequestStep3'
import RequestStep4 from '../components/Request/RequestStep4'
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  accessTokenState,
  userInfoState,
  activityListState,
  commentListState,
  currentProcessStepState,
  documentListState,
  fastSignatureState,
  requestState,
  signatureListState,
  taskListState,
  viewerAddressedByState,
  processingInviteTaskState,
} from '../recoil/atoms'
import useSignIn from '../hooks/useSignIn'
import { useEffect } from 'react'
import {
  QueryActivitiesUseCase,
  QueryCommentsUseCase,
  QueryDocumentsUseCase,
  QueryProcessesUseCase,
  QuerySignaturesUseCase,
  QueryTasksUseCase,
} from '@axelity/actasign-sic'
import SectionTitle from '../elements/SectionTitle'
import { ClockIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import Timeline from '../components/Timeline'
import DocumentList from '../components/DocumentList'

const { Step } = Steps

const Request = () => {
  const search = useLocation().search
  // const authMethod = new URLSearchParams(search).get('auth_method')
  const requestId = new URLSearchParams(search).get('request')
  const queryAccessToken = new URLSearchParams(search).get('access_token')
  const step = new URLSearchParams(search).get('step')

  const { t } = useTranslation(['request'])

  const [noAccessToken] = useSignIn()

  const history = useHistory()

  const [currentProcessStep, setCurrentProcessStep] = useRecoilState(currentProcessStepState)
  const resetAccessToken = useResetRecoilState(accessTokenState)
  const resetUserInfo = useResetRecoilState(userInfoState)

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState)
  const [request, setRequest] = useRecoilState(requestState)
  const resetRequest = useResetRecoilState(requestState)
  const setActivities = useSetRecoilState(activityListState)
  const setTasks = useSetRecoilState(taskListState)
  const [documents, setDocuments] = useRecoilState(documentListState)
  const setSignatures = useSetRecoilState(signatureListState)
  const [comments, setComments] = useRecoilState(commentListState)
  const setViewerAddressedBy = useSetRecoilState(viewerAddressedByState)
  const setFastSignature = useSetRecoilState(fastSignatureState)
  const [processingInviteTask, setProcessingInviteTask] = useRecoilState(processingInviteTaskState)

  const [isLoading, setLoading] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [sender, setSender] = useState<string>('')
  const [created, setCreated] = useState<string>('')
  const [dueOn, setDueOn] = useState<string>('')

  const handleBack = () => {
    resetRequest()
    history.push('/requests')
  }

  useEffect(() => {
    // This is because history.goBack() is not working with other Browsers than Chrome
    setViewerAddressedBy('/request')
    setFastSignature(undefined)

    if (request) {
      setTitle(request.title)

      setCreated(
        Intl.DateTimeFormat(request.ownerInfo.language, { dateStyle: 'medium', timeStyle: 'short' }).format(
          new Date(`${request.created}`),
        ),
      )

      setDueOn(
        Intl.DateTimeFormat(request.ownerInfo.language, { dateStyle: 'medium', timeStyle: 'short' }).format(
          new Date(`${request.dueOn}`),
        ),
      )

      setSender(`${request.ownerInfo.firstName} ${request.ownerInfo.familyName} (${request.ownerInfo.emailAddress})`)

      setLoading(true)
      new QueryActivitiesUseCase()
        .query({
          process: request._id,
        })
        .then((_activities) => {
          setActivities(_activities)
        })
        .then(() => {
          return new QueryTasksUseCase().query({
            process: request._id,
          })
        })
        .then((_tasks) => {
          setTasks(_tasks)
        })
        .then(() => {
          return new QueryDocumentsUseCase().query({
            process: request._id,
          })
        })
        .then((_documents) => {
          setDocuments(_documents)
        })
        .then(() => {
          return new QuerySignaturesUseCase().query({
            process: request._id,
          })
        })
        .then((_signatures) => {
          setSignatures(_signatures)
        })
        .then(() => {
          return new QueryCommentsUseCase().query({
            process: request._id,
          })
        })
        .then((_comments) => {
          setComments(_comments)
        })
        .catch((error) => {
          console.error(error)
          message.error(t('request:error.unexpected'))
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [request])

  useEffect(() => {
    if (accessToken && requestId) {
      new QueryProcessesUseCase()
        .query({
          id: requestId,
        })
        .then((_requests) => {
          if (_requests && _requests.length > 0) {
            setRequest(_requests[0])

            if (step && (step === '2' || step === '3' || step === '4')) {
              setCurrentProcessStep(parseInt(step) - 1)
            } else {
              setCurrentProcessStep(0)
            }

            setProcessingInviteTask(true)
            sessionStorage.setItem('pivt', 'true')
            sessionStorage.setItem('prend', 'true')
            history.replace('/request')
          } else {
            console.warn(`Request ${requestId} could not be found`)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      if (sessionStorage.getItem('pivt') === 'true') {
        setProcessingInviteTask(true)
        sessionStorage.setItem('prend', 'true')
      } else {
        sessionStorage.removeItem('prend')
      }
    }
  }, [accessToken])

  useEffect(() => {
    if (queryAccessToken) {
      setAccessToken(queryAccessToken)
    }
  }, [queryAccessToken])

  useEffect(() => {
    if (noAccessToken && !requestId) history.replace('/signin')
  }, [noAccessToken])

  useEffect(() => {
    if (sessionStorage.getItem('pivt') === 'true' && sessionStorage.getItem('prend') !== 'true') {
      resetUserInfo()
      resetAccessToken()
      sessionStorage.removeItem('pivt')
      sessionStorage.removeItem('prend')
      history.push('/')
    }
  }, [])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={request?.title || t('request:title')}
        pages={[
          {
            name: t('request:title'),
          },
        ]}
        buttons={[
          {
            label: t('request:action.back'),
            type: 'ghost',
            href: '#',
            onClick: handleBack,
          },
        ]}
      />

      {/**
       * Steps
       */}
      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        {(!request && sessionStorage.getItem('prend') !== 'true') || (request && request.status === 'PENDING') ? (
          <div>
            <Steps type="navigation" size="default" current={currentProcessStep}>
              <Step
                title={t('request:step.request.title')}
                description={t('request:step.request.description')}
                status={currentProcessStep > 0 ? 'finish' : 'process'}
              />
              <Step
                title={t('request:step.activities.title')}
                description={t('request:step.activities.description')}
                status={currentProcessStep < 1 ? 'wait' : currentProcessStep > 1 ? 'finish' : 'process'}
              />
              <Step
                title={t('request:step.documents.title')}
                description={t('request:step.documents.description')}
                status={currentProcessStep < 2 ? 'wait' : currentProcessStep > 2 ? 'finish' : 'process'}
              />
              {request && request.status === 'PENDING' ? (
                <Step
                  title={t('request:step.send.title')}
                  description={t('request:step.send.description')}
                  status={currentProcessStep < 3 ? 'wait' : currentProcessStep > 3 ? 'finish' : 'process'}
                />
              ) : null}
            </Steps>

            {/**
             * Details
             */}
            <Spin tip={t('request:action.loading')} spinning={isLoading} size="large">
              {currentProcessStep === 0 ? <RequestStep1 /> : null}
              {currentProcessStep === 1 ? <RequestStep2 /> : null}
              {currentProcessStep === 2 ? <RequestStep3 /> : null}
              {currentProcessStep === 3 ? <RequestStep4 /> : null}
            </Spin>
          </div>
        ) : (
          <div className="w-full">
            <div className="p-5 border-b">
              <div className="flex flex-row items-center justify-between">
                <h2 className="font-semibold text-lg">{title}</h2>
                <p className="text-sm">{created}</p>
              </div>

              <div className="flex flex-row items-center justify-between">
                <h3 className="font-semibold text-sm text-gray-500">{sender}</h3>
                <div className="flex flex-row items-center justify-start">
                  <ClockIcon className="w-4 h-4 text-gray-500" />
                  <p className="ml-2 text-sm">{dueOn}</p>
                </div>
              </div>
            </div>

            {comments && comments.length > 0 ? (
              <div className="p-5 border-b">
                <SectionTitle title={t('task:comments')} />
                {comments.map((taskComment) => {
                  return (
                    <Comment key={taskComment._id} author={taskComment.userFullname} content={taskComment.comment} />
                  )
                })}
              </div>
            ) : null}

            <Timeline />

            {documents && documents.length > 0 ? (
              <div style={{ borderTopWidth: 1 }} className="p-5 border-t-2">
                <DocumentList canView={true} canDownload={true} canPrepare={false} canRemove={false} />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}

export default Request
