import {
  IAccessToken,
  IAccount,
  IActivity,
  IComment,
  IDocument,
  IProcess,
  ISignature,
  ITask,
  IUserInfo,
  IVisual,
  IWorkspace,
  TMemberPlan,
  TMemberRole,
} from '@axelity/actasign-sic'
import { atom, atomFamily } from 'recoil'
import {
  TFastSignature,
  TFontStyle,
  TPage,
  TProcessState,
  TSignatureField,
  TTextLine,
  TViewerState,
  TVisualImage,
} from '../types/appTypes'

export const challengeState = atom<string>({
  key: 'challengeState',
  default: '',
})

export const authMethodState = atom<string>({
  key: 'authMethodState',
  default: '',
})

export const accessTokenState = atom<string>({
  key: 'accessTokenState',
  default: '',
})

export const userInfoState = atom<IUserInfo>({
  key: 'userInfoState',
  default: undefined,
})

export const currentAccountState = atom<IAccount>({
  key: 'currentAccountState',
  default: undefined,
})

export const currentWorkspaceState = atom<IWorkspace>({
  key: 'currentWorkspaceState',
  default: undefined,
})

export const currentPlanState = atom<TMemberPlan>({
  key: 'currentPlanState',
  default: 'GUEST',
})

export const currentRolesState = atom<TMemberRole[]>({
  key: 'currentRolesState',
  default: ['SIGNER'],
})

export const userLanguageState = atom<string>({
  key: 'userLanguageState',
  default: '',
})

export const currentProcessState = atom<TProcessState>({
  key: 'currentProcessState',
  default: {} as TProcessState,
})

export const requestListState = atom<IProcess[]>({
  key: 'requestListState',
  default: [],
})

export const requestState = atom<IProcess>({
  key: 'requestState',
  default: undefined,
})

export const activityListState = atom<IActivity[]>({
  key: 'activityListState',
  default: [],
})

export const activityState = atom<IActivity>({
  key: 'activityState',
  default: undefined,
})

export const taskListState = atom<ITask[]>({
  key: 'taskListState',
  default: [],
})

export const taskState = atom<ITask>({
  key: 'taskState',
  default: undefined,
})

export const documentListState = atom<IDocument[]>({
  key: 'documentListState',
  default: [],
})

export const documentState = atom<IDocument>({
  key: 'documentState',
  default: undefined,
})

export const signatureListState = atom<ISignature[]>({
  key: 'signatureListState',
  default: [],
})

export const signatureState = atom<ISignature>({
  key: 'signatureState',
  default: undefined,
})

export const commentListState = atom<IComment[]>({
  key: 'commentListState',
  default: [],
})

export const pageListState = atom<TPage[]>({
  key: 'pageListState',
  default: [],
})

export const apiKeyListState = atom<IAccessToken[]>({
  key: 'apiKeyListState',
  default: [],
})

export const apiKeyState = atom<IAccessToken>({
  key: 'apiKeyState',
  default: undefined,
})

export const fastSignatureState = atom<TFastSignature>({
  key: 'fastSignatureState',
  default: undefined,
})

export const viewerState = atom<TViewerState>({
  key: 'viewerState',
  default: 'CHANGE',
})

export const viewerAddressedByState = atom<string>({
  key: 'viewerAddressedByState',
  default: undefined,
})

export const currentProcessStepState = atom<number>({
  key: 'currentProcessStepState',
  default: 0,
})

export const dragObjectShiftState = atom<{ shiftX: number; shiftY: number }>({
  key: 'dragObjectShiftState',
  default: {
    shiftX: 0,
    shiftY: 0,
  },
})

export const dragObjectPositionState = atom<{ top: number; left: number }>({
  key: 'dragObjectPositionState',
  default: {
    top: 0,
    left: 0,
  },
})

export const signatureFieldStateFamily = atomFamily({
  key: 'signatureFieldState',
  default: {} as TSignatureField,
})

export const textLineStateFamily = atomFamily({
  key: 'textLineState',
  default: {} as TTextLine,
})

export const visualImageStateFamily = atomFamily({
  key: 'visualImageState',
  default: {} as TVisualImage,
})

export const fontStyleStateFamily = atomFamily({
  key: 'fontStyleState',
  default: {} as TFontStyle,
})

export const documentPageStateFamily = atomFamily({
  key: 'documentPageStateFamily',
  default: '',
})

export const visualState = atom<IVisual>({
  key: 'visualState',
  default: {
    fontStyles: [
      {
        fontColorHex: '#000000',
        fontSize: 7,
        fontFamily: 'Arial',
        styleName: 'A',
      },
      {
        fontColorHex: '#999999',
        fontSize: 4,
        fontFamily: 'Arial',
        styleName: 'B',
      },
    ],
    border: {
      colorHex: '#f3f4f6',
      isVisible: true,
      width: 1,
    },
    background: {
      colorHex: '#ffffff',
      imageDataUrl: '',
      isTransparent: false,
    },
    settings: {
      isVisible: true,
      isFix: false,
      showLongDate: false,
      addSeconds: false,
      addTime: false,
      addTimezone: false,
    },
    position: {
      page: 0,
      x: 0,
      y: 0,
    },
  } as IVisual,
})

export const visualListState = atom<IVisual[]>({
  key: 'visualListState',
  default: [],
})

export const currentTextLineState = atom<number>({
  key: 'currentTextLineState',
  default: -1,
})

export const currentImageState = atom<number>({
  key: 'currentImageState',
  default: -1,
})

export const currentVisualState = atom<string>({
  key: 'currentVisualState',
  default: '',
})

export const currentAccountUserState = atom<IUserInfo>({
  key: 'currentAccountUserState',
  default: undefined,
})

export const currentTaskUserState = atom<IUserInfo>({
  key: 'currentTaskUserState',
  default: undefined,
})

export const accountUsersState = atom<IUserInfo[]>({
  key: 'accountUsersState',
  default: [],
})

export const requestActivitiesState = atom<IActivity[]>({
  key: 'requestActivitiesState',
  default: [],
})

export const requestActivityTasksState = atom<ITask[]>({
  key: 'requestActivityTasksState',
  default: [],
})

export const showRequestActivityDialogState = atom<boolean>({
  key: 'showRequestActivityDialogState',
  default: false,
})

export const showVisualBackgroundDialogState = atom<boolean>({
  key: 'showVisualBackgroundDialogState',
  default: false,
})

export const showVisualBorderDialogState = atom<boolean>({
  key: 'showVisualBorderDialogState',
  default: false,
})

export const showVisualFontStyleDialogState = atom<boolean>({
  key: 'showVisualFontStyleDialogState',
  default: false,
})

export const showVisualTextLineDialogState = atom<boolean>({
  key: 'showVisualTextLineDialogState',
  default: false,
})

export const showVisualImageDialogState = atom<boolean>({
  key: 'showVisualImageDialogState',
  default: false,
})

export const showAccountUserDialogState = atom<boolean>({
  key: 'showAccountUserDialogState',
  default: false,
})

export const showTaskUserDialogState = atom<boolean>({
  key: 'showTaskUserDialogState',
  default: false,
})

export const signingInProgessState = atom<boolean>({
  key: 'signingInProgressState',
  default: false,
})

export const closeAllTimelinesState = atom<boolean>({
  key: 'closeAllTimelinesState',
  default: false,
})

export const processingInviteTaskState = atom<boolean>({
  key: 'processingInviteTaskState',
  default: false,
})

export const viewingDocumentState = atom<boolean>({
  key: 'viewingDocumentState',
  default: false,
})
