/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Button, Input, Select, message } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { useRecoilState } from 'recoil'
import { userInfoState, userLanguageState } from '../recoil/atoms'
import { GetUserUseCase, UpdateUserBasicUseCase } from '@axelity/actasign-sic'
import SectionTitle from '../elements/SectionTitle'
import FieldTitle from '../elements/FieldTitle'
import * as yup from 'yup'
import useSignIn from '../hooks/useSignIn'

const { Option } = Select

const ProfileBasic = () => {
  const { t, i18n } = useTranslation('profileBasic')

  const [noAccessToken] = useSignIn()

  const history = useHistory()

  const [userInfo, setUserInfo] = useRecoilState(userInfoState)

  const [firstName, setFirstName] = useState('')
  const [familyName, setFamilyName] = useState('')

  const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState)

  type TFormErrors = {
    firstName: string
    familyName: string
    language: string
  }

  const [formErrors, setFormErrors] = useState<TFormErrors>({
    firstName: '',
    familyName: '',
    language: '',
  })

  const validateForm = (): boolean => {
    let valid = true
    let errors: TFormErrors = {
      firstName: '',
      familyName: '',
      language: '',
    }

    if (!yup.string().trim().min(1).required().isValidSync(firstName)) {
      errors = { ...errors, firstName: t('profileBasic:validation.inputRequired') }
      valid = false
    }

    if (!yup.string().trim().min(1).required().isValidSync(familyName)) {
      errors = { ...errors, familyName: t('profileBasic:validation.inputRequired') }
      valid = false
    }

    if (!yup.string().trim().min(2).isValidSync(userLanguage)) {
      errors = { ...errors, language: t('profileBasic:validation.inputRequired') }
      valid = false
    }

    setFormErrors(errors)

    return valid
  }

  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value)
    setUserLanguage(i18n.language.substr(0,2))
  }

  const saveHandler = () => {
    if (!validateForm()) return

    new UpdateUserBasicUseCase({
      userId: userInfo._id as string,
    })
      .update({
        familyName,
        firstName,
        language: userLanguage,
      })
      .then(() => {
        return new GetUserUseCase().getUser()
      })
      .then((userInfo) => {
        setUserInfo(userInfo)
        message.success(t('profileBasic:updateSuccessful'))
      })
      .catch((error) => {
        console.error(error)
        message.error(t('profileBasic:updateFailed'))
      })
    history.push('/home')
  }

  const cancelHandler = () => {
    history.push('/home')
  }

  useEffect(() => {
    if (userInfo) {
      setFirstName(userInfo.firstName)
      setFamilyName(userInfo.familyName)
      setUserLanguage(userInfo.language)
    }
  }, [userInfo])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('profile:userDetails.name')}
        pages={[
          {
            name: t('profile:userProfile.name'),
          },
          {
            name: t('profile:userDetails.name'),
          },
        ]}
      />

      <div className="container max-w-4xl mx-auto text-gray-700 text-base bg-white border-2 border-gray-100 p-8 mt-4 shadow-sm rounded-md">
        <SectionTitle title={t('title')} />
        <FieldTitle title={t('profileBasic:firstName')} />
        <div className="inline-flex w-full">
          <Input
            className="rounded"
            type="text"
            size="large"
            placeholder={t('profileBasic:firstName')}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          ></Input>
        </div>
        <div className="my-1 font-normal text-md text-red-500">{formErrors.firstName}</div>

        <FieldTitle title={t('profileBasic:familyName')} />
        <div className="inline-flex w-full">
          <Input
            className="rounded"
            type="text"
            size="large"
            placeholder={t('profileBasic:familyName')}
            value={familyName}
            onChange={(e) => setFamilyName(e.target.value)}
          ></Input>
        </div>
        <div className="my-1 font-normal text-md text-red-500">{formErrors.familyName}</div>

        <FieldTitle title={t('profileBasic:language')} />
        <div className="inline-flex w-full">
          <div className="w-full">
            <Select value={userLanguage} size="large" style={{ width: '100%' }} onChange={changeLanguage}>
              <Option value="de">{t('common:language.german')}</Option>
              <Option value="en">{t('common:language.english')}</Option>
              {/* <Option value="fr">{t('common:language.french')}</Option> */}
            </Select>
          </div>
        </div>
        <div className="my-1 font-normal text-md text-red-500">{formErrors.language}</div>

        <div className="flex flex-auto mt-8 justify-end">
          <Button
            className="ml-2 rounded font-semibold text-gray-700"
            size="large"
            type="ghost"
            onClick={cancelHandler}
          >
            {t('profileBasic:back')}
          </Button>
          <Button className="ml-2 rounded font-semibold" size="large" type="primary" onClick={saveHandler}>
            {t('profileBasic:apply')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ProfileBasic
