/* eslint-disable import/first */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  QueryTasksUseCase,
} from '@axelity/actasign-sic'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import TaskList from '../components/TaskList'
import useSignIn from '../hooks/useSignIn'
import {
  accessTokenState,
  fastSignatureState,
  taskListState,
  taskState,
  userInfoState,
  viewerAddressedByState,
} from '../recoil/atoms'
import { Spin, Tabs } from 'antd'
import NoData from '../components/NoData'

const { TabPane } = Tabs

const Tasks = () => {
  const { t } = useTranslation(['common', 'myTasks'])

  const history = useHistory()

  const [noAccessToken] = useSignIn()

  const userInfo = useRecoilValue(userInfoState)
  const setViewerAddressedBy = useSetRecoilState(viewerAddressedByState)
  const resetFastSignature = useResetRecoilState(fastSignatureState)
  const resetAccessToken = useResetRecoilState(accessTokenState)
  const resetUserInfo = useResetRecoilState(userInfoState)

  const [tasks, setTasks] = useRecoilState(taskListState)
  const resetTask = useResetRecoilState(taskState)

  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    // Query user tasks
    resetTask()
    setLoading(true)
    if (userInfo) {
      new QueryTasksUseCase()
        .query({
          user: userInfo._id,
        })
        .then((userTasks) => {
          if (userTasks.length) {
            setTasks(userTasks)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [userInfo])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  useEffect(() => {
    if (sessionStorage.getItem('pivt') === 'true') {
      resetUserInfo()
      resetAccessToken()
      sessionStorage.removeItem('pivt')
      history.push('/')
    } else {
      // This is because history.goBack() is not working with other Browsers than Chrome
      setViewerAddressedBy('/tasks')
      resetFastSignature()
    }
  }, [])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('common:myTasks')}
        pages={[
          {
            name: t('common:myTasks'),
          },
        ]}
      />

      {/**
       * Tasks
       */}
      {tasks && tasks.length !== 0 ? (
        <div className="container max-w-4xl mx-auto text-base bg-white p-4 mt-4 rounded-sm">
          <Spin spinning={isLoading} size="large">
            <Tabs defaultActiveKey="active">
              <TabPane tab={<div className="text-base">{t('myTasks:tab.active')}</div>} key="active">
                <TaskList tasks={tasks} status={['ACTIVE']} />
              </TabPane>
              <TabPane tab={<div className="text-base">{t('myTasks:tab.completed')}</div>} key="completed">
                <TaskList tasks={tasks} status={['COMPLETED']} />
              </TabPane>
              <TabPane tab={<div className="text-base">{t('myTasks:tab.inactive')}</div>} key="others">
                <TaskList tasks={tasks} status={['DECLINED', 'OBSOLETED', 'SUPERCEDED', 'SUSPENDED']} />
              </TabPane>
            </Tabs>
          </Spin>
        </div>
      ) : null}
      {(!tasks || tasks.length === 0) && !isLoading ? <NoData note={t('myTasks:noTasks')} /> : null}
    </div>
  )
}

export default Tasks
