import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { version } from '../../package.json'

const LandingPage = () => {
  const { t } = useTranslation(['landingPage'])

  return (
    <main>
      <div className="flex flex-col items-center">
        <div className="flex justify-right mt-20">
          <span className="text-8xl font-light">acta</span>
          <span className="text-8xl font-bold">SIGN</span>
          <span className="text-xl font-light">&copy;</span>
          <span className="text-8xl font-bold pl-2">ONE</span>
        </div>
        <h1 className="mt-3 font-light text-2xl text-center">{t('landingPage:tagline')}</h1>
        <div className="mt-10">
          {/* {window.location.href.includes('actasign.li') ? <img
              className="w-24 h-auto"
              src={`${process.env.PUBLIC_URL}/assets/images/liechtenstein.png`}
              alt="liechtenstein"
            /> : null} */}
          {window.location.href.includes('actasign.ch') || window.location.href.includes('actasign.li') ? (
            <img
              className="w-24 h-auto"
              src={`${process.env.PUBLIC_URL}/assets/images/switzerland.png`}
              alt="switzerland"
            />
          ) : null}
          {window.location.href.includes('localhost') ? (
            <img
              className="w-24 h-auto"
              src={`${process.env.PUBLIC_URL}/assets/images/international.png`}
              alt="international"
            />
          ) : null}
        </div>
        <h2 className="mt-8 max-w-3xl font-light text-2xl text-center">{t('landingPage:hero')}</h2>
        <div className="mt-20 w-6/12 flex flex-row justify-around w-3xl">
          <div>
            <Link to={'/signup'}>
              <Button className="ml-2 rounded" size="large" type="ghost">
                {t('signUp')}
              </Button>
            </Link>
          </div>
          <div>
            <Link to={'/signin'}>
              <Button className="ml-2 rounded" size="large" type="primary">
                {t('signIn')}
              </Button>
            </Link>
          </div>
        </div>
        <div className="mt-20">
          &copy; {new Date().getFullYear()} {t('landingPage:copyright')} (Release: {version})
        </div>
      </div>
    </main>
  )
}

export default LandingPage
