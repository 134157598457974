/* eslint-disable react-hooks/exhaustive-deps */
import { IVisual } from '@axelity/actasign-sic'
import { useEffect, useState } from 'react'
import { replace } from 'lodash'
import { Spin } from 'antd'

type SignaturePreviewProps = {
  visual: IVisual
  fullName?: string
  organization?: string
  role?: string
  place?: string
  reason?: string
  userText?: string
}

type TImageData = {
  image: HTMLImageElement
  scale: number
  x: number
  y: number
  width: number
  height: number
}

const SignaturePreview = ({ visual, fullName, organization, role, place, reason, userText }: SignaturePreviewProps) => {
  const RATIO = 4

  const [images, setImages] = useState<TImageData[]>([])

  const [preview, setPreview] = useState<string>(undefined)
  const [isLoading, setLoading] = useState<boolean>(false)

  const buildPreview = () => {
    const canvas = document.createElement('canvas')
    canvas.width = 720
    canvas.height = 200
    const context = canvas.getContext('2d')

    context.clearRect(0, 0, canvas.width, canvas.height)

    if (context && visual.background) {
      if (visual.background.colorHex) {
        context.beginPath()
        context.fillStyle = visual.background.colorHex
        context.fillRect(0, 0, canvas.width, canvas.height)
        context.closePath()
      }

      if (visual.background.imageDataUrl) {
        context.beginPath()
        const _image = new Image()
        _image.src = visual.background.imageDataUrl
        context.drawImage(_image, 0, 0)
        context.closePath()
      }
    }

    if (context && visual.border) {
      if (visual.border.isVisible) {
        context.lineWidth = visual.border.width
        context.strokeStyle = visual.border.colorHex
        context.strokeRect(0, 0, canvas.width, canvas.height)
      }
    }

    if (context && visual.images) {
      context.beginPath()
      images.forEach((_image) => {
        context.drawImage(_image.image, _image.x, _image.y, _image.width, _image.height)
      })
      context.closePath()
    }

    // date/time settings
    const dateTimeFormat: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      year: 'numeric',
    }

    if (visual.settings.showLongDate) {
      dateTimeFormat.month = 'long'
    } else {
      dateTimeFormat.month = '2-digit'
    }

    if (visual.settings.addTime) {
      dateTimeFormat.hour = '2-digit'
      dateTimeFormat.minute = '2-digit'
    }

    if (visual.settings.addSeconds) {
      dateTimeFormat.second = '2-digit'
    }

    if (visual.settings.addTimezone) {
      dateTimeFormat.timeZoneName = 'short'
    }

    if (context && visual.textLines && visual.fontStyles) {
      context.beginPath()
      visual.textLines.forEach((textLine) => {
        let displayText = `${textLine.displayText}`
        displayText = fullName ? replace(displayText, /{{SIGNER}}/, `${fullName}`) : displayText
        displayText = organization ? replace(displayText, /{{ORGANIZATION}}/, `${organization}`) : displayText
        displayText = role ? replace(displayText, /{{ROLE}}/, `${role}`) : displayText
        displayText = place ? replace(displayText, /{{PLACE}}/, `${place}`) : displayText
        displayText = reason || visual.defaultReason ? replace(displayText, /{{REASON}}/, `${reason || visual.defaultReason}`) : displayText
        displayText = userText ? replace(displayText, /{{USERTEXT}}/, `${userText}`) : displayText
        displayText = replace(
          displayText,
          /{{DATE}}/,
          new Intl.DateTimeFormat(visual.language || 'de', dateTimeFormat).format(new Date()),
        )

        const fontStyle = visual.fontStyles?.find((style) => {
          return style.styleName === textLine.fontStyle
        })
        if (fontStyle) {
          const font = `${fontStyle.fontSize * RATIO}px ${fontStyle.fontFamily}`
          context.font = font
          context.fillStyle = fontStyle.fontColorHex
          context.fillText(displayText, textLine.x, textLine.y + fontStyle.fontSize * RATIO)
        }
      })
      context.closePath()

      setPreview(canvas.toDataURL())
    }
  }

  const loadImage = (visualImage: {
    imageDataUrl: string
    x: number
    y: number
    width: number
    height: number
  }): Promise<TImageData> => {
    return new Promise((resolve) => {
      const _image = new Image()
      _image.src = visualImage.imageDataUrl
      _image.onload = () => {
        resolve({
          image: _image,
          scale: visualImage.width / _image.naturalWidth,
          x: visualImage.x,
          y: visualImage.y,
          width: visualImage.width,
          height: (_image.naturalHeight * visualImage.width) / _image.naturalWidth,
        })
      }
      _image.src = visualImage.imageDataUrl
    })
  }

  const loadImages = async (visual: IVisual): Promise<TImageData[]> => {
    const imagesData: TImageData[] = []
    if (visual.images) {
      for (let i = 0; i < visual.images.length; i++) {
        const _imageData = await loadImage(visual.images[i])
        imagesData.push(_imageData)
      }
    }
    return imagesData
  }

  useEffect(() => {
    buildPreview()
  }, [images, fullName, organization, reason, place, role, userText])

  useEffect(() => {
    if (visual) {
      setLoading(true)
      loadImages(visual).then((imagesData) => {
        setImages(imagesData)
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [visual])

  return (
    <div>
      {preview ? (
        <img src={preview} alt="preview" className="border w-full h-auto border-gray-100" />
      ) : <Spin spinning={isLoading} size="large" />}
    </div>
  )
}

export default SignaturePreview
