/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Button, Input, message } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { useRecoilValue } from 'recoil'
import { userInfoState } from '../recoil/atoms'
import { DeleteAccountBrandingUseCase, QueryAccountsUseCase, UpdateAccountBrandingUseCase } from '@axelity/actasign-sic'
import { InboxInIcon } from '@heroicons/react/outline'
import { TFileInfo } from '../types/appTypes'
import useSignIn from '../hooks/useSignIn'
import SectionTitle from '../elements/SectionTitle'
import FieldTitle from '../elements/FieldTitle'

const SettingsAccount = () => {
  const { t } = useTranslation(['settingsBranding', 'settings'])

  const [noAccessToken] = useSignIn()

  const history = useHistory()

  const userInfo = useRecoilValue(userInfoState)

  const [accountId, setAccountId] = useState<string>('')

  const DEFAULT_ACCENT_COLOR_HEX = '#00396d'
  const DEFAULT_ACCENT_TEXT_COLOR_HEX = '#ffffff'
  const DEFAULT_BRAND_COLOR_HEX = '#b1d9ff'
  const DEFAULT_BRAND_TEXT_COLOR_HEX = '#263238'

  const [termsOfUseUrl, setTermsOfUseUrl] = useState<string>('')
  const [accentColorHex, setAccentColorHex] = useState<string>(DEFAULT_ACCENT_COLOR_HEX)
  const [accentTextColorHex, setAccentTextColorHex] = useState<string>(DEFAULT_ACCENT_TEXT_COLOR_HEX)
  const [brandColorHex, setBrandColorHex] = useState<string>(DEFAULT_BRAND_COLOR_HEX)
  const [brandTextColorHex, setBrandTextColorHex] = useState<string>(DEFAULT_BRAND_TEXT_COLOR_HEX)
  const [bannerDataUrl, setBannerDataUrl] = useState<string>('')
  const [logoDataUrl, setLogoDataUrl] = useState<string>('')

  const updateHandler = () => {
    new UpdateAccountBrandingUseCase({
      accountId,
    })
      .update({
        termsOfUseUrl,
        accentColorHex,
        accentTextColorHex,
        brandColorHex,
        brandTextColorHex,
        bannerDataUrl,
        logoDataUrl,
      })
      .then(() => {
        message.success(t('settingsBranding:updateSuccessful'))
        history.push('/home')
      })
      .catch((error) => {
        message.error(t('settingsBranding:updateFailed'))
        console.error(error)
      })
  }

  const deleteHandler = () => {
    new DeleteAccountBrandingUseCase({
      accountId,
    })
      .delete()
      .then(() => {
        setTermsOfUseUrl('')
        setAccentColorHex(DEFAULT_ACCENT_COLOR_HEX)
        setAccentTextColorHex(DEFAULT_ACCENT_TEXT_COLOR_HEX)
        setBrandColorHex(DEFAULT_BRAND_COLOR_HEX)
        setBrandTextColorHex(DEFAULT_BRAND_TEXT_COLOR_HEX)
        setBannerDataUrl('')
        setLogoDataUrl('')
        message.success(t('settingsBranding:deleteSuccessful'))
        history.push('/home')
      })
      .catch((error) => {
        message.error(t('settingsBranding:deleteFailed'))
        console.error(error)
      })
  }

  const backHandler = () => {
    history.push('/home')
  }

  const openLogoDialog = () => {
    const ele = document.getElementById('inputFileLogo')
    if (ele) ele.click()
  }

  const openBannerDialog = () => {
    const ele = document.getElementById('inputFileBanner')
    if (ele) ele.click()
  }

  const handleDragOver = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    event.dataTransfer.dropEffect = 'copy'
  }

  const handleDropLogo = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      loadFiles(event.dataTransfer.files).then((loadedFiles: TFileInfo[]) => {
        setLogoDataUrl(loadedFiles[0].content as string)
      })
    }
  }

  const handleDropBanner = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      loadFiles(event.dataTransfer.files).then((loadedFiles: TFileInfo[]) => {
        setBannerDataUrl(loadedFiles[0].content as string)
      })
    }
  }

  const handleLogoSelect = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    if (event.target && event.target.files && event.target.files.length > 0) {
      loadFiles(event.target.files).then((loadedFiles: TFileInfo[]) => {
        setLogoDataUrl(loadedFiles[0].content as string)
      })
    }
  }

  const handleBannerSelect = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    if (event.target && event.target.files && event.target.files.length > 0) {
      loadFiles(event.target.files).then((loadedFiles: TFileInfo[]) => {
        setBannerDataUrl(loadedFiles[0].content as string)
      })
    }
  }

  const loadFiles = async (files: any): Promise<TFileInfo[]> => {
    let loadedFiles: TFileInfo[] = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      let promise: any = new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = (result: any) => {
          let fileInfo: TFileInfo = {
            content: result?.currentTarget?.result,
            mimeType: file.type,
            filename: file.name,
            filesize: file.size,
            lastModified: file.lastModified,
          }
          resolve(fileInfo)
        }
        reader.readAsDataURL(file)
      })

      let fileInfo = await promise
      loadedFiles = [...loadedFiles, fileInfo]
    }
    return loadedFiles
  }

  useEffect(() => {
    // Lookup account the user is the primary admin (owner)
    if (userInfo.memberships) {
      const membership = userInfo.memberships.find((ms) => {
        return ms.roles.includes('PRIMARY-ADMIN')
      })

      if (membership) {
        new QueryAccountsUseCase()
          .query({
            id: membership.account,
          })
          .then((accounts) => {
            const account = accounts[0]
            setAccountId(account._id as string)

            if (account.branding) {
              setTermsOfUseUrl(account.branding.termsOfUseUrl || '')
              setAccentColorHex(account.branding.accentColorHex || DEFAULT_ACCENT_COLOR_HEX)
              setAccentTextColorHex(account.branding.accentTextColorHex || DEFAULT_ACCENT_TEXT_COLOR_HEX)
              setBrandColorHex(account.branding.brandColorHex || DEFAULT_BRAND_COLOR_HEX)
              setBrandTextColorHex(account.branding.brandTextColorHex || DEFAULT_BRAND_TEXT_COLOR_HEX)
              setBannerDataUrl(account.branding.bannerDataUrl || '')
              setLogoDataUrl(account.branding.logoDataUrl || '')
            } else {
              setTermsOfUseUrl('')
              setAccentColorHex(DEFAULT_ACCENT_COLOR_HEX)
              setAccentTextColorHex(DEFAULT_ACCENT_TEXT_COLOR_HEX)
              setBrandColorHex(DEFAULT_BRAND_COLOR_HEX)
              setBrandTextColorHex(DEFAULT_BRAND_TEXT_COLOR_HEX)
              setBannerDataUrl('')
              setLogoDataUrl('')
            }
          })
      }
    }
  }, [userInfo])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('settings:branding.name')}
        pages={[
          {
            name: t('settings:accountSettings.name'),
          },
          {
            name: t('settings:branding.name'),
          },
        ]}
      />

      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        {/**
         * Title
         */}
        <SectionTitle title={t('settings:branding.name')} />

        {/**
         * Terms of use
         */}
        {/* <FieldTitle title={t('settingsBranding:termsOfUseUrl')} />
        <div className="inline-flex w-full">
          <Input
            addonBefore="https://"
            type="text"
            size="large"
            placeholder={t('settingsBranding:termsOfUseUrl')}
            value={termsOfUseUrl}
            onChange={(e) => setTermsOfUseUrl(e.target.value)}
          ></Input>
        </div> */}

        <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          {/**
           * Brand color
           */}
          <div className="sm:col-span-3">
            <FieldTitle title={t('settingsBranding:brandColor')} />
            <div className="flex w-full">
              <div className="w-11">
                <Input
                  type="color"
                  size="large"
                  placeholder={t('settingsBranding:brandColor')}
                  value={brandColorHex}
                  onChange={(e) => setBrandColorHex(e.target.value)}
                ></Input>
              </div>
              <p className="ml-2 mt-2 text-base font-medium">{brandColorHex}</p>
            </div>
          </div>

          {/**
           * Brand text color
           */}
          <div className="sm:col-span-3">
            <FieldTitle title={t('settingsBranding:brandTextColor')} />
            <div className="flex w-full">
              <div className="w-11">
                <Input
                  type="color"
                  size="large"
                  placeholder={t('settingsBranding:brandColor')}
                  value={brandTextColorHex}
                  onChange={(e) => setBrandTextColorHex(e.target.value)}
                ></Input>
              </div>
              <p className="ml-2 mt-2 text-base font-medium">{brandTextColorHex}</p>
            </div>
          </div>
        </div>

        <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          {/**
           * Accent color
           */}
          <div className="sm:col-span-3">
            <FieldTitle title={t('settingsBranding:accentColor')} />
            <div className="flex w-full">
              <div className="w-11">
                <Input
                  type="color"
                  size="large"
                  placeholder={t('settingsBranding:accentColor')}
                  value={accentColorHex}
                  onChange={(e) => setAccentColorHex(e.target.value)}
                ></Input>
              </div>
              <p className="ml-2 mt-2 text-base font-medium">{accentColorHex}</p>
            </div>
          </div>

          {/**
           * Accent text color
           */}
          <div className="sm:col-span-3">
            <FieldTitle title={t('settingsBranding:accentTextColor')} />
            <div className="flex w-full">
              <div className="w-11">
                <Input
                  type="color"
                  size="large"
                  placeholder={t('settingsBranding:accentTextColor')}
                  value={accentTextColorHex}
                  onChange={(e) => setAccentTextColorHex(e.target.value)}
                ></Input>
              </div>
              <p className="ml-2 mt-2 text-base font-medium">{accentTextColorHex}</p>
            </div>
          </div>
        </div>

        <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          {/**
           * Upload logo
           */}
          <div className="sm:col-span-3">
            <FieldTitle title={t('settingsBranding:uploadLogo')} />
            <div className="w-full my-4">
              <div
                className="relative cursor-pointer p-4 border-2 border-dotted flex flex-col content-center items-center justify-center"
                onDragOver={handleDragOver}
                onDrop={handleDropLogo}
                onClick={openLogoDialog}
              >
                <InboxInIcon className="h-5 w-5 text-antd-blue" />
                <p className="text-base text-center mt-2">{t('settingsBranding:uploadInstructions')}</p>
                <p className="text-sm mt-2">{t('settingsBranding:picturesOnly')}</p>
                <input
                  type="file"
                  id="inputFileLogo"
                  accept="image/*"
                  multiple={false}
                  onChange={handleLogoSelect}
                  hidden
                />
              </div>
            </div>
          </div>

          {/**
           * Preview logo
           */}
          <div className="sm:col-span-3">
            <FieldTitle title={t('settingsBranding:preview')} />
            <div className="w-full my-4">
              {logoDataUrl ? <img src={logoDataUrl} className="object-scale-down h-24 w-full" alt="logo" /> : null}
            </div>
          </div>
        </div>

        <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          {/**
           * Upload banner
           */}
          <div className="sm:col-span-3">
            <FieldTitle title={t('settingsBranding:uploadBanner')} />
            <div className="w-full my-4">
              <div
                className="relative cursor-pointer p-4 border-2 border-dotted flex flex-col content-center items-center justify-center"
                onDragOver={handleDragOver}
                onDrop={handleDropBanner}
                onClick={openBannerDialog}
              >
                <InboxInIcon className="h-5 w-5 text-antd-blue" />
                <p className="text-base text-center mt-2">{t('settingsBranding:uploadInstructions')}</p>
                <p className="text-sm mt-2">{t('settingsBranding:picturesOnly')}</p>
                <input
                  type="file"
                  id="inputFileBanner"
                  accept="image/*"
                  multiple={false}
                  onChange={handleBannerSelect}
                  hidden
                />
              </div>
            </div>
          </div>

          {/**
           * Preview banner
           */}
          <div className="sm:col-span-3">
            <FieldTitle title={t('settingsBranding:preview')} />
            <div className="w-full my-4">
              {bannerDataUrl ? (
                <img src={bannerDataUrl} className="object-scale-down h-24 w-full" alt="banner" />
              ) : null}
            </div>
          </div>
        </div>

        <div className="flex flex-auto mt-4 justify-between">
          <Button className="rounded font-semibold" size="large" type="default" onClick={backHandler}>
            {t('settingsBranding:back')}
          </Button>
          <div>
            <Button className="rounded font-semibold" size="large" type="primary" danger={true} onClick={deleteHandler}>
              {t('settingsBranding:delete')}
            </Button>
            <Button className="ml-2 rounded font-semibold" size="large" type="primary" onClick={updateHandler}>
              {t('settingsBranding:save')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsAccount
