/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { TPages } from '../types/appTypes'
import PageItem from './PageItem'
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/react/outline'

type TPageListProps = {
  pages: TPages
}

const PageList = ({ pages }: TPageListProps) => {
  const [wrapperHeight, setWrapperHeight] = useState<number>(window.innerHeight - 200)

  useEffect(() => {
    setWrapperHeight(window.innerHeight - 230)
  }, [window.innerHeight])

  const handleScrollToFirstPage = () => {
    document.getElementById('page-list').scrollTop = 0
  }

  const handleScrollToLastPage = () => {
    const elementId = `${pages[pages.length - 1].documentId}_${pages[pages.length - 1].pageNo}`
    const topPos = document.getElementById(elementId).offsetTop
    document.getElementById('page-list').scrollTop = topPos
  }
  return (
    <div className="flex h-full flex-nowrap">
      <div className="flex flex-col justify-between m-5">
        <div className="rounded-full p-3 bg-acs-blue-100 hover:cursor-pointer" onClick={handleScrollToFirstPage}>
          <ChevronDoubleUpIcon className="w-8 h-8 text-acs-blue-500" />
        </div>
        <div className="rounded-full p-3 bg-acs-blue-100 hover:cursor-pointer" onClick={handleScrollToLastPage}>
          <ChevronDoubleDownIcon className="w-8 h-8 text-acs-blue-500" />
        </div>
      </div>
      <div
        id="page-list"
        style={{ height: `${wrapperHeight}px`, scrollBehavior: 'smooth' }}
        className="container flex-col overflow-auto p-2 min-w-max"
      >
        {pages.map((page) => {
          return <PageItem key={page.pageNo} page={page} />
        })}
      </div>
    </div>
  )
}

export default PageList
