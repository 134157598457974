/* eslint-disable react-hooks/exhaustive-deps */
import {
  ApplicationException,
  AuthorizeUserUseCase,
  SignInUseCase,
  TUserAuthenticationMethod,
} from '@axelity/actasign-sic'
import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { Link } from 'react-router-dom'
import i18n from '../i18n'
import { userLanguageState, accessTokenState, userInfoState } from '../recoil/atoms'
import { Button, Input, message } from 'antd'
import * as yup from 'yup'
import useSignIn from '../hooks/useSignIn'

import AppLogo from '../components/AppLogo'
import FieldTitle from '../elements/FieldTitle'

const SignIn = () => {
  const { t } = useTranslation(['common', 'signin'])

  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const otpRef = useRef(null)

  const userLanguage = useRecoilValue(userLanguageState)

  useSignIn()

  const history = useHistory()

  const setAccessToken = useSetRecoilState(accessTokenState)
  const resetAccessToken = useResetRecoilState(accessTokenState)
  const resetUserInfo = useResetRecoilState(userInfoState)

  const [emailAddress, setEmailAddress] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [otp, setOtp] = useState<string>('')

  const [challenge, setChallenge] = useState<string>('')
  const [isInProgress, setIsInProgress] = useState<boolean>(false)
  const [readyToSignIn, setReadyToSignIn] = useState<boolean>(false)
  const [userAuthMethod, setUserAuthMethod] = useState<TUserAuthenticationMethod | undefined>()

  const handleChangeEmail = (value: string) => {
    if (yup.string().required().email().isValidSync(value)) {
      setReadyToSignIn(true)
    } else {
      setReadyToSignIn(false)
    }
    setEmailAddress(value)
  }

  const signIn = async () => {
    try {
      setIsInProgress(true)

      sessionStorage.removeItem('access_token')

      const { challenge, authMethod: authn } = await new SignInUseCase().signIn({
        emailAddress,
      })

      if (authn === 'EMAIL-LINK') {
        setUserAuthMethod('EMAIL-LINK')
      } else if (authn === 'TOTP') {
        setUserAuthMethod('TOTP')
        otpRef.current.focus()
      } else if (authn === 'PASSWORD') {
        setUserAuthMethod('PASSWORD')
        passwordRef.current.focus()
      }

      // Store authMethod and challenge in context
      setChallenge(challenge)
    } catch (error) {
      message.error(t('signin:authenticationFailed'))
      console.error(error)
      const err = error as ApplicationException
      console.dir(err.problem)
    } finally {
      setIsInProgress(false)
    }
  }

  const authorize = async () => {
    const response = await new AuthorizeUserUseCase().authorize({
      challenge,
      otp: userAuthMethod === 'TOTP' ? otp : undefined,
      password: userAuthMethod === 'PASSWORD' ? password : undefined,
    })

    setAccessToken(response.access_token)
    // sessionStorage.setItem('access_token', response.access_token)

    // const userInfo = await new GetUserUseCase().getUser()
    // setUserInfo(userInfo)
    // if (userInfo.memberships && userInfo.memberships.length > 0) {
    //   setCurrentPlan(userInfo.memberships[0].plan)
    //   setCurrentRoles(userInfo.memberships[0].roles)
    // }

    // const accounts = await new QueryAccountsUseCase().query({
    //   id: userInfo.currentAccount,
    // })
    // setCurrentAccount(accounts[0])

    // const workspaces = await new QueryWorkspacesUseCase().query({
    //   id: userInfo.currentWorkspace,
    // })
    // setCurrentWorkspace(workspaces[0])
  }

  const handleAuthorize = () => {
    setIsInProgress(true)
    authorize()
      .then(() => {
        setIsInProgress(false)
        history.replace('/home')
      })
      .catch((error) => {
        setIsInProgress(false)
        message.error(t('signin:authorizationFailed'))
        console.error(error)
      })
  }

  useEffect(() => {
    i18n.changeLanguage(userLanguage)
    emailRef.current.focus()
  }, [userLanguage])

  useEffect(() => {
    resetUserInfo()
    resetAccessToken()
    sessionStorage.removeItem('access_token')
  }, [])

  return (
    <div className="container max-w-4xl mx-auto text-base p-10 mb-10">
      <div className="mt-10 hover:cursor-pointer" onClick={() => history.push('/')}>
        <AppLogo />
      </div>

      <div className="bg-white border-2 border-gray-100 p-4 mt-10 shadow-sm rounded-md">
        <div className="flex-1 px-10 text-base">
          {/**
           * Title
           */}
          <div className="flex justify-left">
            <h1 className="text-xl font-bold">{t('sign-in')}</h1>
          </div>

          {/**
           * Instructions
           */}
          <div className="pt-12 flex justify-left">
            <h2 className="text-lg font-light">{t('signin:instructions')}</h2>
          </div>
        </div>

        <div className="min-h-full bg-white flex flex-row">
          <div className="flex-1 pt-12 px-10 w-1/2 text-base">
            {!userAuthMethod ? (
              <div>
                {/**
                 * Email address
                 */}
                <div className="my-2">
                  <FieldTitle title={t('signin:emailAddress')} />
                  <Input
                    className="rounded"
                    autoComplete="email"
                    type="email"
                    size="large"
                    placeholder={t('signin:emailAddress')}
                    value={emailAddress}
                    onChange={(e) => handleChangeEmail(e.target.value)}
                    onPressEnter={signIn}
                    ref={emailRef}
                  ></Input>
                </div>

                {/**
                 * Next button
                 */}
                <div className="mt-10">
                  <Button
                    className="rounded font-semibold"
                    type="primary"
                    disabled={!readyToSignIn}
                    size="large"
                    style={{ width: '100%' }}
                    onClick={signIn}
                    loading={isInProgress}
                  >
                    {t('signin:next')}
                  </Button>
                </div>

                {/**
                 * Sign-up Link
                 */}
                <div className="mt-5">
                  <div className="flex content-center">
                    <p>{t('signin:notRegisteredYet')}</p>
                    <Link className="px-2 font-medium  text-blue-500 hover:text-blue-800" to="/signup">
                      {t('sign-up')}
                    </Link>
                  </div>
                </div>
              </div>
            ) : null}

            {userAuthMethod === 'EMAIL-LINK' ? (
              <div>
                <p>{t('signin:challengeSentByMail')}</p>
              </div>
            ) : null}

            {/**
             * Password
             */}
            {userAuthMethod === 'PASSWORD' ? (
              <div className="my-2">
                <FieldTitle title={t('signin:password')} />
                <Input
                  type="password"
                  size="large"
                  placeholder={t('signin:password')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onPressEnter={handleAuthorize}
                  ref={passwordRef}
                ></Input>
              </div>
            ) : null}

            {/**
             * OTP
             */}
            {userAuthMethod === 'TOTP' ? (
              <div className="my-2">
                <FieldTitle title={t('signin:otp')} />
                <Input
                  type="text"
                  size="large"
                  placeholder={t('signin:otp')}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  onPressEnter={handleAuthorize}
                  ref={otpRef}
                ></Input>
              </div>
            ) : null}

            {/**
             * Sign-in button
             */}
            {userAuthMethod === 'PASSWORD' || userAuthMethod === 'TOTP' ? (
              <div className="mt-10">
                <Button
                  className="rounded font-semibold"
                  type="primary"
                  size="large"
                  style={{ width: '100%' }}
                  onClick={handleAuthorize}
                  loading={isInProgress}
                >
                  {t('common:sign-in')}
                </Button>
              </div>
            ) : null}
          </div>

          <div className="hidden lg:block pt-12 px-10 w-1/2 text-base">
            <div className="flex flex-row justify-center">
              <img
                style={{ width: '100%' }}
                src={`${process.env.PUBLIC_URL}/assets/images/login.svg`}
                alt="Online illustrations by Storyset"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn
