import { TTextLine } from '../../types/appTypes'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { currentTextLineState, dragObjectShiftState, showVisualTextLineDialogState, visualState } from '../../recoil/atoms'
import { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'

type TextLineProps = {
  lineNo: number
  textLine: TTextLine
}

const TextLine = (props: TextLineProps) => {
  const RATIO = 4

  const visual = useRecoilValue(visualState)

  const setCurrentTextLine = useSetRecoilState(currentTextLineState)
  const setShowVisualTextLineDialog = useSetRecoilState(showVisualTextLineDialogState)

  const setShift = useSetRecoilState(dragObjectShiftState)

  const [fontSize, setFontSize] = useState(0)
  const [fontColor, setFontColor] = useState('#000000')
  const [fontFamily, setFontFamily] = useState('Arial')

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement

    // Determine the shift from left and top of the mouse pointer when start dragging
    setShift({
      shiftX: e.clientX - target.getBoundingClientRect().left,
      shiftY: e.clientY - target.getBoundingClientRect().top,
    })

    // Set information (text line incl. index) that has to be dragged
    // This is consumed on VisualPreview
    const _props = cloneDeep(props) as any
    _props.type = "textLine"
    
    e.dataTransfer.setData('text/plain', JSON.stringify(_props))
  }

  const handleDoubleClick = () => {
    setCurrentTextLine(props.lineNo)
    setShowVisualTextLineDialog(true)
  }

  useEffect(() => {
    if (visual && visual.fontStyles?.length) {
      const style = visual.fontStyles.find((s) => {
        return s.styleName === props.textLine.fontStyle
      })
      if (style) {
        setFontSize(style.fontSize)
        setFontColor(style.fontColorHex)
        setFontFamily(style.fontFamily)
      }
    }
  }, [visual, props])

  return (
    <span
      key={props.lineNo}
      draggable={true}
      style={{
        position: 'absolute',
        left: `${props.textLine.x}px`,
        top: `${props.textLine.y}px`,
        fontSize: `${fontSize * RATIO}px`,
        color: fontColor,
        fontFamily: fontFamily,
      }}
      className="hover:cursor-move z-30 absolute"
      onDragStart={handleDragStart}
      onDoubleClick={handleDoubleClick}
    >
      {props.textLine.displayText}
    </span>
  )
}

export default TextLine
