/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Button, Input, message, Alert } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { useRecoilState, useRecoilValue } from 'recoil'
import { accessTokenState, userInfoState } from '../recoil/atoms'
import * as yup from 'yup'
import {
  DeleteUserPasswordUseCase,
  GetUserUseCase,
  UpdateUserAuthMethodUseCase,
  UpdateUserPasswordUseCase,
} from '@axelity/actasign-sic'
import useSignIn from '../hooks/useSignIn'
import SectionTitle from '../elements/SectionTitle'
import FieldTitle from '../elements/FieldTitle'

const ProfilePassword = () => {
  const { t } = useTranslation(['profilePassword', 'profile'])

  const [noAccessToken] = useSignIn()

  const history = useHistory()

  const [userInfo, setUserInfo] = useRecoilState(userInfoState)

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const [inProgress, setInProgress] = useState<boolean>(false)

  const removeHandler = () => {
    new DeleteUserPasswordUseCase({
      userId: userInfo._id as string,
    })
      .delete({
        password: oldPassword,
      })
      .then(() => {
        return new UpdateUserAuthMethodUseCase({
          userId: userInfo._id as string,
        }).update({
          authMethod: 'EMAIL-LINK',
        })
      })
      .then(() => {
        return new GetUserUseCase().getUser()
      })
      .then((_userInfo) => {
        setUserInfo(_userInfo)
      })
      .then(() => {
        setOldPassword('')
        setNewPassword('')
        setConfirmNewPassword('')
        message.success(t('removeSuccessful'))
        history.push('/home')
      })
      .catch(() => {
        message.error(t('profilePassword:updateFailed'))
      })
  }

  const applyHandler = () => {
    if (newPassword !== confirmNewPassword) {
      message.error(t('profilePassword:passwordNotMatch'))
      return
    }

    const schema = yup
      .string()
      .matches(
        new RegExp(
          /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z\u00C0-\u017F])(?=(.*[A-Z]))(?=(.*)).{10,}/,
        ),
      )

    if (!schema.isValidSync(newPassword)) {
      message.error(t('profilePassword:passwordNotStrong'))
      return
    }

    setInProgress(true)

    new UpdateUserPasswordUseCase({
      userId: userInfo._id as string,
    })
      .update({
        oldPassword,
        newPassword,
      })
      .then(() => {
        return new GetUserUseCase().getUser()
      })
      .then((_userInfo) => {
        setUserInfo(_userInfo)
      })
      .then(() => {
        setInProgress(false)
        message.success(t('profilePassword:updateSuccessful'))
        history.push('/home')
      })
      .catch(() => {
        setInProgress(false)
        message.error(t('profilePassword:updateFailed'))
      })
  }

  const cancelHandler = () => {
    history.push('/home')
  }

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('profile:password.name')}
        pages={[
          {
            name: t('profile:userProfile.name'),
          },
          {
            name: t('profile:password.name'),
          },
        ]}
      />

      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        <SectionTitle title={t('profilePassword:title')} />
        <div>
          <p className="mt-2">{t('profilePassword:instructions')}</p>
        </div>

        <Alert
          className="rounded mt-2"
          message={t('profilePassword:alert.notice')}
          description={t('profilePassword:alert.changeSettings')}
          showIcon
          type="info"
        />

        <form className="mt-5">
          <div>
            <FieldTitle title={t('profilePassword:oldPassword')} />
            <p className="text-xs font-medium">{t('profilePassword:oldPasswordHint')}</p>
            <div className="inline-flex w-full">
              <Input
                className="rounded"
                autoComplete="current-password"
                type="password"
                size="large"
                placeholder=""
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              ></Input>
            </div>

            <FieldTitle title={t('profilePassword:newPassword')} />
            <p className="text-xs font-medium">{t('profilePassword:newPasswordHint')}</p>
            <div className="inline-flex w-full">
              <Input
                className="rounded"
                autoComplete="new-password"
                type="password"
                size="large"
                placeholder=""
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              ></Input>
            </div>

            <FieldTitle title={t('profilePassword:confirmNewPassword')} />
            <div className="inline-flex w-full">
              <Input
                className="rounded"
                autoComplete="new-password"
                type="password"
                size="large"
                placeholder=""
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                onPressEnter={applyHandler}
              ></Input>
            </div>

            <div className="flex flex-auto mt-8 justify-between">
              <Button className="rounded font-semibold" size="large" type="ghost" onClick={cancelHandler}>
                {t('profilePassword:back')}
              </Button>
              <div>
                <Button
                  className="ml-2 rounded font-semibold"
                  size="large"
                  danger={true}
                  type="primary"
                  onClick={removeHandler}
                >
                  {t('profilePassword:remove')}
                </Button>
                <Button
                  className="ml-2 rounded font-semibold"
                  loading={inProgress}
                  size="large"
                  type="primary"
                  onClick={applyHandler}
                >
                  {t('profilePassword:apply')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProfilePassword
