/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { Button, Modal, Select, Input, message } from 'antd'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  accountUsersState,
  currentAccountState,
  currentAccountUserState,
  showAccountUserDialogState,
} from '../recoil/atoms'
import { useTranslation } from 'react-i18next'
import {
  CreateUserUseCase,
  InviteAccountUserUseCase,
  QueryUsersUseCase,
  TMemberPlan,
  TMemberRole,
  UpdateAccountUserUseCase,
} from '@axelity/actasign-sic'
import * as yup from 'yup'
import SectionTitle from '../elements/SectionTitle'
import FieldTitle from '../elements/FieldTitle'

const { Option } = Select

const AccountUser = () => {
  const { t } = useTranslation(['common', 'accountUser'])

  const setUsers = useSetRecoilState(accountUsersState)
  const currentAccount = useRecoilValue(currentAccountState)

  const [currentAccountUser, setCurrentAccountUser] = useRecoilState(currentAccountUserState)
  const resetCurrentAccountUser = useResetRecoilState(currentAccountUserState)

  const emailRef = useRef(null)
  const firstNameRef = useRef(null)

  const [firstName, setFirstName] = useState<string>('')
  const [familyName, setFamilyName] = useState<string>('')
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [language, setLanguage] = useState<string>('')
  const [plan, setPlan] = useState<TMemberPlan>('QES')
  const [roles, setRoles] = useState<TMemberRole[]>(['SIGNER'])

  const [isUpdating, setUpdating] = useState<boolean>(false)

  const [showDialog, setShowDialog] = useRecoilState(showAccountUserDialogState)

  const [canSearch, setCanSearch] = useState<boolean>(false)
  const [searchExecuted, setSearchExecuted] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [userExists, setUserExists] = useState<boolean>(false)

  const lookup = () => {
    if (!canSearch) return

    setLoading(true)

    new QueryUsersUseCase()
      .query({
        emailAddress,
      })
      .then((users) => {
        if (users.length > 0) {
          const userInfo = users[0]
          setCurrentAccountUser(userInfo)

          let _plan: TMemberPlan
          let _roles: TMemberRole[]
          if (userInfo.memberships) {
            _plan = userInfo.memberships[0].plan
            _roles = userInfo.memberships[0].roles
          }

          setFirstName(userInfo.firstName)
          setFamilyName(userInfo.familyName)
          setEmailAddress(userInfo.email?.emailAddress || '')
          setLanguage(userInfo.language)
          setPlan(_plan || 'QES')
          setRoles(_roles || ['SIGNER'])
          setUserExists(true)
        } else {
          setFirstName('')
          setFamilyName('')
          setLanguage('')
        }
        setSearchExecuted(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const handleChangeEmailAddress = (value: string) => {
    setEmailAddress(value)
    setSearchExecuted(false)
    if (yup.string().required().matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).isValidSync(value)) {
      setCanSearch(true)
    }
  }

  const processMembership = async () => {
    if (currentAccountUser) {
      await new UpdateAccountUserUseCase({
        accountId: currentAccount._id,
        userId: currentAccountUser._id,
      }).update({
        plan,
        roles,
      })
    } else {
      const userInfo = await new CreateUserUseCase().create({
        emailAddress,
        firstName,
        familyName,
        language,
      })

      await new InviteAccountUserUseCase({
        accountId: currentAccount._id,
        userId: userInfo._id,
      }).invite({
        plan,
        roles,
      })
    }

    // Update account users
    const _users = await new QueryUsersUseCase().query({
      account: currentAccount._id,
    })
    setUsers(_users)
  }

  const handleOk = () => {
    setUpdating(true)

    if (currentAccountUser) {
      processMembership()
        .then(() => {
          message.success(t('accountUser:result.updateSuccesful'))
          resetCurrentAccountUser()
          setShowDialog(false)
        })
        .catch((error) => {
          message.error(t('accountUser:result.updateFailed'))
          console.error(error)
        })
        .finally(() => {
          setUpdating(false)
        })
    } else {
      processMembership()
        .then(() => {
          message.success(t('accountUser:result.inviteSuccesful'))
          resetCurrentAccountUser()
          setShowDialog(false)
        })
        .catch((error) => {
          message.error(t('accountUser:result.inviteFailed'))
          console.error(error)
        })
        .finally(() => {
          setUpdating(false)
        })
    }
  }

  const handleCancel = () => {
    resetCurrentAccountUser()
    setShowDialog(false)
  }

  const handleReset = () => {
    setFirstName('')
    setFamilyName('')
    setEmailAddress('')
    setLanguage('')
    setPlan('QES')
    setRoles(['SIGNER'])
    setCanSearch(false)
    setLoading(false)
    setUserExists(false)
    setSearchExecuted(false)
    resetCurrentAccountUser()
  }

  useEffect(() => {
    if (searchExecuted && !userExists) {
      firstNameRef.current.focus()
    }
  }, [searchExecuted, userExists])

  useEffect(() => {
    if (currentAccountUser) {
      let _plan: TMemberPlan
      let _roles: TMemberRole[]
      if (currentAccountUser.memberships) {
        _plan = currentAccountUser.memberships[0].plan
        _roles = currentAccountUser.memberships[0].roles
      }

      setFirstName(currentAccountUser.firstName)
      setFamilyName(currentAccountUser.familyName)
      setEmailAddress(currentAccountUser.email.emailAddress)
      setLanguage(currentAccountUser.language)
      setPlan(_plan || 'QES')
      setRoles(_roles || ['SIGNER'])
      setUserExists(true)
      setSearchExecuted(true)
    } else {
      handleReset()
    }
  }, [currentAccountUser])

  return (
    <Modal
      title={t('accountUser:title')}
      visible={showDialog}
      footer={
        !currentAccountUser
          ? [
              <Button className="rounded font-semibold" key="reset" type="ghost" onClick={handleReset}>
                {t('accountUser:action.reset')}
              </Button>,
              <Button className="rounded font-semibold" key="cancel" onClick={handleCancel}>
                {t('accountUser:action.cancel')}
              </Button>,
              <Button className="rounded font-semibold" key="apply" type="primary" onClick={handleOk} loading={isUpdating}>
                {t('accountUser:action.apply')}
              </Button>,
            ]
          : [
              <Button className="rounded font-semibold" key="cancel" onClick={handleCancel}>
                {t('accountUser:action.cancel')}
              </Button>,
              <Button className="rounded font-semibold" key="apply" type="primary" onClick={handleOk} loading={isUpdating}>
                {t('accountUser:action.apply')}
              </Button>,
            ]
      }
    >
      <div className="mt-2">
        <FieldTitle title={t('accountUser:emailAddress')} />
        <div className="flex w-full">
          <Input
            className="rounded"
            type="email"
            size="large"
            disabled={userExists}
            value={emailAddress}
            onChange={(e) => handleChangeEmailAddress(e.target.value)}
            onPressEnter={lookup}
            ref={emailRef}
          ></Input>

          <Button className="ml-2 rounded font-semibold" disabled={!canSearch} size="large" type="primary" onClick={(e) => lookup()} loading={isLoading}>
            {t('accountUser:action.add')}
          </Button>
        </div>

        {searchExecuted ? (
          <div>
            {!userExists ? <SectionTitle title={t('accountUser:newUser')} /> : null}

            <FieldTitle title={t('accountUser:firstName')} />
            <div className="inline-flex w-full">
              <Input
                ref={firstNameRef}
                className="rounded"
                autoComplete="first-name"
                type="text"
                size="large"
                disabled={userExists}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              ></Input>
            </div>

            <FieldTitle title={t('accountUser:familyName')} />
            <div className="inline-flex w-full">
              <Input
                className="rounded"
                autoComplete="family-name"
                type="text"
                size="large"
                disabled={userExists}
                value={familyName}
                onChange={(e) => setFamilyName(e.target.value)}
              ></Input>
            </div>

            {/**
             * Language
             */}
            <FieldTitle title={t('accountUser:language')} />
            <div className="inline-flex w-full">
              <div className="w-full">
                <Select
                  className="rounded"
                  disabled={userExists}
                  value={language}
                  size="large"
                  style={{ width: '100%' }}
                  onChange={(value) => setLanguage(value)}
                >
                  <Option value="de">{t('common:language.german')}</Option>
                  <Option value="en">{t('common:language.english')}</Option>
                  {/* <Option value="fr">{t('common:language.french')}</Option> */}
                </Select>
              </div>
            </div>

            {/**
             * Plan
             */}
            <FieldTitle title={t('accountUser:plan.title')} />
            <div className="inline-flex w-full">
              <div className="w-full">
                <Select
                  className="rounded"
                  value={plan}
                  size="large"
                  style={{ width: '100%' }}
                  onChange={(value) => setPlan(value)}
                >
                  <Option value="QES">{t('accountUser:plan.qes')}</Option>
                  <Option value="AES">{t('accountUser:plan.aes')}</Option>
                  <Option value="SES">{t('accountUser:plan.ses')}</Option>
                  <Option value="PREMIUM">{t('accountUser:plan.premium')}</Option>
                  <Option value="PARTNER">{t('accountUser:plan.partner')}</Option>
                </Select>
              </div>
            </div>

            {/**
             * Member roles
             */}
            <FieldTitle title={t('accountUser:role.title')} />
            <div className="inline-flex w-full">
              <div className="w-full">
                <Select
                  mode="multiple"
                  value={roles}
                  size="large"
                  style={{ width: '100%' }}
                  className="text-sm"
                  onChange={(value) => setRoles(value)}
                >
                  <Option className="text-sm" value="SIGNER">
                    {t('accountUser:role.signer')}
                  </Option>
                  <Option className="text-sm" value="USER-ADMIN">
                    {t('accountUser:role.userAdmin')}
                  </Option>
                  <Option className="text-sm" value="PRIMARY-ADMIN">
                    {t('accountUser:role.primaryAdmin')}
                  </Option>
                </Select>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  )
}

export default AccountUser
