/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { requestState, taskState } from '../recoil/atoms'
import { useEffect, useState } from 'react'
import { Spin } from 'antd'
import { IProcess, ITask, QueryProcessesUseCase, QueryTasksUseCase } from '@axelity/actasign-sic'
import { isEmpty } from 'lodash'
import SectionTitle from '../elements/SectionTitle'
import { ClipboardListIcon, CalendarIcon, UserIcon } from '@heroicons/react/outline'

const Timeline = () => {
  const { t } = useTranslation(['timeline'])

  const request = useRecoilValue(requestState)
  const task = useRecoilValue(taskState)

  const [orderedTasks, setOrderedTasks] = useState<ITask[]>([])

  const [inProgress, setInProgress] = useState<boolean>(false)

  const buildTimeline = async () => {
    if (!request && !task) return

    let _request: IProcess

    if (request) {
      _request = Object.assign({}, request)
    } else {
      const _requests = await new QueryProcessesUseCase().query({
        id: task.process as string,
      })
      if (_requests && _requests.length > 0) {
        _request = Object.assign({}, _requests[0])
      }
    }

    let _tasks: ITask[] = []
    _tasks = await new QueryTasksUseCase().query({
      process: _request._id,
    })

    // order tasks by activity order
    let _orderedTasks: ITask[] = []
    for (let i = 0; i < _request.activities.length; i++) {
      const _filteredTasks = _tasks.filter((_task) => {
        return _task.activity === _request.activities[i]
      })

      _orderedTasks = [..._orderedTasks, ..._filteredTasks]
    }

    setOrderedTasks(_orderedTasks)
  }

  useEffect(() => {
    if (request && !isEmpty(request)) {
      setInProgress(true)
      buildTimeline()
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setInProgress(false)
        })
    }
  }, [request])

  return (
    <div className="w-full p-4">
      {/**
       * Title
       */}
      <SectionTitle title={t('timeline:title')} />

      {/**
       * Add/change activity
       */}
      <div className="w-full">
        <Spin spinning={inProgress} size="large">
          {orderedTasks.map((task) => {
            return (
              <div key={task._id} className="flex my-2 flex-row items-center justify-between">
                <div style={{ width: '60%' }} className="flex">
                  {/**
                   * Task user
                   */}
                  <div style={{ width: '50%' }} className="flex">
                    <UserIcon className="w-5 h-5 text-acs-blue-600" />
                    <div className="ml-2">{`${task.userInfo.firstName} ${task.userInfo.familyName}`}</div>
                  </div>

                  {/**
                   * Task type
                   */}
                  <div className="flex">
                    <div>
                      <ClipboardListIcon className="w-5 h-5" />
                    </div>
                    <div className="ml-2">{t(`timeline:${task.activityType.toLowerCase()}`)}</div>
                  </div>
                </div>

                {/**
                 * Time depending on status
                 */}
                <div className="flex justify-between">
                  {task.status === 'ACTIVE' ? (
                    <div className="rounded-md ml-2 px-2 py-1 text-xs font-semibold bg-acs-blue-100 text-acs-blue-600">
                      {t(`timeline:status.${task.status.toLowerCase()}`)}
                    </div>
                  ) : null}

                  {task.status === 'COMPLETED' ? (
                    <div className="rounded-md ml-2 px-2 py-1 text-xs font-semibold bg-green-100 text-green-700">
                      {t(`timeline:status.${task.status.toLowerCase()}`)}
                    </div>
                  ) : null}

                  {task.status === 'DECLINED' ? (
                    <div className="rounded-md ml-2 px-2 py-1 text-xs font-semibold bg-red-100 text-red-700">
                      {t(`timeline:status.${task.status.toLowerCase()}`)}
                    </div>
                  ) : null}

                  {task.status === 'PENDING' ||
                  task.status === 'OBSOLETED' ||
                  task.status === 'SUPERCEDED' ||
                  task.status === 'SUSPENDED' ? (
                    <div className="rounded-md ml-2 px-2 py-1 text-xs font-semibold bg-gray-100 text-gray-700">
                      {t(`timeline:status.${task.status.toLowerCase()}`)}
                    </div>
                  ) : null}

                  {task.status === 'COMPLETED' ? (
                    <div className="ml-8 flex">
                      <CalendarIcon className="w-5 h-5" />
                      <div className="ml-2">
                        {Intl.DateTimeFormat(task.userInfo.language, {
                          dateStyle: 'medium',
                          timeStyle: 'short',
                        }).format(new Date(`${task.completed}`))}
                      </div>
                    </div>
                  ) : null}

                  {task.status === 'DECLINED' ? (
                    <div className="ml-8 flex">
                      <CalendarIcon className="w-5 h-5" />
                      <div className="ml-2">
                        {Intl.DateTimeFormat(task.userInfo.language, {
                          dateStyle: 'medium',
                          timeStyle: 'short',
                        }).format(new Date(`${task.declined}`))}
                      </div>
                    </div>
                  ) : null}

                  {task.status === 'SUPERCEDED' ? (
                    <div className="ml-8 flex">
                      <CalendarIcon className="w-5 h-5" />
                      <div className="ml-2">
                        {Intl.DateTimeFormat(task.userInfo.language, {
                          dateStyle: 'medium',
                          timeStyle: 'short',
                        }).format(new Date(`${task.superceded}`))}
                      </div>
                    </div>
                  ) : null}

                  {/* {task.status === 'ACTIVE' || task.status === 'PENDING' ? (
                    <div>
                      -
                    </div>
                  ) : null} */}
                </div>
              </div>
            )
          })}
        </Spin>
      </div>
    </div>
  )
}

export default Timeline
