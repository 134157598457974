/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { currentAccountState, userInfoState } from '../recoil/atoms'
import { useEffect, useState } from 'react'
import { Select, Button } from 'antd'
import { IUserInfo, IVisual, QueryUsersUseCase, UpdateVisualUseCase } from '@axelity/actasign-sic'
import SectionTitle from '../elements/SectionTitle'
import { cloneDeep } from 'lodash'
import FieldTitle from '../elements/FieldTitle'

const { Option } = Select

type TVisualAssignmentProps = {
  visual: IVisual
  onAssign: () => void
  onCancel: () => void
}

const VisualAssignment = ({ visual, onAssign, onCancel }: TVisualAssignmentProps) => {
  const { t } = useTranslation(['visualAssignment'])

  const currentAccount = useRecoilValue(currentAccountState)
  const userInfo = useRecoilValue(userInfoState)

  const [accountUsers, setAccountUsers] = useState<IUserInfo[]>([])
  const [selectedUser, setSelectedUser] = useState<string>('')
  const [inProgress, setInProgress] = useState<boolean>(false)

  const retrieveAccountUsers = async () => {
    let _users = await new QueryUsersUseCase().query({
      account: currentAccount._id,
    })

    _users = _users.filter((_user) => {
      return _user._id !== userInfo._id
    })

    setAccountUsers(_users)
  }

  const handleAssign = async () => {
    let _visual = cloneDeep(visual)

    _visual = await new UpdateVisualUseCase({
      visualId: _visual._id,
    }).update({
      account: currentAccount._id,
      workspace: _visual.workspace as string,
      user: selectedUser,
      displayName: _visual.displayName,
      availability: 'USER',
      background: _visual.background,
      border: _visual.border,
      width: _visual.width,
      height: _visual.height,
      language: _visual.language,
      settings: _visual.settings,
      defaultReason: _visual.defaultReason,
      fontStyles: _visual.fontStyles,
      images: _visual.images,
      position: _visual.position,
      textLines: _visual.textLines,
      visualDataUrl: _visual.visualDataUrl,
    })

    onAssign()
  }

  const handleCancel = async () => {
    onCancel()
  }

  useEffect(() => {
    if (visual) {
      setInProgress(true)
      retrieveAccountUsers()
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setInProgress(false)
        })
    }
  }, [visual])

  return (
    <div className="w-full p-4">
      {/**
       * Title
       */}
      <h3 className="font-semibold text-md text-gray-600 mb-2">{t('visualAssignment:title')}</h3>
      <p className="font-medium text-sm text-gray-600">{t('visualAssignment:user')}</p>
      <div className="w-full flex">
        <Select
          showSearch={true}
          className="rounded"
          value={selectedUser}
          size="middle"
          style={{ width: '100%' }}
          onChange={(value) => setSelectedUser(value)}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {accountUsers.map((_user) => {
            return (
              <Option
                key={_user._id}
                value={_user._id}
              >{`${_user.firstName} ${_user.familyName} (${_user.email?.emailAddress})`}</Option>
            )
          })}
        </Select>

        <Button className="ml-2 rounded font-semibold" size="middle" type="primary" onClick={handleAssign}>
          {t('visualAssignment:action.assign')}
        </Button>

        <Button className="ml-2 rounded font-semibold" size="middle" type="ghost" onClick={handleCancel}>
          {t('visualAssignment:action.cancel')}
        </Button>
      </div>
    </div>
  )
}

export default VisualAssignment
