import { IUserInfo, TUserStatus } from '@axelity/actasign-sic'
import UserItem from './UserItem'

type UserListProps = {
  users: IUserInfo[]
  status?: TUserStatus | undefined 
}

const TaskList = (props: UserListProps) => {
  let filteredUsers: IUserInfo[] = []
  if (props.status) {
    filteredUsers = props.users.filter(user => {
      return user.status === props.status
    })
  } else {
    filteredUsers = [...props.users]
  }

  return (
    <div>
      {filteredUsers.map((user) => {
        return <UserItem key={user._id} user={user} />
      })}
    </div>
  )
}

export default TaskList
