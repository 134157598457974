type TFieldTitleProps = {
  title: string
}
const FieldTitle = ({title}: TFieldTitleProps) => {
  return (
    <h3 className="mt-3 mb-1 text-gray-600 font-medium">{title}</h3>
  )
}

export default FieldTitle
