/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Button, Input, message } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { useRecoilState } from 'recoil'
import { userInfoState } from '../recoil/atoms'
import {
  ConfirmUserTOTPUseCase,
  DeleteUserTOTPUseCase,
  GetUserTOTPUseCase,
  GetUserUseCase,
  UpdateUserAuthMethodUseCase,
  UpdateUserTOTPUseCase,
} from '@axelity/actasign-sic'
import useSignIn from '../hooks/useSignIn'
import SectionTitle from '../elements/SectionTitle'
import FieldTitle from '../elements/FieldTitle'

const ProfileTOTP = () => {
  const { t } = useTranslation(['profileTOTP', 'profile'])

  const [noAccessToken] = useSignIn()

  const history = useHistory()

  const [userInfo, setUserInfo] = useRecoilState(userInfoState)

  const [totpQRCode, setTotpQRCode] = useState('')
  const [totpSecret, setTotpSecret] = useState('')
  const [confirmationCode, setConfirmationCode] = useState('')

  const confirmationHandler = async (e: any) => {
    e.preventDefault()
    new ConfirmUserTOTPUseCase({
      userId: userInfo._id as string,
    })
      .confirm({
        challengeResponse: confirmationCode,
      })
      .then(() => {
        return new GetUserUseCase().getUser()
      })
      .then((userInfo) => {
        setUserInfo(userInfo)
        setConfirmationCode('')
      })
      .then(() => {
        message.success(t('verificationSuccessful'))
        history.push('/home')
      })
      .catch((error) => {
        console.error(error)
        message.error(t('verificationFailed'))
      })
  }

  const removeHandler = () => {
    new DeleteUserTOTPUseCase({
      userId: userInfo._id as string,
    })
      .delete()
      .then(() => {
        setTotpSecret('')
        setTotpQRCode('')
      })
      .then(() => {
        return new UpdateUserAuthMethodUseCase({
          userId: userInfo._id as string,
        }).update({
          authMethod: 'EMAIL-LINK',
        })
      })
      .then(() => {
        return new GetUserUseCase().getUser()
      })
      .then((userInfo) => {
        message.info(t('totpDeleted'))
        setUserInfo(userInfo)
        setConfirmationCode('')
        history.push('/home')
      })
  }

  const createHandler = () => {
    new UpdateUserTOTPUseCase({
      userId: userInfo._id as string,
    })
      .update()
      .then((response) => {
        setTotpQRCode(response.totpQRCode)
        setTotpSecret(response.totpSecret)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const applyHandler = () => {
    history.push('/home')
  }

  useEffect(() => {
    if (userInfo && userInfo._id) {
      if (userInfo.totp) {
        new GetUserTOTPUseCase({
          userId: userInfo._id as string,
        })
          .get()
          .then((response) => {
            setTotpQRCode(response.totpQRCode)
            setTotpSecret(response.totpSecret)
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }, [userInfo])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('profile:totp.name')}
        pages={[
          {
            name: t('profile:userProfile.name'),
          },
          {
            name: t('profile:totp.name'),
          },
        ]}
      />

      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        <SectionTitle title={t('profileTOTP:title')} />

        <div>
          <p className="mt-2">{t('profileTOTP:instructions')}</p>
        </div>

        <div className="mt-5">
          {totpQRCode ? (
            <div>
              <div className="p-0 m-0">
                <img className="object-scale-down w-48" src={totpQRCode} alt="TOTP" />
              </div>

              <FieldTitle title={t('profileTOTP:confirmationCode')} />
              <div className="inline-flex mt-1">
                <Input
                  className="rounded"
                  type="text"
                  size="large"
                  placeholder={t('profileTOTP:confirmationCode')}
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  onPressEnter={confirmationHandler}
                ></Input>

                <div>
                  <Button className="ml-2 rounded font-semibold" size="large" type="primary" onClick={confirmationHandler}>
                    {t('profileTOTP:confirm')}
                  </Button>
                </div>
              </div>

              <p className="my-4 font-normal">{t('profileTOTP:manualInput')}</p>
              <p className="mb-10 font-semibold text-2xl">{totpSecret}</p>

              <div className="flex flex-auto mt-4 justify-between">
                <Button className="rounded font-semibold" size="large" danger={true} type="primary" onClick={removeHandler}>
                  {t('profileTOTP:remove')}
                </Button>
                <Button className="rounded font-semibold" size="large" type="ghost" onClick={applyHandler}>
                  {t('profileTOTP:back')}
                </Button>
              </div>
            </div>
          ) : null}

          {!totpQRCode ? (
            <div>
              <div className="flex flex-auto mt-4 justify-end">
                <Button className="ml-2 rounded font-semibold" size="large" type="primary" onClick={createHandler}>
                  {t('profileTOTP:create')}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ProfileTOTP
