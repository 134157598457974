/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import PageList from '../components/PageList'
import SignatureFieldList from '../components/SignatureFieldList'
import { TPages, TSignatureField, TSignatureFields } from '../types/appTypes'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  documentState,
  fastSignatureState,
  pageListState,
  signatureListState,
  taskListState,
  viewerAddressedByState,
  viewerState,
  viewingDocumentState,
} from '../recoil/atoms'
import { QuerySignaturesUseCase, RenderDocumentPagesUseCase, StartProcessUseCase } from '@axelity/actasign-sic'
import { useEffect } from 'react'
import { Spin } from 'antd'

const Viewer = () => {
  const { t } = useTranslation(['viewer'])

  const history = useHistory()

  const requestDocument = useRecoilValue(documentState)
  const taskList = useRecoilValue(taskListState)
  const viewerAddressedBy = useRecoilValue(viewerAddressedByState)

  const [signatures, setSignatures] = useRecoilState(signatureListState)
  const resetSignatures = useResetRecoilState(signatureListState)
  const fastSignature = useRecoilValue(fastSignatureState)
  const viewType = useRecoilValue(viewerState)
  const [viewingDocument, setViewingDocument] = useRecoilState(viewingDocumentState)

  const [pages, setPages] = useRecoilState(pageListState)
  const resetPages = useResetRecoilState(pageListState)

  const [fields, setFields] = useState<TSignatureFields>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleApply = async () => {
    if (fastSignature && fastSignature === 'SINGLE') {
      await new StartProcessUseCase({
        processId: requestDocument.process as string,
      }).start()

      setViewingDocument(false)
      history.push('/consent')
    } else {
      history.push(viewerAddressedBy)
    }
  }

  const handleBack = () => {
    setViewingDocument(false)
    history.push(viewerAddressedBy)
  }

  useEffect(() => {
    if (requestDocument) {
      setIsLoading(true)
      resetPages()

      new RenderDocumentPagesUseCase({
        documentId: requestDocument._id,
      })
        .render({
          dpi: 72,
          fileType: 'png',
        })
        .then((_renderedPages) => {
          const _pages: TPages = []
          for (let i = 0; i < _renderedPages.length; i++) {
            _pages.push({
              documentId: requestDocument._id,
              pageNo: _renderedPages[i].pageNo,
              height: _renderedPages[i].height,
              width: _renderedPages[i].width,
              label: `${requestDocument.displayName} page ${_renderedPages[i].pageNo}`,
              src: _renderedPages[i].dataUrl,
            })
          }
          setPages(_pages)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [requestDocument])

  const prepareSignatureFields = () => {
    if (pages.length > 0 && taskList.length > 0) {
      // get all signing tasks
      const signingTasks = taskList.filter((task) => {
        return (
          task.activityType === 'SIGN' &&
          task.process === requestDocument.process &&
          (task.status === 'PENDING' || task.status === 'ACTIVE')
        )
      })

      // initialize signature fields
      const _fields: TSignatureField[] = [
        ...signingTasks.map((task) => {
          const field: TSignatureField = {
            label: `${task.userInfo.firstName} ${task.userInfo.familyName}`,
            signed: false,
            placed: false,
            taskId: task._id,
            documentId: requestDocument._id,
          }
          return field
        }),
      ]

      for (let i = 0; i < _fields.length; i++) {
        const _signature = signatures.find((s) => {
          return s.document === requestDocument._id && s.task === _fields[i].taskId
        })

        // if there is a signature for this document and task, we update the field information
        // so that it can be placed on the document
        if (_signature) {
          _fields[i].placed = true
          _fields[i].signed = _signature.status === 'SIGNED' ? true : false
          _fields[i].page = _signature.rectangle.page
          _fields[i].x = _signature.rectangle.x
          _fields[i].y = _signature.rectangle.y
        }
      }

      setFields(_fields)
    }
  }

  useEffect(() => {
    if (requestDocument && signatures && pages && pages.length > 0 && taskList) {
      prepareSignatureFields()
    }
  }, [signatures, pages, taskList])

  useEffect(() => {
    if (requestDocument) {
      // Retrieve signatures
      new QuerySignaturesUseCase()
        .query({
          document: requestDocument._id,
        })
        .then((_signatures) => {
          if (_signatures.length > 0) {
            setSignatures(_signatures)
          } else {
            resetSignatures()
          }
        })
    }
  }, [requestDocument])

  useEffect(() => {
    setViewingDocument(true)
    resetPages()
  }, [])

  return (
    <div>
      <NavBar />

      <div>
        {signatures && signatures.length > 0 ? (
          <div>
            {viewType === 'CHANGE' ? (
              <PageHeader
                title={t('viewer:title')}
                pages={[
                  {
                    name: t('viewer:title'),
                  },
                ]}
                buttons={[
                  {
                    label: t('viewer:action.apply'),
                    type: 'primary',
                    href: '#',
                    onClick: handleApply,
                  },
                ]}
              />
            ) : (
              <PageHeader
                title={t('viewer:title')}
                pages={[
                  {
                    name: t('viewer:title'),
                  },
                ]}
                buttons={[
                  {
                    label: t('viewer:action.back'),
                    type: 'primary',
                    href: '#',
                    onClick: handleBack,
                  },
                ]}
              />
            )}
          </div>
        ) : (
          <PageHeader
            title={t('viewer:title')}
            pages={[
              {
                name: t('viewer:title'),
              },
            ]}
            buttons={[
              {
                label: t('viewer:action.back'),
                type: 'primary',
                href: '#',
                onClick: handleBack,
              },
            ]}
          />
        )}
      </div>

      <div className="max-w-7xl mx-auto text-base bg-white mt-4">
        <div className="flex flex-row flex-nowrap justify-center">
          {isLoading ? (
            <div className="mt-40 flex flex-row justify-center w-full">
              <Spin spinning={isLoading} size="large" tip={t('viewer:action.loading')} />
            </div>
          ) : (
            <div className="min-w-max">
              <PageList pages={pages} />
            </div>
          )}
          <div>{pages && pages.length > 0 ? <SignatureFieldList fields={fields} /> : null}</div>
        </div>
      </div>
    </div>
  )
}

export default Viewer
