/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AuthorizeUserUseCase, ITask, QueryTasksUseCase } from '@axelity/actasign-sic'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import {
  accessTokenState,
  currentPlanState,
  currentProcessStepState,
  requestState,
  taskListState,
  userInfoState,
  viewerAddressedByState,
} from '../recoil/atoms'
import Tile from '../elements/Tile'
import useSignIn from '../hooks/useSignIn'
import FastSignature from '../components/FastSignature'
import { Button, Alert } from 'antd'
import moment from 'moment'

const Home = () => {
  // Read any query parameters for login
  const search = useLocation().search
  const challenge = new URLSearchParams(search).get('challenge')
  const otp = new URLSearchParams(search).get('otp')
  const queryAccessToken = new URLSearchParams(search).get('access_token')

  const setViewerAddressedBy = useSetRecoilState(viewerAddressedByState)
  const resetAccessToken = useResetRecoilState(accessTokenState)
  const resetUserInfo = useResetRecoilState(userInfoState)

  const { t } = useTranslation(['common'])

  const history = useHistory()

  const [noAccessToken] = useSignIn()

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState)
  const userInfo = useRecoilValue(userInfoState)
  const resetRequest = useResetRecoilState(requestState)
  const resetCurrentProcessStep = useResetRecoilState(currentProcessStepState)
  const currentPlan = useRecoilValue(currentPlanState)
  const [tasks, setTasks] = useRecoilState(taskListState)

  const [activeTasks, setActiveTasks] = useState<ITask[]>([])
  const [alertMobile, setAlertMobile] = useState<boolean>(false)
  const [alertAuth, setAlertAuth] = useState<boolean>(false)

  const handleNewRequest = () => {
    resetCurrentProcessStep()
    resetRequest()
    history.push('/request')
  }

  const handleGoToTasks = () => {
    history.push('/tasks')
  }

  const refreshUserTasks = async () => {
    const _tasks = await new QueryTasksUseCase().query({
      user: userInfo._id,
    })
    setTasks(_tasks)
  }

  useEffect(() => {
    if (tasks) {
      setActiveTasks(
        tasks.filter((_task) => {          
          return _task.status === 'ACTIVE'
        }),
      )
    }
  }, [tasks])

  // useEffect(() => {
  //   if (challenge && otp) {
  //     new AuthorizeUserUseCase()
  //       .authorize({
  //         challenge,
  //         otp,
  //       })
  //       .then((response) => {
  //         setAccessToken(response.access_token)
  //       })
  //       .catch((error) => {
  //         console.error(error)
  //         history.replace('/signin')
  //       })
  //   }
  // }, [challenge, otp])

  // useEffect(() => {
  //   if (queryAccessToken) {
  //     setAccessToken(queryAccessToken)
  //   }
  // }, [queryAccessToken])

  useEffect(() => {
    if (accessToken) {
      history.replace('/home')
    }
  }, [accessToken])

  useEffect(() => {
    if (userInfo) {
      refreshUserTasks()
      
      if (!userInfo.mobile || !userInfo.mobile.lastVerified) {
        setAlertMobile(true)
      }

      const totpAvailable = userInfo.totp && userInfo.totp.lastVerified
      const passwordAvailable = userInfo.password && userInfo.password.lastVerified

      if (!totpAvailable && !passwordAvailable) {
        setAlertAuth(true)
      }
    }
  }, [userInfo])

  useEffect(() => {
    if (sessionStorage.getItem('pivt') === 'true') {
      resetUserInfo()
      resetAccessToken()
      sessionStorage.removeItem('pivt')
      history.push('/')
    } else {
      // This is because history.goBack() is not working with other Browsers than Chrome
      setViewerAddressedBy('/home')
    }
  }, [])

  return (
    <div>
      <NavBar />

      <PageHeader title={t('common:dashboard')} />

      {userInfo ? (
        <div className="container max-w-4xl mx-auto">
          <h2 className="mt-10 font-bold text-2xl">
            {t('common:hello')} {`${userInfo.firstName} ${userInfo.familyName}`}
          </h2>

          {alertMobile ? (
            <Alert
              className="rounded my-1"
              message={t('common:alert.warn')}
              description={t('common:alert.confirmMobile')}
              showIcon
              type="warning"
            />
          ) : null}

          {alertAuth ? (
            <Alert
              className="rounded my-1"
              message={t('common:alert.warn')}
              description={t('common:alert.addSecureAuth')}
              showIcon
              type="warning"
            />
          ) : null}

          <div className="mt-8 flex justify-between flex-wrap">
            <Tile
              title={t('common:tile.activeTasks.title')}
              instructions={t('common:tile.activeTasks.instructions')}
              onClick={handleGoToTasks}
            >
              {activeTasks.length > 0 ? (
                <div className="w-full">
                  <div style={{ fontSize: '32px' }} className="mt-4 text-center">
                    {activeTasks.length}
                  </div>
                  {activeTasks.length > 1 ? (
                    <p className="mt-4 font-semibold text-lg">{t('common:tile.activeTasks.multipleTasksAreWaiting')}</p>
                  ) : (
                    <p className="mt-4 font-semibold text-lg">{t('common:tile.activeTasks.oneTaskIsWaiting')}</p>
                  )}
                </div>
              ) : (
                <p className="mt-4 font-semibold text-xl">{t('common:tile.activeTasks.noTasksAreWaiting')}</p>
              )}
            </Tile>

            <Tile
              title={t('common:tile.signDocuments.title')}
              instructions={t('common:tile.signDocuments.instructions')}
              show={currentPlan !== 'GUEST' && currentPlan !== 'PARTNER'}
            >
              <FastSignature />
            </Tile>

            <Tile
              title={t('common:tile.newRequest.title')}
              instructions={t('common:tile.newRequest.instructions')}
              show={currentPlan !== 'GUEST' && currentPlan !== 'PARTNER'}
            >
              <Button
                className="w-full mt-8 rounded font-semibold"
                size="large"
                type="primary"
                onClick={handleNewRequest}
              >
                {t('common:newRequest')}
              </Button>
            </Tile>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Home
