import { useEffect, useState } from 'react'
import { Button, Input, Modal, Select } from 'antd'
import { useRecoilState } from 'recoil'
import { showVisualFontStyleDialogState, visualState } from '../../recoil/atoms'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'
import SectionTitle from '../../elements/SectionTitle'
import FieldTitle from '../../elements/FieldTitle'

const { Option } = Select

const VisualFontStyle = () => {
  const { t } = useTranslation(['profileVisual'])

  const [visual, setVisual] = useRecoilState(visualState)

  const [showVisualFontStyleDialog, setShowVisualFontStyleDialog] = useRecoilState(showVisualFontStyleDialogState)

  const [colorHexA, setColorHexA] = useState<string>('#000000')
  const [fontSizeA, setFontSizeA] = useState<number>(7)
  const [fontFamilyA, setFontFamilyA] = useState<string>('Arial')

  const [colorHexB, setColorHexB] = useState<string>('#999999')
  const [fontSizeB, setFontSizeB] = useState<number>(4)
  const [fontFamilyB, setFontFamilyB] = useState<string>('Arial')

  const handleFontStyleOk = () => {
    const _visual = cloneDeep(visual)
    setVisual(
      Object.assign(_visual, {
        fontStyles: [
          {
            fontColorHex: colorHexA,
            fontSize: fontSizeA,
            fontFamily: fontFamilyA,
            styleName: 'A',
          },
          {
            fontColorHex: colorHexB,
            fontSize: fontSizeB,
            fontFamily: fontFamilyB,
            styleName: 'B',
          },
        ],
      }),
    )

    setShowVisualFontStyleDialog(false)
  }

  const handleFontStyleCancel = () => {
    setShowVisualFontStyleDialog(false)
  }

  useEffect(() => {
    if (visual && visual.fontStyles?.length && visual.fontStyles?.length > 0) {
      setColorHexA(visual.fontStyles[0].fontColorHex)
      setFontSizeA(visual.fontStyles[0].fontSize)
      setFontFamilyA(visual.fontStyles[0].fontFamily)
    }

    if (visual && visual.fontStyles?.length && visual.fontStyles?.length > 1) {
      setColorHexB(visual.fontStyles[1].fontColorHex)
      setFontSizeB(visual.fontStyles[1].fontSize)
      setFontFamilyB(visual.fontStyles[1].fontFamily)
    }
  }, [visual, showVisualFontStyleDialog])

  return (
    <Modal
      title={t('profileVisual:fontStyle.title')}
      visible={showVisualFontStyleDialog}
      footer={[
        <Button className="rounded font-semibold" key="cancel" onClick={handleFontStyleCancel}>
          {t('profileVisual:fontStyle.cancel')}
        </Button>,
        <Button className="rounded font-semibold" key="apply" type="primary" onClick={handleFontStyleOk}>
          {t('profileVisual:fontStyle.apply')}
        </Button>,
      ]}
    >
      {/**
       * Font style A
       */}
      <SectionTitle title={t('profileVisual:fontStyle.typeA')} />

      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        {/**
         * Font color A
         */}
        <div className="col-span-2">
          <FieldTitle title={t('profileVisual:fontStyle.fontColor')} />
          <div className="flex w-full">
            <div className="w-11">
              <Input
                className="rounded"
                type="color"
                size="large"
                value={colorHexA}
                onChange={(e) => setColorHexA(e.target.value)}
              ></Input>
            </div>
            <p className="ml-2 mt-2 text-base font-medium">{colorHexA}</p>
          </div>
        </div>

        {/**
         * Font size A
         */}
        <div className="col-span-2">
          <FieldTitle title={t('profileVisual:fontStyle.fontSize')} />
          <Input
            className="rounded"
            size="large"
            type="number"
            value={fontSizeA}
            onChange={(e) => setFontSizeA(parseInt(e.target.value))}
          ></Input>
        </div>

        {/**
         * Font family A
         */}
        <div className="col-span-2">
          <FieldTitle title={t('profileVisual:fontStyle.fontFamily')} />
          <div className="w-full">
            <Select
              className="rounded"
              value={fontFamilyA}
              size="large"
              style={{ width: '100%' }}
              onChange={(value) => setFontFamilyA(value)}
            >
              <Option value={'Arial'}>Arial</Option>
              {/* <Option value={'Helvetica'}>Helvetica</Option> */}
              <Option value={'Courier New'}>Courier New</Option>
              <Option value={'Georgia'}>Georgia</Option>
              <Option value={'Times New Roman'}>Times New Roman</Option>
              <Option value={'Trebuchet MS'}>Trebuchet MS</Option>
              <Option value={'Verdana'}>Verdana</Option>
            </Select>
          </div>
        </div>
      </div>

      {/**
       * Font style B
       */}
      <SectionTitle title={t('profileVisual:fontStyle.typeB')} />

      <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        {/**
         * Font color B
         */}
        <div className="col-span-2">
          <FieldTitle title={t('profileVisual:fontStyle.fontColor')} />
          <div className="flex w-full">
            <div className="w-11">
              <Input type="color" size="large" value={colorHexB} onChange={(e) => setColorHexB(e.target.value)}></Input>
            </div>
            <p className="ml-2 mt-2 text-base font-medium">{colorHexB}</p>
          </div>
        </div>

        {/**
         * Font size B
         */}
        <div className="col-span-2">
          <FieldTitle title={t('profileVisual:fontStyle.fontSize')} />
          <Input
            size="large"
            type="number"
            value={fontSizeB}
            onChange={(e) => setFontSizeB(parseInt(e.target.value))}
          ></Input>
        </div>

        {/**
         * Font family B
         */}
        <div className="col-span-2">
          <FieldTitle title={t('profileVisual:fontStyle.fontFamily')} />
          <div className="w-full">
            <Select
              value={fontFamilyB}
              size="large"
              style={{ width: '100%' }}
              onChange={(value) => setFontFamilyB(value)}
            >
              <Option value={'Arial'}>Arial</Option>
              {/* <Option value={'Helvetica'}>Helvetica</Option> */}
              <Option value={'Courier New'}>Courier New</Option>
              <Option value={'Georgia'}>Georgia</Option>
              <Option value={'Times New Roman'}>Times New Roman</Option>
              <Option value={'Trebuchet MS'}>Trebuchet MS</Option>
              <Option value={'Verdana'}>Verdana</Option>
            </Select>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default VisualFontStyle
