/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { dragObjectPositionState, dragObjectShiftState } from '../recoil/atoms'
import { TPage, TSignatureField } from '../types/appTypes'

type TPageItemProps = {
  page: TPage
}

const PageItem = (props: TPageItemProps) => {
  const [currentPage, setCurrentPage] = useState<TPage>(props.page)

  const shift = useRecoilValue(dragObjectShiftState)
  const setPosition = useSetRecoilState(dragObjectPositionState)

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    // Determine the element (img) the signature field has been drop on
    const target = e.target as HTMLDivElement
    // Determine the parent element (div representing the document page) of the image,
    // so that the signature can be attached as an element
    const parent = target.parentElement

    // Retrieve data provided by dragging
    const field = JSON.parse(e.dataTransfer.getData('text/plain')) as TSignatureField

    // Determin the element (div representing the signature field) which was dropped on the target
    const signatureField = document.getElementById(field.taskId as string)

    if (parent && signatureField) {
      // Specify position (top left corner) of the signature field within the document page
      // The shift values are determined when drag of signature field starts
      const left = e.nativeEvent.offsetX - shift.shiftX
      const top = e.nativeEvent.offsetY - shift.shiftY

      // Attach signature field to page
      parent.appendChild(signatureField)

      // ...and set the position
      signatureField.style.position = 'absolute'
      signatureField.style.top = `${top}px`
      signatureField.style.left = `${left}px`

      setPosition({
        top,
        left,
      })
    }
  }

  useEffect(() => {
    if (props && props.page) setCurrentPage(props.page)
  }, [props])

  return (
    <div
      id={`${currentPage.documentId}_${currentPage.pageNo}`}
      data-page={currentPage.pageNo}
      data-document-id={currentPage.documentId}
      data-pagewidth={currentPage.width}
      data-pageheight={currentPage.height}
      className="relative mt-2 border-2 shadow-sm border-gray-200"
    >
      <img
        style={{ width: currentPage.width, height: currentPage.height }}
        src={currentPage.src}
        alt={currentPage.label}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      />
    </div>
  )
}

export default PageItem
