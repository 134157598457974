/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Button, Input, Switch, message } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { useRecoilValue } from 'recoil'
import { userInfoState } from '../recoil/atoms'
import {
  DeleteAccountMailGatewayUseCase,
  QueryAccountsUseCase,
  UpdateAccountMailGatewayUseCase,
} from '@axelity/actasign-sic'
import useSignIn from '../hooks/useSignIn'
import SectionTitle from '../elements/SectionTitle'

const SettingsEmailGateway = () => {
  const { t } = useTranslation(['settingsEmailGateway', 'settings'])

  const [noAccessToken] = useSignIn()

  const history = useHistory()

  const DEFAULT_PORT = 465
  const DEFAULT_SECURE = true

  const userInfo = useRecoilValue(userInfoState)

  const [accountId, setAccountId] = useState<string>('')

  const [smtpHost, setSmtpHost] = useState<string>('')
  const [port, setPort] = useState<number>(DEFAULT_PORT)
  const [secure, setSecure] = useState<boolean>(DEFAULT_SECURE)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [senderName, setSenderName] = useState<string>('')
  const [senderAddress, setSenderAddress] = useState<string>('')

  const updateHandler = () => {
    new UpdateAccountMailGatewayUseCase({
      accountId,
    })
      .update({
        smtpHost,
        port,
        secure,
        username,
        password,
        senderName,
        senderAddress,
      })
      .then(() => {
        message.success(t('settingsEmailGateway:updateSuccessful'))
        history.push('/home')
      })
      .catch((error) => {
        message.error(t('settingsEmailGateway:updateFailed'))
        console.error(error)
      })
  }

  const deleteHandler = () => {
    new DeleteAccountMailGatewayUseCase({
      accountId,
    })
      .delete()
      .then(() => {
        setSmtpHost('')
        setPort(DEFAULT_PORT)
        setSecure(DEFAULT_SECURE)
        setUsername('')
        setPassword('')
        setSenderName('')
        setSenderAddress('')
        message.success(t('settingsEmailGateway:deleteSuccessful'))
        history.push('/home')
      })
      .catch((error) => {
        message.error(t('settingsEmailGateway:deleteFailed'))
        console.error(error)
      })
  }

  const backHandler = () => {
    history.push('/home')
  }

  useEffect(() => {
    // Lookup account the user is the primary admin (owner)
    if (userInfo.memberships) {
      const membership = userInfo.memberships.find((ms) => {
        return ms.roles.includes('PRIMARY-ADMIN')
      })

      if (membership) {
        new QueryAccountsUseCase()
          .query({
            id: membership.account,
          })
          .then((accounts) => {
            const account = accounts[0]
            setAccountId(account._id as string)

            if (account.mailGateway) {
              setSmtpHost(account.mailGateway.smtpHost || '')
              setPort(account.mailGateway.port || DEFAULT_PORT)
              setSecure(account.mailGateway.secure || DEFAULT_SECURE)
              setUsername(account.mailGateway.username || '')
              setPassword(account.mailGateway.password || '')
              setSenderName(account.mailGateway.senderName || '')
              setSenderAddress(account.mailGateway.senderAddress || '')
            }
          })
      }
    }
  }, [userInfo])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('settings:emailGateway.name')}
        pages={[
          {
            name: t('settings:accountSettings.name'),
          },
          {
            name: t('settings:emailGateway.name'),
          },
        ]}
      />

      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        {/**
         * Title
         */}
        <SectionTitle title={t('settings:emailGateway.name')} />

        <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          {/**
           * SMTP Host
           */}
          <div className="sm:col-span-4">
            <p className="mt-2 font-semibold">{t('settingsEmailGateway:smtpHost')}</p>
            <div className="inline-flex w-full">
              <Input
                type="text"
                size="large"
                placeholder={t('settingsEmailGateway:smtpHost')}
                value={smtpHost}
                onChange={(e) => setSmtpHost(e.target.value)}
              ></Input>
            </div>
          </div>
          {/**
           * Port
           */}
          <div className="sm:col-span-1">
            <p className="mt-2 font-semibold">{t('settingsEmailGateway:port')}</p>
            <div className="inline-flex w-full">
              <Input
                type="number"
                size="large"
                placeholder={t('settingsEmailGateway:port')}
                value={port}
                onChange={(e) => setPort(parseInt(e.target.value))}
              ></Input>
            </div>
          </div>

          {/**
           * TLS secure
           */}
          <div className="sm:col-span-1">
            <p className="mt-2 font-semibold">{t('settingsEmailGateway:secure')}</p>
            <div className="flex flex-auto content-center mt-1">
              <div>
                <Switch checked={secure} onClick={() => setSecure(!secure)} />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          {/**
           * Username
           */}
          <div className="sm:col-span-3">
            <p className="mt-2 font-semibold">{t('settingsEmailGateway:username')}</p>
            <div className="inline-flex w-full">
              <Input
                type="text"
                size="large"
                placeholder={t('settingsEmailGateway:username')}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              ></Input>
            </div>
          </div>

          {/**
           * Password
           */}
          <div className="sm:col-span-3">
            <p className="mt-2 font-semibold">{t('settingsEmailGateway:password')}</p>
            <div className="inline-flex w-full">
              <Input
                type="password"
                size="large"
                placeholder={t('settingsEmailGateway:password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Input>
            </div>
          </div>
        </div>

        <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          {/**
           * Sender name
           */}
          <div className="sm:col-span-3">
            <p className="mt-2 font-semibold">{t('settingsEmailGateway:senderName')}</p>
            <div className="inline-flex w-full">
              <Input
                type="text"
                size="large"
                placeholder={t('settingsEmailGateway:senderName')}
                value={senderName}
                onChange={(e) => setSenderName(e.target.value)}
              ></Input>
            </div>
          </div>

          {/**
           * Sender address
           */}
          <div className="sm:col-span-3">
            <p className="mt-2 font-semibold">{t('settingsEmailGateway:senderAddress')}</p>
            <div className="inline-flex w-full">
              <Input
                type="email"
                size="large"
                placeholder={t('settingsEmailGateway:senderAddress')}
                value={senderAddress}
                onChange={(e) => setSenderAddress(e.target.value)}
              ></Input>
            </div>
          </div>
        </div>

        <div className="flex flex-auto mt-8 justify-between">
          <Button className="rounded font-semibold" size="large" type="default" onClick={backHandler}>
            {t('settingsEmailGateway:back')}
          </Button>
          <div>
            <Button
              className="rounded font-semibold"
              size="large"
              danger={true}
              type="primary"
              onClick={deleteHandler}
            >
              {t('settingsEmailGateway:delete')}
            </Button>
            <Button className="ml-2 rounded font-semibold" size="large" type="primary" onClick={updateHandler}>
              {t('settingsEmailGateway:save')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsEmailGateway
