const AppLogo = () => {
  return (
    <div className="flex justify-left mr-2">
      {/**
       * Logo
       */}
      <span className="text-2xl font-light">acta</span>
      <span className="text-2xl font-bold">SIGN</span>
      <span className="text-md font-light">&copy;</span>
      <span className="text-2xl font-bold pl-2">ONE</span>
    </div>
  )
}

export default AppLogo
