import { useTranslation } from 'react-i18next'
import { DeleteTaskUseCase, ITask, QueryUsersUseCase, TTrustLevel } from '@axelity/actasign-sic'
import { message } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { taskListState } from '../recoil/atoms'
import { useRecoilState } from 'recoil'
import { useState } from 'react'
import { useEffect } from 'react'

type TActivityTaskItemProps = {
  task: ITask
}

const ActivityTaskItem = ({ task }: TActivityTaskItemProps) => {
  const { t } = useTranslation(['requestActivity'])

  const [tasks, setTasks] = useRecoilState(taskListState)
  const [trustLevel, setTrustLevel] = useState<TTrustLevel>('SES')

  const handleRemoveTask = (e: React.MouseEvent<HTMLDivElement>) => {
    new DeleteTaskUseCase({
      taskId: task._id,
    })
      .delete()
      .then(() => {
        setTasks([
          ...tasks.filter((_task) => {
            return _task._id !== task._id
          }),
        ])
        message.success(t('requestActivity:success.deleteTask'))
      })
      .catch((error) => {
        console.error(error)
        message.error(t('requestActivity:fail.deleteTask'))
      })
  }

  useEffect(() => {
    if (task) {
      new QueryUsersUseCase().query({
        id: task.userInfo.id
      }).then((_users) => {
        if (_users && _users.length > 0) {
          if (_users[0].evidence) {
            setTrustLevel(_users[0].evidence.trustLevel)
          }
        }
      })
    }
  }, [task])

  return (
    <div className="w-full flex flex-row flex-nowrap justify-between p-2 my-1 rounded items-center text-base font-semibold border-2 border-gray-100 text-acs-blue-600">
      <div>
      <span className="whitespace-nowrap">{`${task.userInfo.firstName} ${task.userInfo.familyName} (${task.userInfo.emailAddress})`}</span>

      <span className="ml-2 rounded-md px-2 py-1 text-xs font-semibold bg-teal-100 text-teal-700">{t(`requestActivity:evidence.${trustLevel.toLowerCase()}`)}</span>
      </div>
      <CloseCircleOutlined className="ml-2 hover:text-red-500" onClick={handleRemoveTask} />
    </div>
  )
}

export default ActivityTaskItem
