import { IUserInfo, QueryUsersUseCase, RemoveAccountUserUseCase, ResetUserLoginUseCase } from '@axelity/actasign-sic'
import { UserIcon, PencilIcon, TrashIcon, RewindIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { message } from 'antd'
import {
  accountUsersState,
  currentAccountState,
  currentAccountUserState,
  showAccountUserDialogState,
} from '../recoil/atoms'

type UserItemProps = {
  user: IUserInfo
}
const UserItem = (props: UserItemProps) => {
  const { t } = useTranslation(['settings', 'accountUsers'])

  const currentAccount = useRecoilValue(currentAccountState)
  const setCurrentAccountUser = useSetRecoilState(currentAccountUserState)
  const setShowDialog = useSetRecoilState(showAccountUserDialogState)
  const setUsers = useSetRecoilState(accountUsersState)

  let lastLogin = ''
  if (props.user && props.user.lastLogin) {
    lastLogin = Intl.DateTimeFormat(props.user.language, { dateStyle: 'medium', timeStyle: 'short' }).format(
      new Date(`${props.user.lastLogin}`),
    )
  }

  const handleChangeUser = () => {
    setCurrentAccountUser(props.user)
    setShowDialog(true)
  }

  const handleRemoveUser = () => {
    new RemoveAccountUserUseCase({
      accountId: currentAccount._id,
      userId: props.user._id,
    })
      .remove()
      .then(() => {
        new QueryUsersUseCase()
          .query({
            account: currentAccount._id,
          })
          .then((_users: IUserInfo[]) => {
            setUsers(_users)
          })
          .catch((error) => {
            console.error(error)
          })
      })
  }

  const handleResetUserCredentials = () => {
    new ResetUserLoginUseCase({
      userId: props.user._id,
    })
      .reset()
      .then(() => {
        message.success(t('accountUsers:message.resetSuccessful'))
      })
      .catch(() => {
        message.error(t('accountUsers:message.resetFailed'))
      })
  }

  return (
    <div className="container my-2 border-2 shadow-sm rounded-md border-gray-100 hover:bg-gray-50 hover:border-acs-blue-600">
      <div className="flex justify-start p-2">
        <UserIcon className="w-7 h-7 text-acs-blue-600" />
        <div className="ml-5 w-full">
          <div className="flex w-full justify-between items-center">
            <div className="flex">
              <div className="truncate text-md font-semibold">
                {props.user.firstName} {props.user.familyName}
              </div>
              <div
                className={`rounded-md ml-4 px-2 py-1 ${
                  props.user.memberships[0].status === 'ACTIVE'
                    ? 'bg-green-100 text-green-700'
                    : props.user.memberships[0].status === 'INVITED'
                    ? 'bg-yellow-100 text-yellow-700'
                    : 'bg-gray-100 text-gray-700'
                } text-xs font-semibold`}
              >
                {t(`accountUsers:status.${props.user.memberships[0].status.toLowerCase()}`)}
              </div>
            </div>

            {/*
             */}
            <div className="flex justify-end">
              {props.user.memberships
                ? props.user.memberships[0].roles.map((role) => {
                    return (
                      <div
                        key={role}
                        className="rounded-md ml-2 px-2 py-1 text-xs font-semibold bg-acs-blue-100 text-acs-blue-700"
                      >
                        {t(`accountUsers:role.${role.toLowerCase()}`)}
                      </div>
                    )
                  })
                : null}
            </div>
          </div>

          <div className="flex mt-1 w-full justify-between items-center">
            <div className="text-xs pt-2 text-gray-500">
              {props.user.email.emailAddress} | {t('accountUsers:list.lastLogin')}{' '}
              {lastLogin || t('accountUsers:list.noLogin')}
            </div>
            <div className="rounded-md px-2 py-1 text-xs font-semibold bg-teal-100 text-teal-700">
              {t(`accountUsers:plan.${props.user.memberships[0].plan.toLowerCase()}`)}
            </div>
          </div>
        </div>
      </div>

      {/**
       * Actions
       */}
      <div className="flex justify-end w-full pr-2 bg-acs-blue-50 rounded-b-md">
        <div
          className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
          onClick={handleChangeUser}
        >
          <PencilIcon className="w-4 h-4 mr-1" />
          {t('accountUsers:list.edit')}
        </div>

        {!props.user.memberships[0].roles.includes('PRIMARY-ADMIN') ? (
          <div
            className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
            onClick={handleResetUserCredentials}
          >
            <RewindIcon className="w-4 h-4 mr-1" />
            {t('accountUsers:list.reset')}
          </div>
        ) : null}

        {!props.user.memberships[0].roles.includes('PRIMARY-ADMIN') ? (
          <div
            className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
            onClick={handleRemoveUser}
          >
            <TrashIcon className="w-4 h-4 mr-1" />
            {t('accountUsers:list.remove')}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default UserItem
