import { ChevronRightIcon, HomeIcon } from '@heroicons/react/outline'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { processingInviteTaskState, viewingDocumentState } from '../recoil/atoms'
import { useRecoilValue } from 'recoil'

type TButtonType = 'primary' | 'ghost' | 'default'

type HeaderButtons = {
  label: string
  href: string
  type: TButtonType
  onClick?: () => void
}[]

type HeaderPages = {
  name: string
  href?: string
}[]

type PageHeaderProps = {
  title: string
  pages?: HeaderPages
  buttons?: HeaderButtons
}

const PageHeader = (props: PageHeaderProps) => {
  const processingInviteTask = useRecoilValue(processingInviteTaskState)
  const viewingDocument = useRecoilValue(viewingDocumentState)

  let pages: HeaderPages = []
  let buttons: HeaderButtons = []
  if (props.pages) {
    pages = [...props.pages]
  }

  if (props.buttons) {
    buttons = [...props.buttons]
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-2 sm:px-6">
      <div className="mt-2 pb-4 flex flex-col border-b-2 border-gray-100">
        {!processingInviteTask ? (
          <div className="mb-2">
            <ul className="flex items-center space-x-4">
              <li key={'home'}>
                <Link to="/home" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Home</span>
                </Link>
              </li>
              {pages.map((page) => (
                <li key={page.name}>
                  <div className="flex">
                    <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    {page.href ? (
                      <Link to={page.href}>
                        <p className="ml-4 text-gray-400 hover:text-gray-500">{page.name}</p>
                      </Link>
                    ) : (
                      <p className="ml-4 text-sm font-normal text-gray-400">{page.name}</p>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        {/**
         * Title
         */}
        <div className="flex flex-row items-center justify-between">
          <h2 className="text-2xl font-semibold leading-7 text-gray-900 whitespace-nowrap overflow-hidden overflow-ellipsis">
            {props.title}
          </h2>

          {!processingInviteTask || viewingDocument ? (
            <div>
              {buttons.map((_button) => (
                <div key={_button.label}>
                  {_button.href ? (
                    <Link to={_button.href || '#'}>
                      <Button
                        className="ml-2 rounded font-semibold"
                        size="large"
                        type={_button.type}
                        onClick={_button.onClick}
                      >
                        {_button.label}
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      className="ml-2 rounded font-semibold"
                      size="large"
                      type={_button.type}
                      onClick={_button.onClick}
                    >
                      {_button.label}
                    </Button>
                  )}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default PageHeader
