/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Button, Input, message } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { useRecoilState } from 'recoil'
import { userInfoState } from '../recoil/atoms'
import {
  ConfirmUserMobileUseCase,
  DeleteUserMobileUseCase,
  GetUserUseCase,
  VerifyUserMobileUseCase,
} from '@axelity/actasign-sic'
import * as yup from 'yup'
import FieldTitle from '../elements/FieldTitle'
import SectionTitle from '../elements/SectionTitle'
import useSignIn from '../hooks/useSignIn'

const ProfileMobile = () => {
  const { t } = useTranslation(['profileMobile', 'profile'])

  const [noAccessToken] = useSignIn()

  const history = useHistory()

  const confirmationCodeRef = useRef(null)

  const [userInfo, setUserInfo] = useRecoilState(userInfoState)

  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [confirmationCode, setConfirmationCode] = useState<string>('')
  const [isSent, setIsSent] = useState<boolean>(false)
  const [isIncomplete, setIsIncomplete] = useState<boolean>(false)

  type TFormErrors = {
    phoneNumber: string
  }

  const [formErrors, setFormErrors] = useState<TFormErrors>({
    phoneNumber: '',
  })

  const validateForm = (): boolean => {
    let valid = true
    let errors: TFormErrors = {
      phoneNumber: '',
    }

    let _phoneNumber = `${phoneNumber}`
    if (_phoneNumber.startsWith('00')) {
      _phoneNumber = `+${_phoneNumber.substring(2)}`
    }
    if (_phoneNumber.startsWith('+00')) {
      _phoneNumber = `+${_phoneNumber.substring(3)}`
    }
    if (_phoneNumber.startsWith('+0')) {
      _phoneNumber = `+41${_phoneNumber.substring(2)}`
    }
    if (!_phoneNumber.startsWith('+')) {
      _phoneNumber = `+${_phoneNumber}`
    }

    setPhoneNumber(_phoneNumber.replace(/[^\d+]/g, ''))

    if (!yup.string().trim().min(11).required().isValidSync(phoneNumber)) {
      errors = { ...errors, phoneNumber: t('profileMobile:validation.phoneNumberIncomplete') }
      valid = false
    }

    setFormErrors(errors)

    return valid
  }

  const handleChangeMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value)
  }

  const backHandler = () => {
    history.push('/home')
  }

  const confirmationHandler = async (e: any) => {
    e.preventDefault()
    if (!phoneNumber.startsWith('+')) setPhoneNumber(`+${phoneNumber}`)

    new ConfirmUserMobileUseCase({
      userId: userInfo._id as string,
    })
      .confirm({
        challengeResponse: confirmationCode,
        phoneNumber,
      })
      .then(() => {
        return new GetUserUseCase().getUser()
      })
      .then((_userInfo) => {
        setIsSent(false)
        setUserInfo(_userInfo)
        setConfirmationCode('')
      })
      .then(() => {
        message.success(t('verificationSuccessful'))
        history.push('/home')
      })
      .catch((error) => {
        message.error(t('verificationFailed'))
        console.error(error)
      })
  }

  const verifyHandler = async () => {
    if (!validateForm()) return

    new VerifyUserMobileUseCase({
      userId: userInfo._id as string,
    })
      .verify({
        mobile: phoneNumber,
        language: userInfo.language,
      })
      .then(() => {
        setIsSent(true)
        message.info(t('messageSent', { phoneNumber: phoneNumber }))
        confirmationCodeRef.current.focus()
      })
  }

  useEffect(() => {    
    if (validateForm()) {
      setIsIncomplete(false)
    } else {
      setIsIncomplete(true)
    }
  }, [phoneNumber])

  const deleteHandler = () => {
    new DeleteUserMobileUseCase({
      userId: userInfo._id as string,
    })
      .delete()
      .then(() => {
        setPhoneNumber('')
        setConfirmationCode('')
      })
      .then(() => {
        return new GetUserUseCase().getUser()
      })
      .then((userInfo) => {
        setIsSent(false)
        setUserInfo(userInfo)
        setConfirmationCode('')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    if (userInfo) {
      setPhoneNumber(userInfo.mobile ? (userInfo.mobile.phoneNumber as string) : '')
    }
  }, [userInfo])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('profile:mobileNumber.name')}
        pages={[
          {
            name: t('profile:userProfile.name'),
          },
          {
            name: t('profile:mobileNumber.name'),
          },
        ]}
      />

      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        <SectionTitle title={t('profileMobile:title')} />
        <FieldTitle title={t('profileMobile:placeholder')} />
        <div className="inline-flex w-full">
          <Input
            className="rounded"
            type="tel"
            size="large"
            placeholder={t('profileMobile:placeholder')}
            value={phoneNumber}
            onChange={handleChangeMobile}
            disabled={isSent}
            onPressEnter={verifyHandler}
          ></Input>

          {!isSent ? (
            <Button
              disabled={isIncomplete}
              className="rounded ml-2 font-semibold"
              size="large"
              type="primary"
              onClick={() => verifyHandler()}
            >
              {t('verify')}
            </Button>
          ) : null}
        </div>
        <div className="my-1 font-normal text-md text-red-500">{formErrors.phoneNumber}</div>

        <div>
          <p className="mt-2">{t('profileMobile:instructions')}</p>
        </div>

        <div className="mt-10">
          <FieldTitle title={t('profileMobile:confirmationCode')} />
          <div className="inline-flex mt-1">
            <Input
              ref={confirmationCodeRef}
              className="rounded"
              type="number"
              size="large"
              placeholder={t('profileMobile:confirmationCode')}
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              disabled={!isSent}
              onPressEnter={confirmationHandler}
            ></Input>
            {isSent ? (
              <div>
                <div>
                  <Button
                    className="ml-2 rounded font-semibold"
                    size="large"
                    type="primary"
                    onClick={confirmationHandler}
                  >
                    {t('confirm')}
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {isSent ? (
          <div className="flex mt-2">
            <p>{t('profileMobile:codeNotReceived')}</p>
            <p className="ml-2 underline text-antd-blue cursor-pointer" onClick={() => verifyHandler()}>
              {t('sendAgain')}
            </p>
          </div>
        ) : null}

        <div className="flex flex-auto mt-8 justify-between">
          {!isSent && phoneNumber ? (
            <Button className="rounded font-semibold" size="large" danger={true} type="primary" onClick={deleteHandler}>
              {t('profileMobile:remove')}
            </Button>
          ) : null}

          {!isSent ? (
            <Button className="ml-2 rounded font-semibold" size="large" type="ghost" onClick={backHandler}>
              {t('profileMobile:back')}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ProfileMobile
