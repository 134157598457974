/* eslint-disable import/first */
/* eslint-disable react-hooks/exhaustive-deps */
import { QueryProcessesUseCase } from '@axelity/actasign-sic'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Spin, Tabs } from 'antd'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import RequestList from '../components/RequestList'
import NoData from '../components/NoData'
import useSignIn from '../hooks/useSignIn'
import {
  accessTokenState,
  currentPlanState,
  currentProcessStepState,
  documentState,
  requestListState,
  requestState,
  userInfoState,
} from '../recoil/atoms'

const { TabPane } = Tabs

const Requests = () => {
  const { t } = useTranslation(['common', 'requests'])

  const userInfo = useRecoilValue(userInfoState)
  const currentPlan = useRecoilValue(currentPlanState)
  const resetDocument = useResetRecoilState(documentState)

  const [requests, setRequests] = useRecoilState(requestListState)
  const resetRequest = useResetRecoilState(requestState)

  const resetCurrentProcessStep = useResetRecoilState(currentProcessStepState)
  const resetAccessToken = useResetRecoilState(accessTokenState)
  const resetUserInfo = useResetRecoilState(userInfoState)

  const [isLoading, setLoading] = useState<boolean>(false)

  const history = useHistory()

  const [noAccessToken] = useSignIn()

  const handleNewRequest = () => {
    resetRequest()
    resetCurrentProcessStep()
    history.push('/request')
  }

  useEffect(() => {
    resetDocument()

    // Query user tasks
    if (userInfo && currentPlan) {
      if (currentPlan === 'GUEST' || currentPlan === 'PARTNER') {
        history.replace('/home')
      }

      setLoading(true)
      new QueryProcessesUseCase()
        .query({
          owner: userInfo._id,
        })
        .then((userRequests) => {
          if (userRequests.length) {
            setRequests(userRequests)
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [userInfo, currentPlan])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  useEffect(() => {
    if (sessionStorage.getItem('pivt') === 'true') {
      resetUserInfo()
      resetAccessToken()
      sessionStorage.removeItem('pivt')
      history.push('/')
    }
  }, [])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('common:requestList')}
        pages={[
          {
            name: t('common:requestList'),
          },
        ]}
        buttons={[
          {
            label: t('common:newRequest'),
            href: '#',
            type: 'primary',
            onClick: handleNewRequest,
          },
        ]}
      />

      {/**
       * Requests
       */}
      {requests && requests.length > 0 ? (
        <div className="container max-w-4xl mx-auto text-base bg-white p-4 mt-4 rounded-sm">
          <Spin spinning={isLoading} size="large">
            <Tabs defaultActiveKey="pending">
              <TabPane tab={<div className="text-base">{t('requests:tab.pending')}</div>} key="pending">
                <RequestList requests={requests} status={['PENDING']} />
              </TabPane>
              <TabPane tab={<div className="text-base">{t('requests:tab.active')}</div>} key="active">
                <RequestList requests={requests} status={['ACTIVE']} />
              </TabPane>
              <TabPane tab={<div className="text-base">{t('requests:tab.completed')}</div>} key="completed">
                <RequestList requests={requests} status={['COMPLETED']} />
              </TabPane>
              <TabPane tab={<div className="text-base">{t('requests:tab.inactive')}</div>} key="inactive">
                <RequestList requests={requests} status={['CANCELLED', 'CLOSED', 'SUSPENDED']} />
              </TabPane>
            </Tabs>
          </Spin>
        </div>
      ) : null}
      {(!requests || requests.length === 0) && !isLoading ? <NoData note={t('requests:noRequests')} /> : null}
    </div>
  )
}

export default Requests
