import { IAccessToken, TAccessTokenStatus } from '@axelity/actasign-sic'
import ApiKeyItem from './ApiKeyItem'

type ApiKeyListProps = {
  apiKeys: IAccessToken[]
  status?: TAccessTokenStatus[] | undefined
}

const ApiKeyList = ({ apiKeys, status }: ApiKeyListProps) => {
  let filteredApiKeys: IAccessToken[] = []
  let tempApiKeys: IAccessToken[] = []

  if (status) {
    tempApiKeys = apiKeys.filter((_apiKey) => {
      return status.includes(_apiKey.status)
    })
  } else {
    tempApiKeys = [...apiKeys]
  }

  tempApiKeys = tempApiKeys.filter((_apiKey) => {
    return _apiKey.status !== 'CLOSED'
  })

  // Sort decending
  tempApiKeys = tempApiKeys.sort((a, b) => {
    const aDate = new Date(a.created).getTime()
    const bDate = new Date(b.created).getTime()
    return bDate - aDate
  })

  filteredApiKeys = [...tempApiKeys]

  return (
    <div>
      {filteredApiKeys.map((apiKey) => {
        return <ApiKeyItem key={apiKey._id} apiKey={apiKey} />
      })}
    </div>
  )
}

export default ApiKeyList
