import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, message } from 'antd'
import { LeftCircleOutlined, SendOutlined } from '@ant-design/icons'
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { currentProcessStepState, requestState } from '../../recoil/atoms'
import Timeline from '../Timeline'
import {
  QueryDocumentsUseCase,
  QuerySignaturesUseCase,
  QueryTasksUseCase,
  StartProcessUseCase,
} from '@axelity/actasign-sic'
import { useState } from 'react'

const RequestStep4 = () => {
  const { t } = useTranslation(['common', 'request', 'settings'])

  const history = useHistory()

  const setCurrentProcessStep = useSetRecoilState(currentProcessStepState)
  const request = useRecoilValue(requestState)
  const resetRequest = useResetRecoilState(requestState)

  const [inProgress, setInProgress] = useState<boolean>(false)

  const previousHandler = () => {
    setCurrentProcessStep(2)
  }

  const startRequest = async () => {
    setInProgress(true)

    try {
      const _documents = await new QueryDocumentsUseCase().query({
        process: request._id,
      })

      if (!_documents || _documents.length === 0) {
        message.error(t('request:error.incomplete'))
        throw new Error(t('request:error.incomplete'))
      }

      const _tasks = await new QueryTasksUseCase().query({
        process: request._id,
      })

      const _signTasks = _tasks.filter((_task) => {
        return _task.activityType === 'SIGN'
      })

      if (_signTasks && _signTasks.length > 0) {
        const _signatures = await new QuerySignaturesUseCase().query({
          process: request._id,
        })

        if (!_signatures || _signatures.length < _signTasks.length) {
          message.error(t('request:error.signaturesIncomplete'))
          throw new Error(t('request:error.signaturesIncomplete'))
        }
      }

      await new StartProcessUseCase({
        processId: request._id,
      }).start()

      resetRequest()

      setInProgress(false)
      history.replace('/requests')
    } catch (error) {
      setInProgress(false)
      console.error(error)
    }
  }

  return (
    <div>
      <div className="flex flex-auto my-8 justify-between">
        <Button className="rounded font-semibold" size="large" type="primary" onClick={previousHandler}>
          <div className="inline-flex items-center whitespace-nowrap">
            <LeftCircleOutlined />
            <p className="ml-2">{t('request:step.documents.title')}</p>
          </div>
        </Button>

        <Button
          loading={inProgress}
          className="rounded font-semibold whitespace-nowrap"
          size="large"
          type="primary"
          onClick={startRequest}
        >
          <div className="inline-flex items-center whitespace-nowrap">
            <p className="mr-2">{t('request:step.send.title')}</p>
            <SendOutlined />
          </div>
        </Button>
      </div>

      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        <Timeline />
      </div>
    </div>
  )
}

export default RequestStep4
