import { Transition } from '@headlessui/react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useEffect, useState, useRef } from 'react'
import { Button, Input, DatePicker, message } from 'antd'
import locale_de from 'antd/es/date-picker/locale/de_DE'
import locale_en from 'antd/es/date-picker/locale/en_GB'
import { requestState, userInfoState } from '../recoil/atoms'
import * as yup from 'yup'
import { useRecoilState, useRecoilValue } from 'recoil'
import { CreateProcessUseCase, IProcess, QueryProcessesUseCase, UpdateProcessUseCase } from '@axelity/actasign-sic'
import SectionTitle from '../elements/SectionTitle'
import FieldTitle from '../elements/FieldTitle'

type TRequestDetailsProps = {
  onApply: (request: IProcess) => void
}

const RequestDetails = ({ onApply }: TRequestDetailsProps) => {
  const { t, i18n } = useTranslation(['requestDetails'])

  const titleRef = useRef(null)

  const userInfo = useRecoilValue(userInfoState)
  const [request, setRequest] = useRecoilState(requestState)

  const [title, setTitle] = useState('')
  // const [mailingType, setMailingType] = useState<TMailingType>('ALL')
  const [dueOn, setDueOn] = useState<Date>(moment().add(2, 'weeks').toDate())
  const [retainUntil, setRetainUntil] = useState<Date>(moment().add(3, 'months').toDate())
  const [inProgress, setInProgress] = useState<boolean>(false)

  let updatedDueOn: Date = moment().add(2, 'weeks').toDate()

  type TFormErrors = {
    title: string
    dueOn: string
    retainUntil: string
  }

  const [errors, setErrors] = useState<TFormErrors>({
    title: '',
    dueOn: '',
    retainUntil: '',
  })

  const validateForm = (): boolean => {
    let valid = true
    let errors: TFormErrors = {
      title: '',
      dueOn: '',
      retainUntil: '',
    }

    if (!yup.string().min(2).required().isValidSync(title)) {
      errors = { ...errors, title: t('requestDetails:error.title') }
      valid = false
    }

    let _dueOnStr = moment(dueOn).format('YYYY-MM-DD')
    _dueOnStr = _dueOnStr.concat('T23:59:59.999')
    if (new Date(_dueOnStr).getTime() <= new Date().getTime()) {
      errors = { ...errors, dueOn: t('requestDetails:error.dueOn') }
      valid = false
    } else {
      updatedDueOn = new Date(_dueOnStr)
      setDueOn(new Date(_dueOnStr))
    }

    if (typeof retainUntil === 'string') {
      if (new Date(retainUntil).getTime() <= new Date().getTime()) {
        errors = { ...errors, retainUntil: t('requestDetails:error.retainUntil') }
        valid = false
      } else {
        setRetainUntil(new Date(retainUntil))
      }
    } else {
      if (retainUntil.getTime() <= new Date().getTime()) {
        errors = { ...errors, retainUntil: t('requestDetails:error.retainUntil') }
        valid = false
      }
    }

    setErrors(errors)

    return valid
  }

  const handleApply = () => {
    if (!validateForm()) return

    setInProgress(true)
    if (request) {
      new UpdateProcessUseCase({
        processId: request._id,
      })
        .update({
          settings: request.settings,
          title,
          dueOn: updatedDueOn,
          retainUntil,
        })
        .then(() => {
          return new QueryProcessesUseCase().query({
            id: request._id,
          })
        })
        .then((requests) => {
          // Update data
          setRequest(requests[0])
        })
        .then(() => {
          message.success(t('requestDetails:success.updateRequest'))
          onApply(request)
        })
        .catch((error) => {
          message.error(t('requestDetails:fail.updateRequest'))
          console.error(error)
        })
        .finally(() => {
          setInProgress(false)
        })
    } else {
      new CreateProcessUseCase()
        .create({
          account: userInfo.currentAccount,
          workspace: userInfo.currentWorkspace,
          owner: userInfo._id,
          title,
          dueOn: updatedDueOn,
          retainUntil,
          settings: {
            notifyAllProcessRecalled: true,
            notifyAssigneeTaskStarted: true,
            notifyAssigneesProcessCompleted: true,
            notifyAssignerProcessCompleted: true,
            notifyAssignerProcessStarted: true,
            notifyAssignerTaskCompleted: false,
            notifyAssignerTaskDeclined: true,
            notifyAssignerTaskReassigned: false,
            notifyAssignerTaskStarted: false,
            ownerIsAssignee: true, //mailingType === 'ME' || mailingType === 'ALL' ? true : false,
            permitAssignActivityOrder: true,
            useIndividualMessages: true,
          },
        })
        .then((_request) => {
          setRequest(_request)
          message.success(t('requestDetails:success.createRequest'))
          onApply(_request)
        })
        .catch((error) => {
          message.error(t('requestDetails:fail.createRequest'))
          console.error(error)
        })
        .finally(() => {
          setInProgress(false)
        })
    }
  }

  useEffect(() => {
    if (request) {
      setTitle(request.title)
      setDueOn(request.dueOn)
      setRetainUntil(request.retainUntil)
    }
  }, [request])

  useEffect(() => {
    titleRef.current.focus()
  }, [])

  return (
    <div className="w-full">
      <Transition
        show={true}
        enter="transition ease duration-700 transform"
        enterFrom="opacity-0 -translate-y-0"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease duration-300 transform"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-0"
      >
        {/**
         * Header
         */}
        <SectionTitle title={t('requestDetails:title')} />

        {/**
         * Process name / title
         */}
        <FieldTitle title={t('requestDetails:subject')} />
        <div className="w-full">
          <Input
            disabled={request && request.status !== 'ACTIVE' && request.status !== 'PENDING'}
            type="text"
            size="large"
            placeholder=""
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ borderRadius: '5px' }}
            ref={titleRef}
          ></Input>
          <div className="my-1 font-semibold text-md text-red-500">{errors.title}</div>
        </div>

        {/**
         * Due and retention date
         */}
        <div className="mb-1 mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          {/**
           * Due date
           */}
          <div className="sm:col-span-3">
            <FieldTitle title={t('requestDetails:dueOn')} />
            <div className="inline-flex w-full">
              <DatePicker
                disabled={request && request.status !== 'ACTIVE' && request.status !== 'PENDING'}
                size="large"
                locale={i18n.language.includes('de') ? locale_de : locale_en}
                format={i18n.language.includes('de') ? 'DD.MM.YYYY' : 'MM/DD/YYYY'}
                value={moment(dueOn)}
                onChange={(date, dateString) => setDueOn(date === null ? new Date() : date.toDate())}
                style={{ borderRadius: '5px' }}
              />
            </div>
            <div className="my-1 font-semibold text-md text-red-500">{errors.dueOn}</div>
          </div>

          {/**
           * retain until date
           */}
          <div className="sm:col-span-3">
            <FieldTitle title={t('requestDetails:retainUntil')} />
            <div className="inline-flex w-full">
              <DatePicker
                disabled={request && request.status !== 'ACTIVE' && request.status !== 'PENDING'}
                size="large"
                locale={i18n.language.includes('de') ? locale_de : locale_en}
                format={i18n.language.includes('de') ? 'DD.MM.YYYY' : 'MM/DD/YYYY'}
                value={moment(retainUntil)}
                onChange={(date, dateString) => setRetainUntil(date === null ? new Date() : date.toDate())}
                style={{ borderRadius: '5px' }}
              />
            </div>
            <div className="my-1 font-semibold text-md text-red-500">{errors.retainUntil}</div>
          </div>
        </div>

        <div className="flex flex-auto mt-8 justify-end">
          {!request || (request && request.status === 'PENDING') ? (
            <Button
              className="rounded font-semibold"
              size="large"
              type="primary"
              onClick={handleApply}
              loading={inProgress}
            >
              {t('requestDetails:action.apply')}
            </Button>
          ) : null}
        </div>
      </Transition>
    </div>
  )
}

export default RequestDetails
