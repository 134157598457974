/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Button, Input, message } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useRecoilState } from 'recoil'
import { userInfoState } from '../recoil/atoms'
import { ConfirmUserMobileUseCase, GetUserUseCase, VerifyUserMobileUseCase } from '@axelity/actasign-sic'
import * as yup from 'yup'
import FieldTitle from '../elements/FieldTitle'
import SectionTitle from '../elements/SectionTitle'
import { Transition } from '@headlessui/react'

type MobileConfirmationProps = {
  show: boolean
  onConfirmed: () => void
}

const MobileConfirmation = (props: MobileConfirmationProps) => {
  const { t } = useTranslation(['mobileConfirmation'])

  const phoneNumberRef = useRef(null)
  const confirmationCodeRef = useRef(null)

  const [userInfo, setUserInfo] = useRecoilState(userInfoState)

  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [confirmationCode, setConfirmationCode] = useState<string>('')
  const [isSent, setIsSent] = useState<boolean>(false)
  const [isIncomplete, setIsIncomplete] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(props.show)

  type TFormErrors = {
    phoneNumber: string
  }

  const [formErrors, setFormErrors] = useState<TFormErrors>({
    phoneNumber: '',
  })

  const validateForm = (): boolean => {
    let valid = true
    let errors: TFormErrors = {
      phoneNumber: '',
    }

    let _phoneNumber = `${phoneNumber}`
    if (_phoneNumber.startsWith('00')) {
      _phoneNumber = `+${_phoneNumber.substring(2)}`
    }
    if (_phoneNumber.startsWith('+00')) {
      _phoneNumber = `+${_phoneNumber.substring(3)}`
    }
    if (_phoneNumber.startsWith('+0')) {
      _phoneNumber = `+41${_phoneNumber.substring(2)}`
    }
    if (!_phoneNumber.startsWith('+')) {
      _phoneNumber = `+${_phoneNumber}`
    }

    setPhoneNumber(_phoneNumber.replace(/[^\d+]/g, ''))

    if (!yup.string().trim().min(11).required().isValidSync(phoneNumber)) {
      errors = { ...errors, phoneNumber: t('mobileConfirmation:validation.phoneNumberIncomplete') }
      valid = false
    }

    setFormErrors(errors)

    return valid
  }

  const handleChangeMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value)
  }

  const confirmationHandler = async (e: any) => {
    e.preventDefault()
    if (!phoneNumber.startsWith('+')) setPhoneNumber(`+${phoneNumber}`)

    new ConfirmUserMobileUseCase({
      userId: userInfo._id as string,
    })
      .confirm({
        challengeResponse: confirmationCode,
        phoneNumber,
      })
      .then(() => {
        return new GetUserUseCase().getUser()
      })
      .then((_userInfo) => {
        setIsSent(false)
        setUserInfo(_userInfo)
        setConfirmationCode('')
        setShowDialog(false)
        props.onConfirmed()
      })
      .then(() => {
        message.success(t('mobileConfirmation:verificationSuccessful'))
      })
      .catch((error) => {
        message.error(t('mobileConfirmation:verificationFailed'))
      })
  }

  const verifyHandler = async () => {
    if (!validateForm()) return

    new VerifyUserMobileUseCase({
      userId: userInfo._id as string,
    })
      .verify({
        mobile: phoneNumber,
        language: userInfo.language,
      })
      .then(() => {
        setIsSent(true)
        message.info(t('mobileConfirmation:messageSent', { phoneNumber: phoneNumber }))
        confirmationCodeRef.current.focus()
      })
  }

  useEffect(() => {
    if (validateForm()) {
      setIsIncomplete(false)
    } else {
      setIsIncomplete(true)
    }
  }, [phoneNumber])

  useEffect(() => {
    if (userInfo) {
      setPhoneNumber(userInfo.mobile ? (userInfo.mobile.phoneNumber as string) : '')
    }
  }, [userInfo])

  useEffect(() => {
    setShowDialog(props.show)
  }, [props])

  return (
    <Transition
      show={showDialog}
      enter="transition ease duration-700 transform"
      enterFrom="opacity-0 -translate-y-0"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease duration-300 transform"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-0"
      afterEnter={() => phoneNumberRef.current.focus()}
    >
      <div className="w-full my-4 px-4 pb-4 rounded-md border-2">
        {/**
         * Title
         */}
        <SectionTitle title={t('mobileConfirmation:title')} />

        <div className="mt-2 grid gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <FieldTitle title={t('mobileConfirmation:placeholder')} />
            <div className="inline-flex w-full">
              <Input
                ref={phoneNumberRef}
                className="rounded"
                type="tel"
                size="large"
                placeholder=""
                value={phoneNumber}
                onChange={handleChangeMobile}
                disabled={isSent}
                onPressEnter={verifyHandler}
              ></Input>

              {!isSent ? (
                <Button
                  disabled={isIncomplete}
                  className="rounded ml-2 font-semibold"
                  size="large"
                  type="primary"
                  onClick={() => verifyHandler()}
                >
                  {t('verify')}
                </Button>
              ) : null}
            </div>
            <div className="my-1 font-normal text-md text-red-500">{formErrors.phoneNumber}</div>

            {isSent ? (
              <div className="flex mt-2">
                <p>{t('mobileConfirmation:codeNotReceived')}</p>
                <p className="ml-2 underline text-antd-blue cursor-pointer" onClick={() => verifyHandler()}>
                  {t('sendAgain')}
                </p>
              </div>
            ) : null}
          </div>

          <div className="sm:col-span-3">
            <FieldTitle title={t('mobileConfirmation:confirmationCode')} />
            <div className="inline-flex">
              <Input
                ref={confirmationCodeRef}
                className="rounded"
                type="number"
                size="large"
                placeholder={t('mobileConfirmation:confirmationCode')}
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                disabled={!isSent}
                onPressEnter={confirmationHandler}
              ></Input>

              {isSent ? (
                <div>
                  <div>
                    <Button
                      className="ml-2 rounded font-semibold"
                      size="large"
                      type="primary"
                      onClick={confirmationHandler}
                    >
                      {t('confirm')}
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default MobileConfirmation
