import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ITask } from '@axelity/actasign-sic'
import { ClipboardCheckIcon, CheckCircleIcon, EyeIcon } from '@heroicons/react/outline'
import { useSetRecoilState } from 'recoil'
import { taskState } from '../recoil/atoms'

type TaskItemProps = {
  task: ITask
}
const TaskItem = ({ task }: TaskItemProps) => {
  const { t } = useTranslation(['taskItem'])

  const setTask = useSetRecoilState(taskState)

  const history = useHistory()

  let message = ''
  if (task.messages && task.messages.initial) {
    message = task.messages.initial
  }

  let created = ''
  if (task && task.created) {
    created = Intl.DateTimeFormat(task.userInfo.language, { dateStyle: 'medium', timeStyle: 'short' }).format(
      new Date(`${task.created}`),
    )
  }

  const handleTask = () => {
    setTask(task)
    history.push('/task')
  }

  return (
    <div className="container my-2 border-2 shadow-sm rounded-md border-gray-200 hover:bg-gray-50 hover:border-acs-blue-600 hover:cursor-pointer" onClick={handleTask}>
      <div className="flex justify-start p-2">
        <ClipboardCheckIcon className="w-7 h-7 text-acs-blue-600" />
        <div className="ml-5 w-full">
          <div className="flex justify-between items-center">
            <div className="flex flex-row justify-start">
              <div className="truncate text-md font-semibold">{task.title}</div>
              <div className="rounded-md ml-2 px-2 py-1 text-xs font-semibold bg-green-100 text-green-700">
                {t(`taskItem:activityType.${task.activityType.toLowerCase()}`)}
              </div>
            </div>
            <div className="flex justify-end">
              <div
                className={`rounded-md ml-2 px-2 py-1 ${
                  task.status === 'ACTIVE' ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'
                }  text-xs font-semibold`}
              >
                {t(`taskItem:status.${task.status.toLowerCase()}`)}
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-1">
            <div className="text-xs font-semibold">{`${task.assignerInfo.firstName} ${task.assignerInfo.familyName} (${task.assignerInfo.emailAddress})`}</div>
            <div className="text-xs font-normal text-gray-500">{created}</div>
          </div>
          <div className="truncate text-xs text-gray-700 mt-2">{message}</div>
        </div>
      </div>

      {/**
       * Actions
       */}
      <div className="flex justify-end rounded-b-md w-full pr-2 bg-acs-blue-50">
        {task.status === 'ACTIVE' ? (
          <div
            className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
            onClick={handleTask}
          >
            <CheckCircleIcon className="w-4 h-4 mr-1" />
            {task.activityType === 'SIGN' ? t('taskItem:action.sign') : t('taskItem:action.complete')}
          </div>
        ) : null}

        {task.status !== 'PENDING' && task.status !== 'ACTIVE' ? (
          <div
            className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
            onClick={handleTask}
          >
            <EyeIcon className="w-4 h-4 mr-1" />
            {t('taskItem:action.view')}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default TaskItem
