import { useEffect, useState } from 'react'
import { Input, Modal, Radio, Button } from 'antd'
import { useRecoilState } from 'recoil'
import { showVisualBorderDialogState, visualState } from '../../recoil/atoms'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'

type TBorder = 'color' | 'invisible'

const VisualBackground = () => {
  const { t } = useTranslation(['profileVisual'])

  const [visual, setVisual] = useRecoilState(visualState)

  const [showVisualBorderDialog, setShowVisualBorderDialog] = useRecoilState(showVisualBorderDialogState)

  const [borderType, setBorderType] = useState<TBorder>('color')
  const [isVisible, setVisible] = useState<boolean>(true)
  const [colorHex, setColorHex] = useState<string | undefined>('f3f4f6')
  const [width, setWidth] = useState<number | undefined>(1)

  const handleBorderOk = () => {
    const _visual = cloneDeep(visual)
    _visual.border = {
      isVisible,
      colorHex,
      width,
    }
    setVisual(_visual)

    setShowVisualBorderDialog(false)
  }

  const handleBorderCancel = () => {
    setShowVisualBorderDialog(false)
  }

  const handleChangeBorderType = (e: any) => {
    setBorderType(e.target.value)

    if ((e.target.value as TBorder) === 'color') {
      setVisible(true)
      setColorHex(visual.border && visual.border.colorHex ? visual.border.colorHex : '#f3f4f6')
      setWidth(visual.border && visual.border.width ? visual.border.width : 1)
    } else {
      setVisible(false)
      setColorHex(undefined)
      setWidth(undefined)
    }
  }

  useEffect(() => {
    if (visual && visual.border) {
      if (visual.border.isVisible) {
        setBorderType('color')
      } else {
        setBorderType('invisible')
      }
      setVisible(visual.border.isVisible)
      setColorHex(visual.border.colorHex)
      setWidth(visual.border.width)
    }
  }, [visual, showVisualBorderDialog])

  return (
    <Modal
      title={t('profileVisual:border.title')}
      visible={showVisualBorderDialog}
      footer={[
        <Button className="rounded font-semibold" key="cancel" onClick={handleBorderCancel}>
          {t('profileVisual:border.cancel')}
        </Button>,
        <Button className="rounded font-semibold" key="apply" type="primary" onClick={handleBorderOk}>
          {t('profileVisual:border.apply')}
        </Button>,
      ]}
    >
      {/**
       * Border type
       */}
      <Radio.Group buttonStyle="solid" defaultValue={borderType} onChange={handleChangeBorderType} className="mb-4">
        <Radio.Button value={'invisible' as TBorder}>{t('profileVisual:border.invisible')}</Radio.Button>
        <Radio.Button value={'color' as TBorder}>{t('profileVisual:border.color')}</Radio.Button>
      </Radio.Group>

      {borderType === 'color' ? (
        <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
          {/**
           * Border color
           */}
          <div className="col-span-1">
            <p className="mt-3 font-semibold">{t('profileVisual:border.color')}</p>
            <div className="flex w-full">
              <div className="w-11">
                <Input
                  className="rounded"
                  type="color"
                  size="large"
                  value={colorHex || '#f3f4f6'}
                  onChange={(e) => setColorHex(e.target.value)}
                ></Input>
              </div>
              <p className="ml-2 mt-2 text-base font-medium">{colorHex}</p>
            </div>
          </div>

          {/**
           * Border width
           */}
          <div className="col-span-1">
            <div className="mt-3 font-semibold text-md">{t('profileVisual:border.width')}</div>
            <Input
              className="rounded"
              size="large"
              type="number"
              value={width || 0}
              onChange={(e) => setWidth(parseInt(e.target.value))}
            ></Input>
          </div>
        </div>
      ) : null}
    </Modal>
  )
}

export default VisualBackground
