/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { Button, Modal, Select, Input } from 'antd'
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  currentTaskUserState,
  showTaskUserDialogState,
} from '../recoil/atoms'
import { useTranslation } from 'react-i18next'
import { CreateUserUseCase, IUserInfo, QueryUsersUseCase } from '@axelity/actasign-sic'
import * as yup from 'yup'
import SectionTitle from '../elements/SectionTitle'
import FieldTitle from '../elements/FieldTitle'

const { Option } = Select

const TaskUser = () => {
  const { t } = useTranslation(['common', 'taskUser'])

  const setCurrentTaskUser = useSetRecoilState(currentTaskUserState)
  const resetCurrentTaskUser = useResetRecoilState(currentTaskUserState)

  const emailRef = useRef(null)
  const firstNameRef = useRef(null)

  const [firstName, setFirstName] = useState<string>('')
  const [familyName, setFamilyName] = useState<string>('')
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [language, setLanguage] = useState<string>('')

  const [isUpdating, setUpdating] = useState<boolean>(false)

  const [showDialog, setShowDialog] = useRecoilState(showTaskUserDialogState)

  const [canSearch, setCanSearch] = useState<boolean>(false)
  const [searchExecuted, setSearchExecuted] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [userExists, setUserExists] = useState<boolean>(false)

  const [taskUser, setTaskUser] = useState<IUserInfo>(undefined)

  const lookup = () => {
    if (!canSearch) return

    setLoading(true)

    new QueryUsersUseCase()
      .query({
        emailAddress,
      })
      .then((users) => {
        if (users.length > 0) {
          const _userInfo = users[0]
          setTaskUser(_userInfo)

          setFirstName(_userInfo.firstName)
          setFamilyName(_userInfo.familyName)
          setEmailAddress(_userInfo.email?.emailAddress || '')
          setLanguage(_userInfo.language)
          setUserExists(true)
        } else {
          setFirstName('')
          setFamilyName('')
          setLanguage('')
        }
        setSearchExecuted(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const handleChangeEmailAddress = (value: string) => {
    setEmailAddress(value)
    setSearchExecuted(false)
    if (
      yup
        .string()
        .required()
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
        .isValidSync(value)
    ) {
      setCanSearch(true)
    }
  }

  const handleOk = async () => {
    setUpdating(true)

    try {
      if (!taskUser) {
        const _userInfo = await new CreateUserUseCase().create({
          emailAddress,
          familyName,
          firstName,
          language,
        })
        setCurrentTaskUser(_userInfo)
      } else {
        setCurrentTaskUser(taskUser)
      }
      setShowDialog(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCancel = () => {
    resetCurrentTaskUser()
    setShowDialog(false)
  }

  const handleReset = () => {
    setFirstName('')
    setFamilyName('')
    setEmailAddress('')
    setLanguage('')
    setCanSearch(false)
    setLoading(false)
    setUserExists(false)
    setSearchExecuted(false)
    resetCurrentTaskUser()
    setTaskUser(undefined)
  }

  useEffect(() => {
    if (searchExecuted && !userExists) {
      firstNameRef.current.focus()
    }
  }, [searchExecuted, userExists])

  useEffect(() => {
    if (taskUser) {
      setFirstName(taskUser.firstName)
      setFamilyName(taskUser.familyName)
      setEmailAddress(taskUser.email.emailAddress)
      setLanguage(taskUser.language)
      setUserExists(true)
      setSearchExecuted(true)
    } else {
      handleReset()
    }
  }, [taskUser])

  return (
    <Modal
      title={t('taskUser:title')}
      visible={showDialog}
      footer={
        !taskUser
          ? [
              <Button className="rounded font-semibold" key="reset" type="ghost" onClick={handleReset}>
                {t('taskUser:action.reset')}
              </Button>,
              <Button className="rounded font-semibold" key="cancel" onClick={handleCancel}>
                {t('taskUser:action.cancel')}
              </Button>,
              <Button
                className="rounded font-semibold"
                key="apply"
                type="primary"
                onClick={handleOk}
                disabled={!searchExecuted}
                loading={isUpdating}
              >
                {t('taskUser:action.apply')}
              </Button>,
            ]
          : [
              <Button className="rounded font-semibold" key="cancel" onClick={handleCancel}>
                {t('taskUser:action.cancel')}
              </Button>,
              <Button
                className="rounded font-semibold"
                key="apply"
                type="primary"
                onClick={handleOk}
                disabled={!searchExecuted}
                loading={isUpdating}
              >
                {t('taskUser:action.apply')}
              </Button>,
            ]
      }
    >
      <div className="mt-2">
        <FieldTitle title={t('taskUser:emailAddress')} />
        <div className="flex w-full">
          <Input
            className="rounded"
            type="email"
            size="large"
            disabled={userExists}
            value={emailAddress}
            onChange={(e) => handleChangeEmailAddress(e.target.value)}
            onPressEnter={lookup}
            ref={emailRef}
          ></Input>

          <Button
            className="ml-2 rounded font-semibold"
            disabled={!canSearch}
            size="large"
            type="primary"
            onClick={(e) => lookup()}
            loading={isLoading}
          >
            {t('taskUser:action.select')}
          </Button>
        </div>

        {searchExecuted ? (
          <div>
            {!userExists ? <SectionTitle title={t('taskUser:newUser')} /> : null}

            <FieldTitle title={t('taskUser:firstName')} />
            <div className="inline-flex w-full">
              <Input
                ref={firstNameRef}
                className="rounded"
                autoComplete="first-name"
                type="text"
                size="large"
                disabled={userExists}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              ></Input>
            </div>

            <FieldTitle title={t('taskUser:familyName')} />
            <div className="inline-flex w-full">
              <Input
                className="rounded"
                autoComplete="family-name"
                type="text"
                size="large"
                disabled={userExists}
                value={familyName}
                onChange={(e) => setFamilyName(e.target.value)}
              ></Input>
            </div>

            {/**
             * Language
             */}
            <FieldTitle title={t('taskUser:language')} />
            <div className="inline-flex w-full">
              <div className="w-full">
                <Select
                  className="rounded"
                  disabled={userExists}
                  value={language}
                  size="large"
                  style={{ width: '100%' }}
                  onChange={(value) => setLanguage(value)}
                >
                  <Option value="de">{t('common:language.german')}</Option>
                  <Option value="en">{t('common:language.english')}</Option>
                  {/* <Option value="fr">{t('common:language.french')}</Option> */}
                </Select>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  )
}

export default TaskUser
