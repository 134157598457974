type TSectionTitleProps = {
  title: string
}
const SectionTitle = ({title}: TSectionTitleProps) => {
  return (
    <h3 className="mt-3 text-xl text-gray-700 font-semibold">{title}</h3>
  )
}

export default SectionTitle
