/* eslint-disable import/first */
/* eslint-disable react-hooks/exhaustive-deps */
import { IUserInfo, QueryUsersUseCase } from '@axelity/actasign-sic'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import NavBar from '../components/NavBar'
import AccountUser from '../components/AccountUser'
import PageHeader from '../components/PageHeader'
import UserList from '../components/UserList'
import useSignIn from '../hooks/useSignIn'
import {
  accountUsersState,
  currentAccountState,
  currentRolesState,
  showAccountUserDialogState,
  userInfoState,
} from '../recoil/atoms'

const SettingsAccountUsers = () => {
  const { t } = useTranslation(['settings', 'accountUsers'])

  const [noAccessToken] = useSignIn()

  const history = useHistory()

  const userInfo = useRecoilValue(userInfoState)
  const currentRoles = useRecoilValue(currentRolesState)

  const setShowDialog = useSetRecoilState(showAccountUserDialogState)
  const currentAccount = useRecoilValue(currentAccountState)

  const [users, setUsers] = useRecoilState(accountUsersState)

  const handleNewUser = () => {
    setShowDialog(true)
  }

  useEffect(() => {
    if (userInfo && currentAccount && currentRoles) {
      if (
        currentRoles.includes('PRIMARY-ADMIN') ||
        currentRoles.includes('USER-ADMIN') ||
        currentRoles.includes('MASTER-ADMIN')
      ) {
        new QueryUsersUseCase()
          .query({
            account: currentAccount._id,
          })
          .then((_users: IUserInfo[]) => {
            setUsers(_users)
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }, [userInfo, currentRoles, currentAccount])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  useEffect(() => {
    if (currentRoles) {
      if (
        currentRoles.includes('PRIMARY-ADMIN') ||
        currentRoles.includes('USER-ADMIN') ||
        currentRoles.includes('MASTER-ADMIN')
      ) {
        // ok
      }
    }
  }, [currentRoles])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('accountUsers:list.title')}
        pages={[
          {
            name: t('settings:accountSettings.name'),
          },
          {
            name: t('accountUsers:list.title'),
          },
        ]}
        buttons={[
          {
            href: '',
            label: t('accountUsers:list.newUser'),
            type: 'primary',
            onClick: handleNewUser,
          },
        ]}
      />

      <AccountUser />

      {/**
       * Steps
       */}
      <div className="container max-w-4xl mx-auto text-base bg-white p-4 mt-4 rounded-sm">
        <UserList users={users} />
      </div>
    </div>
  )
}

export default SettingsAccountUsers
