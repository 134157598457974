/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import VisualBackground from '../components/Visual/VisualBackground'
import VisualPreview from '../components/Visual/VisualPreview'
import VisualBorder from '../components/Visual/VisualBorder'
import VisualFontStyle from '../components/Visual/VisualFontStyle'
import VisualTextLine from '../components/Visual/VisualTextLine'
import VisualImage from '../components/Visual/VisualImage'
import ActionRibbon from '../components/ActionRibbon'
import { Input, Select, Switch, Button, message } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CreateVisualUseCase,
  DeleteVisualUseCase,
  TVisualAvailability,
  UpdateVisualUseCase,
} from '@axelity/actasign-sic'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  currentAccountState,
  currentImageState,
  currentRolesState,
  currentTextLineState,
  showVisualBackgroundDialogState,
  showVisualBorderDialogState,
  showVisualFontStyleDialogState,
  showVisualImageDialogState,
  showVisualTextLineDialogState,
  userInfoState,
  visualState,
} from '../recoil/atoms'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import useSignIn from '../hooks/useSignIn'
import FieldTitle from '../elements/FieldTitle'
import SectionTitle from '../elements/SectionTitle'

const { Option } = Select

const ProfileVisual = () => {
  const { t, i18n } = useTranslation(['profileVisual'])

  const [noAccessToken] = useSignIn()

  const history = useHistory()

  const userInfo = useRecoilValue(userInfoState)
  const currentAccount = useRecoilValue(currentAccountState)
  const currentRoles = useRecoilValue(currentRolesState)

  const [visual, setVisual] = useRecoilState(visualState)
  const setCurrentTextLine = useSetRecoilState(currentTextLineState)
  const setCurrentImage = useSetRecoilState(currentImageState)

  const setShowVisualBackgroundDialog = useSetRecoilState(showVisualBackgroundDialogState)
  const setShowVisualBorderDialog = useSetRecoilState(showVisualBorderDialogState)
  const setShowVisualFontStyleDialog = useSetRecoilState(showVisualFontStyleDialogState)
  const setShowVisualTextLineDialog = useSetRecoilState(showVisualTextLineDialogState)
  const setShowVisualImageDialog = useSetRecoilState(showVisualImageDialogState)

  const [displayName, setDisplayName] = useState<string>('')
  const [defaultReason, setDefaultReason] = useState<string>('')
  const [availability, setAvailability] = useState<TVisualAvailability>('USER')
  const [isVisible, setVisible] = useState<boolean>(true)
  const [isFix, setFix] = useState<boolean>(false)
  const [showLongDate, setShowLongDate] = useState<boolean>(false)
  const [addTime, setAddTime] = useState<boolean>(false)
  const [addSeconds, setAddSeconds] = useState<boolean>(false)
  const [addTimezone, setAddTimezone] = useState<boolean>(false)
  const [doUpdate, setUpdate] = useState<boolean>(true)

  const [positionPage, setPositionPage] = useState<number>(1)
  const [positionX, setPositionX] = useState<number>(0)
  const [positionY, setPositionY] = useState<number>(0)

  type TFormErrors = {
    displayName: string
    defaultReason: string
    availability: string
  }
  const [errors, setErrors] = useState<TFormErrors>({
    displayName: '',
    defaultReason: '',
    availability: '',
  })

  const validateForm = (): boolean => {
    let valid = true
    let errors: TFormErrors = {
      displayName: '',
      defaultReason: '',
      availability: '',
    }

    if (!yup.string().min(5).required().isValidSync(displayName)) {
      errors = { ...errors, displayName: t('profileVisual:error.displayName') }
      valid = false
    }

    if (
      !availability ||
      (availability !== 'USER' &&
        availability !== 'ACCOUNT' &&
        availability !== 'WORKSPACE' &&
        availability !== 'GLOBAL')
    ) {
      errors = { ...errors, availability: t('profileVisual:error.availability') }
      valid = false
    }

    setErrors(errors)

    return valid
  }

  const RATIO = 4

  const handleNewTextLine = () => {
    setCurrentTextLine(-1)
    setShowVisualTextLineDialog(true)
  }

  const handleNewImage = () => {
    setCurrentImage(-1)
    setShowVisualImageDialog(true)
  }

  const createPreviewImage = (omitText?: boolean): string => {
    const canvas = document.createElement('canvas')
    canvas.width = 720
    canvas.height = 200
    const context = canvas.getContext('2d')

    if (context && visual.background) {
      if (!visual.background.isTransparent && visual.background.colorHex) {
        context.beginPath()
        context.fillStyle = visual.background.colorHex
        context.fillRect(0, 0, canvas.width, canvas.height)
        context.closePath()
      }

      if (visual.background.imageDataUrl) {
        context.beginPath()
        const _image = new Image()
        _image.src = visual.background.imageDataUrl
        context.drawImage(_image, 0, 0)
        context.closePath()
      }
    }

    // We have to draw the border here, because the PTS
    // doesn't draw the border if a background image is provided
    if (context && visual.border) {
      if (visual.border.isVisible) {
        context.lineWidth = visual.border.width
        context.strokeStyle = visual.border.colorHex
        context.strokeRect(0, 0, canvas.width, canvas.height)
      }
    }

    if (context && visual.images) {
      context.beginPath()
      visual.images.forEach((image) => {
        const _image = new Image()
        _image.src = image.imageDataUrl
        const scale = image.width / _image.naturalWidth
        context.drawImage(_image, image.x, image.y, image.width, _image.naturalHeight * scale)
      })
      context.closePath()
    }

    if (!omitText && context && visual.textLines && visual.fontStyles) {
      context.beginPath()
      visual.textLines.forEach((textLine) => {
        const fontStyle = visual.fontStyles?.find((style) => {
          return style.styleName === textLine.fontStyle
        })
        if (fontStyle) {
          const font = `${fontStyle.fontSize * RATIO}px ${fontStyle.fontFamily}`
          context.font = font
          context.fillStyle = fontStyle.fontColorHex
          context.fillText(textLine.displayText, textLine.x, textLine.y + fontStyle.fontSize * RATIO)
        }
      })
      context.closePath()
    }

    return canvas.toDataURL('image/png')
  }

  const handleDownload = () => {
    const link = document.createElement('a')
    link.download = 'preview.png'
    link.href = createPreviewImage()
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleBack = () => {
    history.push('/profile/visuals')
  }

  const handleDelete = () => {
    if (visual._id) {
      new DeleteVisualUseCase({
        visualId: visual._id,
      })
        .delete()
        .then(() => {
          message.success(t('profileVisual:result.deleteSuccessful'))
          history.push('/profile/visuals')
        })
        .catch((error) => {
          message.error(t('profileVisual:result.deleteFailed'))
          console.error(error)
        })
    }
  }

  const handleSave = () => {
    if (!validateForm()) return

    if (visual._id) {
      new UpdateVisualUseCase({
        visualId: visual._id,
      })
        .update({
          user: userInfo._id,
          account: currentAccount?._id,
          displayName,
          defaultReason,
          availability,
          width: visual.width,
          height: visual.height,
          language: i18n.language.substr(0, 2),
          background: visual.background,
          border: visual.border,
          settings: {
            addSeconds,
            addTime,
            addTimezone,
            isFix,
            isVisible,
            showLongDate,
          },
          fontStyles: visual.fontStyles,
          images: visual.images,
          position: isFix
            ? {
                page: positionPage,
                x: positionX,
                y: positionY,
              }
            : undefined,
          textLines: visual.textLines,
          visualDataUrl: createPreviewImage(true),
        })
        .then(() => {
          message.success(t('profileVisual:result.saveSuccessful'))
          history.push('/profile/visuals')
        })
        .catch((error) => {
          message.error(t('profileVisual:result.saveFailed'))
          console.error(error)
        })
    } else {
      new CreateVisualUseCase()
        .create({
          displayName,
          defaultReason,
          availability,
          width: visual.width,
          height: visual.height,
          language: i18n.language.substr(0, 2),
          background: visual.background,
          border: visual.border,
          settings: {
            addSeconds,
            addTime,
            addTimezone,
            isFix,
            isVisible,
            showLongDate,
          },
          fontStyles: visual.fontStyles,
          images: visual.images,
          position: isFix
            ? {
                page: positionPage,
                x: positionX,
                y: positionY,
              }
            : undefined,
          textLines: visual.textLines,
          visualDataUrl: createPreviewImage(true),
          user: userInfo._id,
          account: currentAccount?._id,
        })
        .then(() => {
          message.success(t('profileVisual:result.saveSuccessful'))
          history.push('/profile/visuals')
        })
        .catch((error) => {
          message.error(t('profileVisual:result.saveFailed'))
          console.error(error)
        })
    }
  }

  useEffect(() => {
    if (visual && doUpdate) {
      setDisplayName(visual.displayName)
      setDefaultReason(visual.defaultReason)
      setAvailability(visual.availability || 'USER')
      setVisible(visual.settings?.isVisible)
      setFix(visual.settings?.isFix)
      setShowLongDate(visual.settings?.showLongDate)
      setAddTime(visual.settings?.addTime)
      setAddSeconds(visual.settings?.addSeconds)
      setAddTimezone(visual.settings?.addTimezone)

      setUpdate(false)
    }
  }, [visual])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  useEffect(() => {
    setUpdate(true)
  }, [])

  return (
    <div>
      <NavBar />

      <PageHeader title={t('profileVisual:general.title')} />

      {/**
       * Modal dialogs
       */}
      <VisualBackground />
      <VisualBorder />
      <VisualFontStyle />
      <VisualTextLine />
      <VisualImage />

      <div
        style={{ width: '776px' }}
        className="container mx-auto text-base bg-white border border-gray-100 p-4 mt-4 shadow-sm rounded-md"
      >
        <div
          style={{ width: '740px', height: '220px' }}
          className="container flex justify-center items-center border border-gray-100"
        >
          <VisualPreview />
        </div>
        <ActionRibbon
          showFontStyle={true}
          onFontStyleClick={() => setShowVisualFontStyleDialog(true)}
          showBackground={true}
          onBackgroundClick={() => setShowVisualBackgroundDialog(true)}
          showBorder={true}
          onBorderClick={() => setShowVisualBorderDialog(true)}
          showAddImage={true}
          onAddImageClick={handleNewImage}
          showAddText={true}
          onAddTextClick={handleNewTextLine}
          showDownload={true}
          onDownloadClick={handleDownload}
        />

        {/**
         * Visual name
         */}
        <div className="mt-4">
          <FieldTitle title={t('profileVisual:general.name')} />
          <Input
            className="rounded"
            size="large"
            placeholder={t('profileVisual:general.name')}
            value={displayName}
            onChange={(e) => {
              setDisplayName(e.target.value)
            }}
          ></Input>
          <div className="my-1 font-semibold text-md text-red-500">{errors.displayName}</div>
        </div>

        {/**
         * Default reason
         */}
        <div className="mt-4">
          <FieldTitle title={t('profileVisual:general.reason')} />
          <Input
            className="rounded"
            size="large"
            placeholder={t('profileVisual:general.reason')}
            value={defaultReason}
            onChange={(e) => {
              setDefaultReason(e.target.value)
            }}
          ></Input>
          <div className="my-1 font-semibold text-md text-red-500">{errors.defaultReason}</div>
        </div>

        {/**
         * Availability
         */}
        <div className="my-2">
          <FieldTitle title={t('profileVisual:general.availability.title')} />
          <div className="w-full">
            <Select
              className="rounded"
              value={availability}
              size="large"
              style={{ width: '100%' }}
              onChange={(value) => {
                setAvailability(value)
              }}
            >
              <Option value={'USER' as TVisualAvailability}>{t('profileVisual:general.availability.user')}</Option>
              {/* 
                {currentRoles.includes('PRIMARY-ADMIN') ? (
                  <Option value={'WORKSPACE' as TVisualAvailability}>
                    {t('profileVisual:general.availability.workspace')}
                  </Option>
                ) : null}
              */}
              {currentRoles.includes('PRIMARY-ADMIN') ? (
                <Option value={'ACCOUNT' as TVisualAvailability}>
                  {t('profileVisual:general.availability.account')}
                </Option>
              ) : null}
              {currentRoles.includes('MASTER-ADMIN') ? (
                <Option value={'GLOBAL' as TVisualAvailability}>
                  {t('profileVisual:general.availability.global')}
                </Option>
              ) : null}
            </Select>
            <div className="my-1 font-semibold text-md text-red-500">{errors.availability}</div>
          </div>
        </div>

        {/**
         * Settings
         */}
        <div className="mt-8">
          <SectionTitle title={t('profileVisual:settings.title')} />
          {/* <div className="flex align-middle mt-4">
          <Switch
            checked={isVisible}
            onClick={(value: boolean) => {
              setVisible(value)
            }}
          />
          <p style={{ textTransform: 'none', fontWeight: 400 }} className="pl-2 text-md">
            {t('profileVisual:settings.visible')}
          </p>
        </div> */}

          <div className="flex align-middle mt-2">
            <Switch
              checked={showLongDate}
              onClick={(value: boolean) => {
                setShowLongDate(value)
              }}
            />
            <p style={{ textTransform: 'none', fontWeight: 400 }} className="pl-2 text-md">
              {t('profileVisual:settings.showLongDate')}
            </p>
          </div>
        </div>

        <div className="flex flex-auto mt-4 justify-between">
          <Button className="rounded font-semibold" size="large" type="ghost" onClick={handleBack}>
            {t('profileVisual:action.back')}
          </Button>
          <div>
            {visual && visual._id ? (
              <Button
                className="rounded font-semibold"
                danger={true}
                size="large"
                type="primary"
                onClick={handleDelete}
              >
                {t('profileVisual:action.remove')}
              </Button>
            ) : null}

            <Button className="ml-2 rounded font-semibold" size="large" type="primary" onClick={handleSave}>
              {t('profileVisual:action.save')}
            </Button>
          </div>
        </div>
        {visual ? (
          <div className="flex flex-auto mt-4 justify-between">
            <p className="font-light text-xs text-gray-400">ID: {visual._id}</p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ProfileVisual
