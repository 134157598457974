/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Button, Input, Select, message } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { useRecoilState } from 'recoil'
import { userInfoState } from '../recoil/atoms'
import {
  GetUserUseCase,
  TUserAuthenticationMethod,
  UpdateUserAuthMethodUseCase,
  UpdateUserOtherSettingsUseCase,
} from '@axelity/actasign-sic'
import useSignIn from '../hooks/useSignIn'
import FieldTitle from '../elements/FieldTitle'
import SectionTitle from '../elements/SectionTitle'

const { Option } = Select

const ProfileOtherSettings = () => {
  const { t } = useTranslation(['profileOtherSettings', 'profile'])

  const [noAccessToken] = useSignIn()

  const history = useHistory()

  const [userInfo, setUserInfo] = useRecoilState(userInfoState)

  const [isEmailLink, setIsEmailLink] = useState<boolean>(true)
  const [isPassword, setIsPassword] = useState<boolean>(false)
  const [isTotp, setIsTotp] = useState<boolean>(false)

  const [suffixSignedDocuments, setSuffixSignedDocuments] = useState<string>('')
  const [authMethod, setAuthMethod] = useState<TUserAuthenticationMethod>('EMAIL-LINK')

  const saveHandler = () => {
    new UpdateUserOtherSettingsUseCase({
      userId: userInfo._id as string,
    })
      .update({
        suffixSignedDocuments,
      })
      .then(() => {
        return new UpdateUserAuthMethodUseCase({
          userId: userInfo._id as string,
        }).update({
          authMethod,
        })
      })
      .then(() => {
        return new GetUserUseCase().getUser()
      })
      .then((userInfo) => {
        setUserInfo(userInfo)
        message.success(t('updateSuccessful'))
        history.push('/home')
      })
      .catch((error) => {
        console.error(error)
        message.error(t('updateFailed'))
      })
  }

  const cancelHandler = () => {
    history.push('/home')
  }

  useEffect(() => {
    if (userInfo) {
      if (userInfo.otherSettings) {
        setSuffixSignedDocuments(userInfo.otherSettings?.suffixSignedDocuments as string)
      }

      setAuthMethod(userInfo.authMethod)

      if (userInfo.email && userInfo.email.emailAddress) {
        setIsEmailLink(true)
      } else {
        setIsEmailLink(false)
      }

      if (userInfo.password && userInfo.password.lastVerified) {
        setIsPassword(true)
      } else {
        setIsPassword(false)
      }

      if (userInfo.totp && userInfo.totp.lastVerified) {
        setIsTotp(true)
      } else {
        setIsTotp(false)
      }
    }
  }, [userInfo])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('profile:otherSettings.name')}
        pages={[
          {
            name: t('profile:userProfile.name'),
          },
          {
            name: t('profile:otherSettings.name'),
          },
        ]}
      />

      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        <SectionTitle title={t('profileOtherSettings:authMethod')} />

        <FieldTitle title={t('profileOtherSettings:authMethod')} />
        <div className="inline-flex w-full">
          <div className="w-full">
            <Select
              className="rounded"
              value={authMethod}
              size="large"
              style={{ width: '100%' }}
              onChange={(value) => setAuthMethod(value)}
            >
              {isEmailLink ? <Option value="EMAIL-LINK">{t('profileOtherSettings:emailLink')}</Option> : null}
              {isPassword ? <Option value="PASSWORD">{t('profileOtherSettings:password')}</Option> : null}
              {isTotp ? <Option value="TOTP">{t('profileOtherSettings:totp')}</Option> : null}
            </Select>
          </div>
        </div>

        <div className="mt-8">
          <SectionTitle title={t('profileOtherSettings:signedDocuments')} />

          <FieldTitle title={t('profileOtherSettings:suffix')} />
          <div className="inline-flex w-full">
            <Input
              className="rounded"
              type="text"
              size="large"
              placeholder={t('profileOtherSettings:suffix')}
              value={suffixSignedDocuments}
              onChange={(e) => setSuffixSignedDocuments(e.target.value)}
            ></Input>
          </div>
        </div>

        <div className="flex flex-auto mt-8 justify-between">
          <Button className="rounded font-semibold" size="large" type="ghost" onClick={cancelHandler}>
            {t('back')}
          </Button>
          <Button className="rounded font-semibold" size="large" type="primary" onClick={saveHandler}>
            {t('apply')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ProfileOtherSettings
