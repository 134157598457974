/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'
import { Popover, Transition, Menu } from '@headlessui/react'
import {
  MenuIcon,
  XIcon,
  // BookOpenIcon,
  ColorSwatchIcon,
  // LinkIcon,
  CodeIcon,
  AtSymbolIcon,
  // ServerIcon,
  UsersIcon,
  // KeyIcon,
  BriefcaseIcon,
  UserIcon,
  UserCircleIcon,
  CogIcon,
  MailIcon,
  DeviceMobileIcon,
  EyeIcon,
  LockClosedIcon,
  CalculatorIcon,
  AdjustmentsIcon,
  ClipboardListIcon,
  // ScaleIcon,
  // ReplyIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Tooltip } from 'antd'

import AppLogo from './AppLogo'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { accessTokenState, currentAccountState, userInfoState, processingInviteTaskState } from '../recoil/atoms'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const NavBar = () => {
  const { t } = useTranslation(['common', 'settings', 'profile'])

  const history = useHistory()

  const userInfo = useRecoilValue(userInfoState)
  const currentAccount = useRecoilValue(currentAccountState)
  const resetAccessToken = useResetRecoilState(accessTokenState)
  const resetUserInfo = useResetRecoilState(userInfoState)
  const processingInviteTask = useRecoilValue(processingInviteTaskState)

  const [firstName, setFirstName] = useState<string>('')
  const [familyName, setFamilyName] = useState<string>('')

  const [allowedAccountSettings, setAllowedAccountSettings] = useState<
    {
      key: string
      name: string
      description: string
      href: string
      icon: any
    }[]
  >([])
  const [allowedProfileSettings, setAllowedProfileSettings] = useState<
    {
      key: string
      name: string
      description: string
      href: string
      icon: any
    }[]
  >([])

  const handleHome = () => {
    if (!processingInviteTask) history.push('/home')
  }

  const handleSignOut = () => {
    resetUserInfo()
    resetAccessToken()
    history.replace('/signin')
  }

  // Define settings
  const accountSettings = [
    // {
    //   key: 'accountDetails',
    //   name: t('settings:accountDetails.name'),
    //   description: t('settings:accountDetails.description'),
    //   href: '/settings/account',
    //   icon: BriefcaseIcon,
    // },
    {
      key: 'branding',
      name: t('settings:branding.name'),
      description: t('settings:branding.description'),
      href: '/settings/branding',
      icon: ColorSwatchIcon,
    },
    // {
    //   key: 'redirect',
    //   name: t('settings:redirect.name'),
    //   description: t('settings:redirect.description'),
    //   href: '/settings/redirect',
    //   icon: ReplyIcon,
    // },
    {
      key: 'apiKeys',
      name: t('settings:apiKeys.name'),
      description: t('settings:apiKeys.description'),
      href: '/settings/apikeys',
      icon: CodeIcon,
    },
    {
      key: 'emailGateway',
      name: t('settings:emailGateway.name'),
      description: t('settings:emailGateway.description'),
      href: '/settings/email_gateway',
      icon: AtSymbolIcon,
    },
    // {
    //   key: 'swisscomAIS',
    //   name: t('settings:swisscomAIS.name'),
    //   description: t('settings:swisscomAIS.description'),
    //   href: '/settings/swisscom_ais',
    //   icon: ServerIcon,
    // },
    {
      key: 'users',
      name: t('settings:users.name'),
      description: t('settings:users.description'),
      href: '/settings/account/users',
      icon: UsersIcon,
    },
    // {
    //   key: 'workspaces',
    //   name: t('settings:workspaces.name'),
    //   description: t('settings:workspaces.description'),
    //   href: '/settings/workspaces',
    //   icon: BookOpenIcon,
    // },
  ]

  const profileSettings = [
    {
      key: 'userDetails',
      name: t('profile:userDetails.name'),
      description: t('profile:userDetails.description'),
      href: '/profile/basic',
      icon: UserIcon,
    },
    {
      key: 'emailAddress',
      name: t('profile:emailAddress.name'),
      description: t('profile:emailAddress.description'),
      href: '/profile/email',
      icon: AtSymbolIcon,
    },
    {
      key: 'mobileNumber',
      name: t('profile:mobileNumber.name'),
      description: t('profile:mobileNumber.description'),
      href: '/profile/mobile',
      icon: DeviceMobileIcon,
    },
    {
      key: 'password',
      name: t('profile:password.name'),
      description: t('profile:password.description'),
      href: '/profile/password',
      icon: LockClosedIcon,
    },
    {
      key: 'totp',
      name: t('profile:totp.name'),
      description: t('profile:totp.description'),
      href: '/profile/totp',
      icon: CalculatorIcon,
    },
    // {
    //   key: 'evidenceValue',
    //   name: t('profile:evidenceValue.name'),
    //   description: t('profile:evidenceValue.description'),
    //   href: '/profile/evidence_value',
    //   icon: ScaleIcon,
    // },
    {
      key: 'visuals',
      name: t('profile:visuals.name'),
      description: t('profile:visuals.description'),
      href: '/profile/visuals',
      icon: EyeIcon,
    },
    // {
    //   key: 'e2ee',
    //   name: t('profile:e2ee.name'),
    //   description: t('profile:e2ee.description'),
    //   href: '/profile/e2ee',
    //   icon: KeyIcon,
    // },
    // {
    //   key: 'link',
    //   name: t('profile:link.name'),
    //   description: t('profile:link.description'),
    //   href: '/profile/magic_link',
    //   icon: LinkIcon,
    // },
    {
      key: 'accounts',
      name: t('profile:accounts.name'),
      description: t('profile:accounts.description'),
      href: '/profile/accounts',
      icon: BriefcaseIcon,
    },
    {
      key: 'otherSettings',
      name: t('profile:otherSettings.name'),
      description: t('profile:otherSettings.description'),
      href: '/profile/other_settings',
      icon: AdjustmentsIcon,
    },
  ]

  useEffect(() => {
    if (userInfo) {
      // console.log(userInfo)

      setFirstName(userInfo.firstName)
      setFamilyName(userInfo.familyName)

      if (!userInfo.memberships) {
        setAllowedProfileSettings(
          profileSettings.filter((s) => {
            return s.key !== 'e2ee' && s.key !== 'link'
          }),
        )
      } else {
        setAllowedProfileSettings(
          profileSettings.filter((s) => {
            if (s.key === 'accounts') {
              if (
                userInfo.memberships &&
                userInfo.memberships.length > 0 &&
                userInfo.memberships[0].roles.includes('PRIMARY-ADMIN')
              ) {
                return true
              } else {
                return false
              }
            }
            return true
          }),
        )
      }
      setAllowedAccountSettings(
        accountSettings.filter((s) => {
          if (
            userInfo.memberships &&
            userInfo.memberships.length > 0 &&
            userInfo.memberships[0].roles.includes('USER-ADMIN')
          ) {
            if (s.key.includes('users')) return true
          } else if (
            userInfo.memberships &&
            userInfo.memberships.length > 0 &&
            userInfo.memberships[0].roles.includes('PRIMARY-ADMIN')
          ) {
            return true
          }
          return false
        }),
      )
    }
  }, [userInfo])

  const AccountSettingsMenu = () => {
    return (
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={classNames(
                open ? 'text-acs-blue-500' : 'text-gray-500',
                'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-acs-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-antd-blue',
              )}
            >
              <div className="flex flex-nowrap items-center">
                <CogIcon className="w-5 h-5" />
                <p className="ml-1">{t('settings:accountSettings.name')}</p>
              </div>

              <ChevronDownIcon
                className={classNames(
                  open ? 'text-acs-blue-500' : 'text-gray-400',
                  'ml-2 h-5 w-5 group-hover:text-acs-blue-500',
                )}
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className="absolute z-50 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    {allowedAccountSettings.map((item) => (
                      <Link key={item.key} to={item.href}>
                        <div className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                          <item.icon className="flex-shrink-0 h-6 w-6 text-acs-blue-500" aria-hidden="true" />
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">{item.name}</p>
                            <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    )
  }

  const ProfileMenu = () => {
    return (
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={classNames(
                open ? 'text-acs-blue-500' : 'text-gray-500',
                'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-acs-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-antd-blue',
              )}
            >
              <div className="flex flex-nowrap items-center">
                <UserCircleIcon className="w-5 h-5" />
                <p className="ml-1 whitespace-nowrap">{t('profile:userProfile.name')}</p>
              </div>

              <ChevronDownIcon
                className={classNames(
                  open ? 'text-acs-blue-500' : 'text-gray-400',
                  'ml-2 h-5 w-5 group-hover:text-acs-blue-500',
                )}
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className="absolute z-50 -left-full transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="relative grid grid-cols-1 gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    {allowedProfileSettings.map((item) => (
                      <Link key={item.key} to={item.href}>
                        <div className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                          <item.icon className="flex-shrink-0 h-6 w-6 text-acs-blue-500" aria-hidden="true" />
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">{item.name}</p>
                            <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    )
  }

  return (
    <Popover className="relative bg-white min-w-max z-30">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
              {/**
               * Dashboard
               */}
              <div className="flex justify-start hover:text-acs-blue-500 hover:cursor-pointer" onClick={handleHome}>
                <AppLogo />
              </div>

              {/**
               * Hamburger menu
               */}
              {!processingInviteTask ? (
                <div className="-mr-2 -my-2 md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-antd-blue">
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              ) : null}

              {/**
               * Tasks and requests
               */}
              {!processingInviteTask ? (
                <div className="hidden md:flex justify-start md:flex-1 lg:w-0">
                  <div className="text-base font-medium text-gray-500 hover:text-gray-900">
                    <Link className="hover:text-acs-blue-500" to="/tasks">
                      <div className="flex flex-nowrap items-center">
                        <ClipboardListIcon className="w-5 h-5" />
                        <p className="ml-1">{t('common:myTasks')}</p>
                      </div>
                    </Link>
                  </div>

                  {userInfo &&
                  userInfo.memberships &&
                  userInfo.memberships.length > 0 &&
                  !userInfo.memberships[0].plan.includes('PARTNER') &&
                  !userInfo.memberships[0].plan.includes('GUEST') &&
                  !userInfo.memberships[0].plan.includes('FREE') ? (
                    <div className="ml-8 text-base font-medium text-gray-500 hover:text-gray-900">
                      <Link className="hover:text-acs-blue-500" to="/requests">
                        <div className="flex flex-nowrap items-center">
                          <MailIcon className="w-5 h-5" />
                          <p className="ml-1">{t('common:requestList')}</p>
                        </div>
                      </Link>
                    </div>
                  ) : null}
                </div>
              ) : null}

              <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <Popover.Group className="hidden md:flex space-x-10">
                  {/**
                   * First fly-out menu for account settings
                   */}

                  {!processingInviteTask &&
                  userInfo &&
                  userInfo.memberships &&
                  userInfo.memberships.length > 0 &&
                  (userInfo.memberships[0].roles.includes('PRIMARY-ADMIN') ||
                    userInfo.memberships[0].roles.includes('USER-ADMIN')) ? (
                    <AccountSettingsMenu />
                  ) : null}

                  {/**
                   * Second fly-out menu for profile
                   */}

                  {!processingInviteTask && userInfo && <ProfileMenu />}
                </Popover.Group>

                {/* Profile dropdown */}
                {!processingInviteTask ? (
                  <Menu as="div" className="ml-4 relative flex-shrink-0">
                    {({ open }) => (
                      <>
                        <div>
                          <Tooltip
                            placement="left"
                            title={`${firstName} ${familyName} ${
                              currentAccount ? '(' + currentAccount.displayName + ')' : ''
                            }`}
                          >
                            <Menu.Button className="bg-acs-blue-500 p-2 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                              <span className="font-bold">{`${firstName ? firstName.substr(0, 1) : ''}${
                                familyName ? familyName.substr(0, 1) : ''
                              }`}</span>
                            </Menu.Button>
                          </Tooltip>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 hover:cursor-pointer',
                                  )}
                                  onClick={handleSignOut}
                                >
                                  {t('sign-out')}
                                </div>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                ) : null}
              </div>
            </div>
          </div>

          <Transition
            show={open && !processingInviteTask}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <AppLogo />
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-antd-blue">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid gap-y-8">
                      {allowedProfileSettings.map((item) => (
                        <Link key={item.key} to={item.href}>
                          <div className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                            <item.icon className="flex-shrink-0 h-6 w-6 text-acs-blue-500" aria-hidden="true" />
                            <p className="ml-2 text-base font-medium text-gray-900">{item.name}</p>
                          </div>
                        </Link>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5 space-y-6">
                  <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                    {/*                     
                    <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                      Pricing
                    </a>

                    <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                      Docs
                    </a>
                    */}
                    {accountSettings.map((item) => (
                      <Link key={item.key} to={item.href}>
                        <p className="text-base font-medium text-gray-900">{item.name}</p>
                      </Link>
                    ))}
                  </div>
                  <div>
                    <Link
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-antd-blue hover:bg-antd-blue"
                      to="/signin"
                    >
                      {t('sign-out')}
                    </Link>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default NavBar
