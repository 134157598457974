/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  activityListState,
  currentAccountState,
  currentWorkspaceState,
  documentState,
  dragObjectPositionState,
  dragObjectShiftState,
  pageListState,
  signatureFieldStateFamily,
  signatureListState,
  taskListState,
  viewerState,
} from '../recoil/atoms'
import { TSignatureField } from '../types/appTypes'
import { CloseCircleOutlined } from '@ant-design/icons'
import { FaSignature } from 'react-icons/fa'
import { CreateSignatureUseCase, DeleteSignatureUseCase, UpdateSignatureUseCase } from '@axelity/actasign-sic'
import { isEmpty } from 'lodash'

type TSignatureFieldProps = {
  fieldKey: string
  field: TSignatureField
}

const SignatureField = (props: TSignatureFieldProps) => {
  const [signatureField, setSignatureField] = useRecoilState(signatureFieldStateFamily(props.fieldKey))
  const setShift = useSetRecoilState(dragObjectShiftState)
  const position = useRecoilValue(dragObjectPositionState)
  const viewType = useRecoilValue(viewerState)

  const account = useRecoilValue(currentAccountState)
  const workspace = useRecoilValue(currentWorkspaceState)
  const activities = useRecoilValue(activityListState)
  const tasks = useRecoilValue(taskListState)
  const requestDocument = useRecoilValue(documentState)
  const pages = useRecoilValue(pageListState)
  const [signatures, setSignatures] = useRecoilState(signatureListState)

  const [label, setLabel] = useState<string>(signatureField.label)
  const [placed, setPlaced] = useState<boolean>(signatureField.placed)
  const [page, setPage] = useState<number>(signatureField.page || 0)

  const createSignature = ({
    pageNo,
    pageWidth,
    pageHeight,
    x,
    y,
  }: {
    pageNo: number
    pageWidth: number
    pageHeight: number
    x: number
    y: number
  }): Promise<void> => {
    return new Promise((resolve, reject) => {
      const _task = tasks.find((t) => {
        return t._id === props.field.taskId
      })
      if (_task) {
        const _activity = activities.find((a) => {
          return a._id === _task.activity
        })

        if (_activity) {
          new CreateSignatureUseCase()
            .create({
              account: account._id,
              workspace: workspace._id,
              process: _activity.process as string,
              activity: _activity._id,
              task: _task._id,
              document: requestDocument._id,
              rectangle: {
                fieldName: _task._id,
                width: 180,
                height: 50,
                page: pageNo,
                pageWidth,
                pageHeight,
                x,
                y,
              },
            })
            .then((signature) => {
              setSignatures([...signatures, signature])
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          resolve()
        }
      } else {
        resolve()
      }
    })
  }

  const removeSignature = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      const signature = signatures.find((_signature) => {
        return _signature.task === props.field.taskId
      })
      if (signature) {
        new DeleteSignatureUseCase({
          signatureId: signature._id,
        })
          .delete()
          .then(() => {
            setSignatures(
              signatures.filter((_signature) => {
                return _signature._id !== signature._id
              }),
            )
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const updateSignature = ({
    pageNo,
    pageWidth,
    pageHeight,
    x,
    y,
  }: {
    pageNo: number
    pageWidth: number
    pageHeight: number
    x: number
    y: number
  }): Promise<void> => {
    return new Promise((resolve, reject) => {
      const signature = signatures.find((_signature) => {
        return _signature.task === props.field.taskId
      })
      new UpdateSignatureUseCase({
        signatureId: signature._id,
      })
        .update({
          rectangle: {
            fieldName: signature.rectangle.fieldName,
            width: signature.rectangle.width,
            height: signature.rectangle.height,
            page: pageNo,
            pageWidth,
            pageHeight,
            x,
            y,
          },
        })
        .then((sig) => {
          setSignatures([
            ...signatures.map((_signature) => {
              if (_signature._id === sig._id) {
                return sig
              } else {
                return _signature
              }
            }),
          ])
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement

    // Determine the shift from left and top of the mouse pointer when start dragging
    setShift({
      shiftX: e.clientX - target.getBoundingClientRect().left,
      shiftY: e.clientY - target.getBoundingClientRect().top,
    })

    // Set information (signature field) that has to be dragged
    // This is consumed on PageItem drop event
    e.dataTransfer.setData('text/plain', JSON.stringify(props.field))
  }

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    // Determine the element (img) the signature field has been drop on
    const target = e.target as HTMLDivElement

    // Determine the parent node (div representing the document page) of the image,
    // so that we can get the dataset values
    // It cannot be the parentElement attribute, because it has no id
    //const pageDiv = target.parentNode as HTMLDivElement
    const parent = target.parentElement

    // Update the signature field information
    // but only if the signature has really been dropped on a document page
    if (parent && parent.dataset.page) {
      const newValue: TSignatureField = {
        label: signatureField.label,
        placed: true,
        signed: false,
        documentId: requestDocument._id,
        taskId: signatureField.taskId,
        page: parseInt(parent.dataset.page),
        x: position.left,
        y: position.top,
      }
      setSignatureField(newValue)

      const signature = signatures.find((_signature) => {
        return _signature.task === signatureField.taskId
      })

      if (signature) {
        updateSignature({
          pageNo: parseInt(parent.dataset.page),
          pageWidth: Math.round(parseFloat(parent.dataset.pagewidth)),
          pageHeight: Math.round(parseFloat(parent.dataset.pageheight)),
          x: Math.round(position.left),
          y: Math.round(position.top),
        }).catch((error) => {
          console.error(error)
        })
      } else {
        createSignature({
          pageNo: parseInt(parent.dataset.page),
          pageWidth: Math.round(parseFloat(parent.dataset.pagewidth)),
          pageHeight: Math.round(parseFloat(parent.dataset.pageheight)),
          x: Math.round(position.left),
          y: Math.round(position.top),
        }).catch((error) => {
          console.error(error)
        })
      }
    }
  }

  const removeSignatureFieldFromPage = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    // Determine the list where to place the removed signature field in
    const signatureFieldListDiv = document.getElementById('signatureFieldList')

    // Get the element representing this signature field
    const signatureFieldDiv = document.getElementById(props.fieldKey)

    if (signatureFieldListDiv && signatureFieldDiv) {
      // append the signature
      signatureFieldListDiv.appendChild(signatureFieldDiv)

      // reset the absolute position
      signatureFieldDiv.style.position = ''
      signatureFieldDiv.style.left = ''
      signatureFieldDiv.style.top = ''

      // Update the signature field
      const newValue: TSignatureField = {
        label: signatureField.label,
        placed: false,
        signed: false,
        taskId: signatureField.taskId,
        documentId: requestDocument._id,
        page: undefined,
        x: undefined,
        y: undefined,
      }
      setSignatureField(newValue)
      setPage(0)

      removeSignature().catch((error) => {
        console.error(error)
      })
    }
  }

  useEffect(() => {
    if (signatureField) {
      setLabel(signatureField.label)
      setPlaced(signatureField.placed)

      // Wait some time to HTML element build. Otherwise it can happen,
      // that getElementById below cannot find the page and the positioned
      // field cannot be placed on the page.
      const promise = new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve()
        }, 200)
      })

      promise.then(() => {
        // Place signature on corresponding page
        if (signatureField.page && signatureField.documentId === requestDocument._id) {
          setPage(signatureField.page)
          // Determine page DIV for appending signature field
          const pageElementId = `${requestDocument._id}_${signatureField.page}`
          let pageElement = document.getElementById(pageElementId)

          // Reference current signature field
          const fieldElement = document.getElementById(signatureField.taskId)

          if (pageElement && fieldElement) {
            // Attach signature field to page
            pageElement.appendChild(fieldElement)
            // ...and set the position
            fieldElement.style.position = 'absolute'
            fieldElement.style.top = `${signatureField.y}px`
            fieldElement.style.left = `${signatureField.x}px`
          }
        }
      })
    } else {
      console.warn(`Signature field not available`)
    }
  }, [signatureField, pages])

  useEffect(() => {
    setSignatureField(props.field)
  }, [])

  return (
    <div
      key={props.fieldKey}
      id={signatureField.taskId}
      draggable={viewType === 'CHANGE'}
      hidden={signatureField.signed}
      style={{ width: '180px', height: '50px' }}
      className={`bg-yellow-100 z-20 p-1 rounded border-2 border-gray-200 ${
        viewType === 'CHANGE' ? 'hover:cursor-move hover:border-acs-blue-600' : ''
      }`}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div className="flex justify-end items-center h-3">
        {placed && viewType === 'CHANGE' ? (
          <CloseCircleOutlined
            className="ml-2 text-gray-700 hover:text-red-500"
            onClick={removeSignatureFieldFromPage}
          />
        ) : null}
      </div>
      <div className="flex justify-start items-center">
        <FaSignature className="ml-1 mr-2 w-6 h-6 text-acs-blue-600" />
        <p className="text-xs font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis text-gray-700">
          {label}
        </p>
      </div>
    </div>
  )
}

export default SignatureField
