/* eslint-disable import/first */
/* eslint-disable react-hooks/exhaustive-deps */
import { QueryVisualsUseCase } from '@axelity/actasign-sic'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import VisualList from '../components/VisualList'
import useSignIn from '../hooks/useSignIn'
import { taskState, userInfoState, visualListState, visualState } from '../recoil/atoms'
import NoData from '../components/NoData'

const ProfileVisuals = () => {
  const { t } = useTranslation(['profile', 'profileVisual'])

  const history = useHistory()

  const userInfo = useRecoilValue(userInfoState)
  const resetTask = useResetRecoilState(taskState)
  const resetVisual = useResetRecoilState(visualState)

  const [visuals, setVisuals] = useRecoilState(visualListState)
  const resetVisuals = useResetRecoilState(visualListState)

  const [noAccessToken] = useSignIn()

  const handleNewVisual = () => {
    resetTask() // to prevent showing tasks actions
    resetVisual()
    history.push('/profile/visual')
  }

  useEffect(() => {
    // Query user visuals
    if (userInfo) {
      new QueryVisualsUseCase().query({}).then((_visuals) => {
        if (_visuals.length) {
          const _filteredVisuals = _visuals.filter((_visual) => {
            if (
              _visual.availability === 'GLOBAL' &&
              userInfo.memberships &&
              !userInfo.memberships[0].roles.includes('MASTER-ADMIN')
            ) {
              return false
            } else {
              return _visual.status !== 'CLOSED'
            }
          })

          const _sortedVisuals = _filteredVisuals.sort((a, b) => {
            return a.displayName.localeCompare(b.displayName)
          })

          setVisuals(_sortedVisuals)
        } else {
          resetVisuals()
        }
      })
    }
  }, [userInfo])

  useEffect(() => {
    if (visuals && visuals.length > 0) {
    }
  }, [visuals])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('profileVisual:list.title')}
        pages={[
          {
            name: t('profile:userProfile.name'),
          },
          {
            name: t('profile:visuals.name'),
          },
        ]}
        buttons={[
          {
            href: '/profile/visual',
            label: t('profileVisual:list.newVisual'),
            type: 'primary',
            onClick: handleNewVisual,
          },
        ]}
      />

      {/**
       * Steps
       */}
      <div className="container max-w-3xl mx-auto text-base p-4 mt-4 rounded-sm">
        {visuals && visuals.length > 0 ? (
          <VisualList visuals={visuals} />
        ) : (
          <NoData note={t('profileVisual:noVisuals')} />
        )}
      </div>
    </div>
  )
}

export default ProfileVisuals
