import { useEffect, useState } from 'react'
import { Button, Modal, Slider } from 'antd'
import { useRecoilState } from 'recoil'
import { currentImageState, showVisualImageDialogState, visualState } from '../../recoil/atoms'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'
import { InboxInIcon } from '@heroicons/react/outline'
import { TFileInfo } from '../../types/appTypes'

const VisualImage = () => {
  const { t } = useTranslation(['profileVisual'])

  const [visual, setVisual] = useRecoilState(visualState)
  const [currentImage, setCurrentImage] = useRecoilState(currentImageState)

  const [showVisualImageDialog, setShowVisualImageDialog] = useRecoilState(showVisualImageDialogState)

  const [imageDataUrl, setImageDataUrl] = useState<string>('')
  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)
  const [x, setX] = useState<number>(0)
  const [y, setY] = useState<number>(0)
  const [scale, setScale] = useState<number>(100)

  const handleImageOk = () => {
    const _visual = cloneDeep(visual)
    if (!_visual.images) {
      _visual.images = []
    }
    if (currentImage !== -1) {
      _visual.images[currentImage] = {
        imageDataUrl,
        width,
        height,
        x,
        y,
      }
    } else {
      _visual.images = [
        ..._visual.images,
        {
          imageDataUrl,
          width,
          height,
          x,
          y,
        },
      ]
    }
    setVisual(_visual)

    setShowVisualImageDialog(false)
  }

  const handleImageCancel = () => {
    setShowVisualImageDialog(false)
  }

  const handleImageRemove = () => {
    const _visual = cloneDeep(visual)
    _visual.images?.splice(currentImage, 1)
    setVisual(_visual)
    setCurrentImage(-1)
    setShowVisualImageDialog(false)
  }

  const handleDragOver = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    event.dataTransfer.dropEffect = 'copy'
  }

  const openImageDialog = () => {
    const ele = document.getElementById('inputImageFile')
    if (ele) ele.click()
  }

  const handleDropImage = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      loadFiles(event.dataTransfer.files).then((loadedFiles: TFileInfo[]) => {
        setImageDataUrl(loadedFiles[0].content as string)
        const ele = document.getElementById('preview')
        if (ele) {
          setWidth(ele.clientWidth)
          setHeight(ele.clientHeight)
        }
      })
    }
  }

  const handleImageSelect = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    if (event.target && event.target.files && event.target.files.length > 0) {
      loadFiles(event.target.files).then((loadedFiles: TFileInfo[]) => {
        setImageDataUrl(loadedFiles[0].content as string)
        const ele = document.getElementById('preview')
        if (ele) {
          setWidth(ele.clientWidth)
          setHeight(ele.clientHeight)
        }
      })
    }
  }

  const loadFiles = async (files: any): Promise<TFileInfo[]> => {
    let loadedFiles: TFileInfo[] = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      let promise: any = new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = (result: any) => {
          let fileInfo: TFileInfo = {
            content: result?.currentTarget?.result,
            mimeType: file.type,
            filename: file.name,
            filesize: file.size,
            lastModified: file.lastModified,
          }
          resolve(fileInfo)
        }
        reader.readAsDataURL(file)
      })

      let fileInfo = await promise
      loadedFiles = [...loadedFiles, fileInfo]
    }
    return loadedFiles
  }

  const changeSize = (value: number) => {
    const ele = document.getElementById('preview')
    if (ele) {
      ele.style.transform = `scale(${value / 100})`
      setWidth((ele.clientWidth * value) / 100)
      setHeight((ele.clientHeight * value) / 100)
    }
    setScale(value)
  }

  useEffect(() => {
    if (visual && currentImage !== -1 && visual.images && visual.images.length > 0 && visual.images[currentImage]) {
      setImageDataUrl(visual.images[currentImage].imageDataUrl || '')
      setWidth(visual.images[currentImage].width)
      setHeight(visual.images[currentImage].height)
      setX(visual.images[currentImage].x)
      setY(visual.images[currentImage].y)
    } else {
      setImageDataUrl('')
      setWidth(0)
      setHeight(0)
      setX(0)
      setY(0)
      setScale(100)
    }
  }, [visual, currentImage, showVisualImageDialog])

  return (
    <Modal
      title={t('profileVisual:image.title')}
      visible={showVisualImageDialog}
      footer={
        currentImage === -1
          ? [
              <Button className="rounded font-semibold" key="cancel" onClick={handleImageCancel}>
                {t('profileVisual:image.cancel')}
              </Button>,
              <Button className="rounded font-semibold" key="apply" type="primary" onClick={handleImageOk}>
                {t('profileVisual:image.apply')}
              </Button>,
            ]
          : [
              <Button
                className="rounded font-semibold"
                type="primary"
                danger={true}
                key="remove"
                onClick={handleImageRemove}
              >
                {t('profileVisual:image.remove')}
              </Button>,
              <Button className="rounded font-semibold" key="cancel" onClick={handleImageCancel}>
                {t('profileVisual:image.cancel')}
              </Button>,
              <Button className="rounded font-semibold" key="apply" type="primary" onClick={handleImageOk}>
                {t('profileVisual:image.apply')}
              </Button>,
            ]
      }
    >
      <div className="mt-2">
        {/**
         * Drop zone
         */}
        <p className="mt-2 font-semibold">{t('profileVisual:background.upload')}</p>
        <div className="w-full my-4">
          <div
            className="relative cursor-pointer p-4 border-2 border-dotted flex flex-col content-center items-center justify-center"
            onDragOver={handleDragOver}
            onDrop={handleDropImage}
            onClick={openImageDialog}
          >
            <InboxInIcon className="h-5 w-5 text-antd-blue" />
            <p className="text-base text-center mt-2">{t('profileVisual:background.uploadInstructions')}</p>
            <p className="text-sm mt-2">{t('profileVisual:background.picturesOnly')}</p>
            <input
              type="file"
              id="inputImageFile"
              accept="image/*"
              multiple={false}
              onChange={handleImageSelect}
              hidden
            />
          </div>
        </div>

        <div className="w-full my-4">
          <Slider min={1} max={150} step={1} value={scale} onChange={(value: number) => changeSize(value)} />
        </div>

        {/**
         * Preview
         */}
        <div className="w-full my-4">
          <p className="mt-2 font-semibold">{t('profileVisual:background.preview')}</p>
          {imageDataUrl ? <img id="preview" src={imageDataUrl} className="h-auto w-full mt-2" alt="preview" /> : null}
        </div>
      </div>
    </Modal>
  )
}

export default VisualImage
