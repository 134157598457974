import { IProcess, TProcessStatus } from '@axelity/actasign-sic'
import RequestItem from './RequestItem'

type RequestListProps = {
  requests: IProcess[]
  status?: TProcessStatus[] | undefined
}

const RequestList = ({ requests, status }: RequestListProps) => {
  let filteredRequests: IProcess[] = []
  let tempRequests: IProcess[] = []

  if (status) {
    tempRequests = requests.filter((prozess) => {
      return status.includes(prozess.status)
    })
  } else {
    tempRequests = [...requests]
  }

  tempRequests = tempRequests.filter((prozess) => {
    return prozess.status !== 'CLOSED'
  })

  // tempRequests = tempRequests.filter((_request) => {
  //   const retainUntil = new Date(_request.retainUntil).getTime()
  //   const now = new Date().getTime()
  //   return now < retainUntil
  // })

  // Sort decending
  tempRequests = tempRequests.sort((a, b) => {
    const aDate = new Date(a.created).getTime()
    const bDate = new Date(b.created).getTime()
    return bDate - aDate
  })

  filteredRequests = [...tempRequests]
  
  return (
    <div>
      {filteredRequests.map((request) => {
        return <RequestItem key={request._id} request={request} />
      })}
    </div>
  )
}

export default RequestList
