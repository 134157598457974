import { TVisualImage } from '../../types/appTypes'
import { useSetRecoilState } from 'recoil'
import { currentImageState, dragObjectShiftState, showVisualImageDialogState } from '../../recoil/atoms'
import { cloneDeep } from 'lodash'

type ImageItemProps = {
  imageNo: number
  image: TVisualImage
}

const ImageItem = (props: ImageItemProps) => {
  const setCurrentImage = useSetRecoilState(currentImageState)
  const setShowVisualImageDialog = useSetRecoilState(showVisualImageDialogState)

  // const visual = useRecoilValue(visualState)
  const setShift = useSetRecoilState(dragObjectShiftState)

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement

    // Determine the shift from left and top of the mouse pointer when start dragging
    setShift({
      shiftX: e.clientX - target.getBoundingClientRect().left,
      shiftY: e.clientY - target.getBoundingClientRect().top,
    })

    // Set information (image incl. index) that has to be dragged
    // This is consumed on VisualPreview
    const _props = cloneDeep(props) as any
    _props.type = 'image'

    e.dataTransfer.setData('text/plain', JSON.stringify(_props))
    e.dataTransfer.setDragImage(
      target,
      e.clientX - target.getBoundingClientRect().left,
      e.clientY - target.getBoundingClientRect().top,
    )
  }

  const handleDoubleClick = () => {
    setCurrentImage(props.imageNo)
    setShowVisualImageDialog(true)
  }

  return (
    <img
      id={`image_${props.imageNo}`}
      key={`image_${props.imageNo}`}
      draggable={true}
      style={{
        position: 'absolute',
        left: `${props.image.x}px`,
        top: `${props.image.y}px`,
        width: `${props.image.width}px`,
        height: `${props.image.height}px`,
      }}
      src={props.image.imageDataUrl}
      className="object-scale-down hover:cursor-move z-20"
      onDragStart={handleDragStart}
      onDoubleClick={handleDoubleClick}
      alt={`image_${props.imageNo}`}
    />
  )
}

export default ImageItem
