import { Switch, Route } from 'react-router-dom'
import SignUp from './pages/SignUp'
import SignIn from './pages/SignIn'
import LandingPage from './pages/LandingPage'
import ProfileBasic from './pages/ProfileBasic'
import ProfileEmail from './pages/ProfileEmail'
import ProfileMobile from './pages/ProfileMobile'
import ProfileTOTP from './pages/ProfileTOTP'
import ProfilePassword from './pages/ProfilePassword'
import ProfileOtherSettings from './pages/ProfileOtherSettings'
// import ProfileMagicLink from './pages/ProfileMagicLink'
import ProfileAccounts from './pages/ProfileAccounts'
// import SettingsAccount from './pages/SettingsAccount'
import SettingsBranding from './pages/SettingsBranding'
// import SettingsRedirect from './pages/SettingsRedirect'
import SettingsEmailGateway from './pages/SettingsEmailGateway'
// import SettingsSwisscomAIS from './pages/SettingsSwisscomAIS'
import SettingsAccountUsers from './pages/SettingsAccountUsers'
import Request from './pages/Request'
import Tasks from './pages/Tasks'
import Task from './pages/Task'
import Requests from './pages/Requests'
import ProfileVisuals from './pages/ProfileVisuals'
import ProfileVisual from './pages/ProfileVisual'
import Viewer from './pages/Viewer'
import Consent from './pages/Consent'
import SettingsApiKeys from './pages/SettingsApiKeys'
import SettingsApiKey from './pages/SettingsApiKey'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import { useSetRecoilState } from 'recoil'
import { userLanguageState } from './recoil/atoms'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const App = () => {
  const { i18n } = useTranslation()

  const setUserLanguage = useSetRecoilState(userLanguageState)

  useEffect(() => {
    setUserLanguage(i18n.language.substr(0,2))
  })

  return (
    <main>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/signup" component={SignUp} />
        <Route path="/signin" component={SignIn} />
        <Route path="/home" component={Home} />
        <Route path="/authorize" component={Home} />
        <Route path="/profile/basic" component={ProfileBasic} />
        <Route path="/profile/email" component={ProfileEmail} />
        <Route path="/profile/mobile" component={ProfileMobile} />
        <Route path="/profile/totp" component={ProfileTOTP} />
        <Route path="/profile/password" component={ProfilePassword} />
        <Route path="/profile/other_settings" component={ProfileOtherSettings} />
        {/* <Route path="/profile/evidence_value" component={ProfileEvidenceValue} /> */}
        <Route path="/profile/visuals" component={ProfileVisuals} />
        <Route path="/profile/visual" component={ProfileVisual} />
        {/* <Route path="/profile/magic_link" component={ProfileMagicLink} /> */}
        <Route path="/profile/accounts" component={ProfileAccounts} />
        {/* <Route exact path="/settings/account" component={SettingsAccount} /> */}
        <Route path="/settings/account/users" component={SettingsAccountUsers} />
        <Route path="/settings/branding" component={SettingsBranding} />
        {/* <Route path="/settings/redirect" component={SettingsRedirect} /> */}
        <Route path="/settings/email_gateway" component={SettingsEmailGateway} />
        {/* <Route path="/settings/swisscom_ais" component={SettingsSwisscomAIS} /> */}
        <Route path="/settings/apikeys" component={SettingsApiKeys} />
        <Route exact path="/settings/apikey" component={SettingsApiKey} />
        <Route path="/requests" component={Requests} />
        <Route path="/request" component={Request} />
        <Route path="/tasks" component={Tasks} />
        <Route path="/task" component={Task} />
        <Route path="/viewer" component={Viewer} />
        <Route path="/consent" component={Consent} />
        <Route path="*" component={NotFound} />
      </Switch>
    </main>
  )
}

export default App
