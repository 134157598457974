import { ITask, TTaskStatus } from '@axelity/actasign-sic'
import TaskItem from './TaskItem'

type TaskListProps = {
  tasks: ITask[]
  status?: TTaskStatus[] | undefined
}

const TaskList = ({ tasks, status }: TaskListProps) => {
  let filteredTasks: ITask[] = []
  let tempTasks: ITask[] = []

  if (status) {
    tempTasks = tasks.filter((_task) => {
      return status.includes(_task.status)
    })
  } else {
    tempTasks = [...tasks]
  }

  tempTasks = tempTasks.filter((_task) => {
    return _task.status !== 'CLOSED'
  })

  // tempTasks = tempTasks.filter((_task) => {
  //   const retainUntil = new Date(_task.retainUntil).getTime()
  //   const now = new Date().getTime()
  //   return now < retainUntil
  // })

  // Sort decending
  tempTasks = tempTasks.sort((a, b) => {
    const aDate = new Date(a.created).getTime()
    const bDate = new Date(b.created).getTime()
    return bDate - aDate
  })

  filteredTasks = [...tempTasks]

  return (
    <div>
      {filteredTasks.map((task) => {
        return <TaskItem key={task._id} task={task} />
      })}
    </div>
  )
}

export default TaskList
