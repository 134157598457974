/* eslint-disable import/first */
/* eslint-disable react-hooks/exhaustive-deps */
import { QueryClientTokensUseCase } from '@axelity/actasign-sic'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import ApiKeyList from '../components/ApiKeyList'
import useSignIn from '../hooks/useSignIn'
import { apiKeyListState, apiKeyState, currentAccountState, userInfoState } from '../recoil/atoms'
import NoData from '../components/NoData'

const SettingsApiKeys = () => {
  const { t } = useTranslation(['apiKeys'])

  const history = useHistory()

  const [noAccessToken] = useSignIn()

  const userInfo = useRecoilValue(userInfoState)
  const currentAccount = useRecoilValue(currentAccountState)

  const [apiKeys, setApiKeys] = useRecoilState(apiKeyListState)
  const resetApiKeys = useResetRecoilState(apiKeyListState)
  const resetApiKey = useResetRecoilState(apiKeyState)

  const [isLoading, setLoading] = useState<boolean>(false)

  const handleNewApiKey = () => {
    history.push('/settings/apikey')
  }

  const updateApiKeyList = () => {
    // Query client API keys
    resetApiKeys()
    resetApiKey()

    if (userInfo && currentAccount) {
      setLoading(true)
      new QueryClientTokensUseCase()
        .query({
          account: currentAccount._id,
        })
        .then((_clientTokens) => {
          if (_clientTokens.length) {
            setApiKeys(_clientTokens)
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    updateApiKeyList()
  }, [userInfo, currentAccount])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('apiKeys:title')}
        pages={[
          {
            name: t('apiKeys:title'),
          },
        ]}
        buttons={[
          {
            label: t('apiKeys:newApiKey'),
            href: '#',
            type: 'primary',
            onClick: handleNewApiKey,
          },
        ]}
      />

      {/**
       * Tasks
       */}
      {apiKeys && apiKeys.length > 0 ? (
        <div className="container max-w-4xl mx-auto text-base bg-white p-4 mt-4 rounded-sm">
          <ApiKeyList apiKeys={apiKeys} status={['ACTIVE']} />
        </div>
      ) : null}
      {apiKeys && apiKeys.length === 0 && !isLoading ? <NoData note={t('apiKeys:noKeys')} /> : null}
    </div>
  )
}

export default SettingsApiKeys
