import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'
import { BrowserRouter as Router } from 'react-router-dom'
import 'antd/dist/antd.css'
import './index.css'
import './i18n'
import App from './App'
import { Configuration } from '@axelity/actasign-sic'
import countries from 'i18n-iso-countries'

Configuration.init({
  baseUrl: process.env.REACT_APP_BASE_URL as string,
})

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Suspense fallback="loading">
        <Router>
          <App />
        </Router>
      </Suspense>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
)
