type TTileProps = {
  title: string
  instructions?: string
  show?: boolean
  onClick?: () => void
  children?: React.ReactNode
}

const Tile = ({ title, instructions, children, show = true, onClick }: TTileProps) => {
  if (show) {
    return (
      <div
        style={{ width: '30%', height: '250px' }}
        className="w-3/10 p-4 rounded border-2 border-gray-100 shadow-sm hover:border-acs-blue-500 hover:bg-acs-blue-50 hover:cursor-pointer"
        onClick={onClick}
      >
        <h3 className="font-semibold text-xl">{title}</h3>
        {instructions ? (
          <div>
            <p className="mt-2 text-sm text-gray-700">{instructions}</p>
          </div>
        ) : null}
        {children}
      </div>
    )
  } else {
    return null
  }
}

export default Tile
