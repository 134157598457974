/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { CreateVisualUseCase, DeleteVisualUseCase, IVisual, QueryVisualsUseCase } from '@axelity/actasign-sic'
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { userInfoState, visualListState, visualState } from '../recoil/atoms'
import { useHistory } from 'react-router-dom'
import { EyeIcon, DuplicateIcon, PencilIcon, TrashIcon, ArrowCircleRightIcon } from '@heroicons/react/outline'
import SignaturePreview from '../components/SignaturePreview'
import VisualAssignment from '../components/VisualAssignment'
import { Spin } from 'antd'
import { useState } from 'react'
import { cloneDeep } from 'lodash'

type VisualItemProps = {
  visual: IVisual
}

const VisualItem = ({ visual }: VisualItemProps) => {
  const { t } = useTranslation(['visualItem'])

  const history = useHistory()

  const userInfo = useRecoilValue(userInfoState)
  const setVisual = useSetRecoilState(visualState)
  const setVisuals = useSetRecoilState(visualListState)
  const resetVisuals = useResetRecoilState(visualListState)

  const [inProgress, setInProgress] = useState<boolean>(false)
  const [showVisualAssignment, setShowVisualAssignment] = useState<boolean>(false)

  const handleEdit = () => {
    setVisual(visual)
    history.push('/profile/visual')
  }

  const handleCopy = () => {
    setInProgress(true)
    const newVisual = cloneDeep(visual)
    newVisual._id = ''
    newVisual.created = new Date()
    newVisual.updated = new Date()
    newVisual.displayName = `${visual.displayName} ${t('visualItem:copy')}`
    newVisual.user = userInfo._id

    new CreateVisualUseCase()
      .create({
        availability: 'USER',
        background: newVisual.background,
        border: newVisual.border,
        displayName: newVisual.displayName,
        height: newVisual.height,
        language: newVisual.language,
        settings: newVisual.settings,
        width: newVisual.width,
        defaultReason: newVisual.defaultReason,
        fontStyles: newVisual.fontStyles,
        images: newVisual.images,
        position: newVisual.position,
        textLines: newVisual.textLines,
        visualDataUrl: newVisual.visualDataUrl,
        user: newVisual.user,
      })
      .then((_visual) => {
        new QueryVisualsUseCase().query({}).then((_visuals) => {
          if (_visuals.length) {
            const _filteredVisuals = _visuals.filter((_visual) => {
              if (
                _visual.availability === 'GLOBAL' &&
                userInfo.memberships &&
                !userInfo.memberships[0].roles.includes('MASTER-ADMIN')
              ) {
                return false
              } else {
                return _visual.status !== 'CLOSED'
              }
            })

            const _sortedVisuals = _filteredVisuals.sort((a, b) => {
              return a.displayName.localeCompare(b.displayName)
            })

            setVisuals(_sortedVisuals)
          } else {
            resetVisuals()
          }
        })
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  const handleRemove = () => {
    setInProgress(true)

    new DeleteVisualUseCase({
      visualId: visual._id,
    })
      .delete()
      .then(() => {
        new QueryVisualsUseCase().query({}).then((_visuals) => {
          if (_visuals.length) {
            const _filteredVisuals = _visuals.filter((_visual) => {
              if (
                _visual.availability === 'GLOBAL' &&
                userInfo.memberships &&
                !userInfo.memberships[0].roles.includes('MASTER-ADMIN')
              ) {
                return false
              } else {
                return _visual.status !== 'CLOSED'
              }
            })

            const _sortedVisuals = _filteredVisuals.sort((a, b) => {
              return a.displayName.localeCompare(b.displayName)
            })

            setVisuals(_sortedVisuals)
          } else {
            resetVisuals()
          }
        })
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  const toggleVisualAssignment = () => {
    setShowVisualAssignment(!showVisualAssignment)
  }

  const handleExecuteAssignment = () => {
    setShowVisualAssignment(false)

    new QueryVisualsUseCase().query({}).then((_visuals) => {
      if (_visuals.length) {
        const _filteredVisuals = _visuals.filter((_visual) => {
          if (
            _visual.availability === 'GLOBAL' &&
            userInfo.memberships &&
            !userInfo.memberships[0].roles.includes('MASTER-ADMIN')
          ) {
            return false
          } else {
            return _visual.status !== 'CLOSED'
          }
        })

        const _sortedVisuals = _filteredVisuals.sort((a, b) => {
          return a.displayName.localeCompare(b.displayName)
        })

        setVisuals(_sortedVisuals)
      } else {
        resetVisuals()
      }
    })
  }

  const handleCancelAssignment = () => {
    setShowVisualAssignment(false)
  }

  return (
    <div className="container my-2 border-2 shadow-sm rounded-md border-gray-200 hover:bg-gray-50 hover:border-acs-blue-600 hover:cursor-pointer">
      <Spin spinning={inProgress} size="large">
        <div className="flex justify-start p-2">
          <EyeIcon className="w-7 h-7 text-acs-blue-600" />
          <div className="ml-5 w-full">
            <div className="flex justify-between items-start">
              <div className="truncate text-md font-semibold">{visual.displayName}</div>
              <div className="w-52">
                <SignaturePreview visual={visual} />
              </div>
            </div>
          </div>
        </div>

        {/**
         * Actions
         */}
        <div className="flex justify-end rounded-b-md w-full pr-2 bg-acs-blue-50">
          <div
            className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
            onClick={handleCopy}
          >
            <DuplicateIcon className="w-4 h-4 mr-1" />
            {t('visualItem:action.copy')}
          </div>

          {visual.user === userInfo._id && userInfo.memberships && userInfo.memberships[0].plan !== 'PARTNER' ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={toggleVisualAssignment}
            >
              <ArrowCircleRightIcon className="w-4 h-4 mr-1" />
              {t('visualItem:action.assign')}
            </div>
          ) : null}

          {visual.user === userInfo._id ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleEdit}
            >
              <PencilIcon className="w-4 h-4 mr-1" />
              {t('visualItem:action.edit')}
            </div>
          ) : null}

          {visual.user === userInfo._id ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleRemove}
            >
              <TrashIcon className="w-4 h-4 mr-1" />
              {t('visualItem:action.remove')}
            </div>
          ) : null}
        </div>
      </Spin>

      {showVisualAssignment ? (
        <VisualAssignment onAssign={handleExecuteAssignment} onCancel={handleCancelAssignment} visual={visual} />
      ) : null}
    </div>
  )
}

export default VisualItem
