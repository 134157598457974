/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { ClipboardListIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import {
  DeleteActivityUseCase,
  DeleteTaskUseCase,
  IActivity,
  ITask,
  QueryProcessesUseCase,
} from '@axelity/actasign-sic'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  activityListState,
  activityState,
  requestState,
  showRequestActivityDialogState,
  taskListState,
} from '../recoil/atoms'
import { useEffect, useState } from 'react'
import { Spin, message } from 'antd'

type ActivityItemProps = {
  activity: IActivity
  canChange?: boolean
  canDelete?: boolean
}

const ActivityItem = ({ activity, canChange = false, canDelete = false }: ActivityItemProps) => {
  const { t } = useTranslation(['activityList'])

  const setRequest = useSetRecoilState(requestState)
  const [activities, setActivities] = useRecoilState(activityListState)
  const setActivity = useSetRecoilState(activityState)
  const setShowRequestActivityDialog = useSetRecoilState(showRequestActivityDialogState)

  const requestTasks = useRecoilValue(taskListState)
  const resetRequestTasks = useResetRecoilState(taskListState)
  const [taskList, setTaskList] = useState<ITask[]>([])
  const [inProgress, setInProgress] = useState<boolean>(false)

  const handleChangeActivity = (e: React.MouseEvent) => {
    setActivity(activity)
    setShowRequestActivityDialog(true)
  }

  const handleRemoveActivity = (e: React.MouseEvent) => {
    setInProgress(true)
    new DeleteActivityUseCase({
      activityId: activity._id,
    })
      .delete()
      .then(() => {
        const _activities = activities.filter((_activity) => {
          return _activity._id !== activity._id
        })
        setActivities(_activities)
      })
      .then(() => {
        for (let i = 0, p = Promise.resolve(); i < requestTasks.length; i++) {
          if (requestTasks[i].activity === activity._id) {
            p = p.then(
              (_) =>
                new Promise((resolve) => {
                  new DeleteTaskUseCase({
                    taskId: requestTasks[i]._id,
                  })
                    .delete()
                    .then(() => {
                      resolve()
                    })
                    .catch((error) => {
                      console.error(error)
                    })
                }),
            )
          }
        }
        resetRequestTasks()
      })
      .then(() => {
        return new QueryProcessesUseCase().query({
          id: activity.process as string,
        })
      })
      .then((_requests) => {
        setInProgress(false)
        setRequest(_requests[0])
      })
      .catch((error) => {
        setInProgress(false)
        console.error(error)
        message.error(t('activityList:error.unexpected'))
      })
  }

  useEffect(() => {
    if (requestTasks && requestTasks.length > 0) {
      setTaskList(
        requestTasks.filter((task) => {
          return task.activity === activity._id
        }),
      )
    }
  }, [requestTasks])

  return (
    <div className="processActivity container mb-2 border-2 shadow-sm rounded-md bg-white border-gray-100 hover:bg-gray-50 hover:border-acs-blue-600">
      <Spin tip={t('activityList:action.loading')} spinning={inProgress} size="large">
        <div className="flex justify-start p-2 rounded-t-md">
          <ClipboardListIcon className="w-7 h-7 text-acs-blue-600" />

          <div className="ml-5 w-full">
            <div className="flex w-full justify-between items-center">
              {/**
               * activity recipients
               */}
              <div className="flex flex-row flex-wrap w-full">
                {taskList.map((task) => {
                  return (
                    <div
                      key={task._id}
                      className="flex flex-row flex-nowrap rounded-lg px-2 m-1 items-center text-xs font-medium bg-acs-blue-100 text-acs-blue-600"
                    >
                      <span className="whitespace-nowrap">{`${task.userInfo.firstName} ${task.userInfo.familyName} (${task.userInfo.emailAddress})`}</span>
                    </div>
                  )
                })}
              </div>

              {/**
               * Activity type
               */}
              <div className="flex justify-end">
                <div className="rounded-md ml-2 px-2 py-1 text-xs font-semibold whitespace-nowrap bg-green-100 text-green-700">
                  {t(`activityList:activityType.${activity.activityType.toLowerCase()}`)}
                </div>
              </div>
            </div>

            {/**
             * Message
             */}
            <div className="text-xs m-1 pt-2 text-gray-500">{activity.messages?.initial || ''}</div>
          </div>
        </div>

        {/**
         * Actions
         */}
        <div className="flex justify-end w-full pr-2 rounded-b-md bg-acs-blue-50">
          {canChange ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleChangeActivity}
            >
              <PencilIcon className="w-4 h-4 mr-1" />
              {t('activityList:action.edit')}
            </div>
          ) : null}

          {canDelete ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleRemoveActivity}
            >
              <TrashIcon className="w-4 h-4 mr-1" />
              {t('activityList:action.remove')}
            </div>
          ) : null}
        </div>
      </Spin>
    </div>
  )
}

export default ActivityItem
