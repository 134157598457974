import {
  IoRibbonOutline,
  IoBanOutline,
  IoArrowRedoOutline,
  IoCheckboxOutline,
  IoChatboxEllipsesOutline,
  IoCaretForwardCircleOutline,
  IoPauseCircleOutline,
  IoStopCircleOutline,
  IoTimeOutline,
  IoColorPaletteOutline,
  IoTextOutline,
  IoImageOutline,
  IoColorFillOutline,
  IoDownloadOutline,
} from 'react-icons/io5'
import { BiBorderOuter } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { FunctionComponent, useEffect, useState } from 'react'

type TActionRibbon = {
  showSign?: boolean
  onSignClick?: () => void
  showComplete?: boolean
  onCompleteClick?: () => void
  showDecline?: boolean
  onDeclineClick?: () => void
  showReassign?: boolean
  onReassignClick?: () => void
  showComment?: boolean
  onCommentClick?: () => void
  showStart?: boolean
  onStartClick?: () => void
  showSuspend?: boolean
  onSuspendClick?: () => void
  showContinue?: boolean
  onContinueClick?: () => void
  showCancel?: boolean
  onCancelClick?: () => void
  showTimeline?: boolean
  onTimelineClick?: () => void
  showFontStyle?: boolean
  onFontStyleClick?: () => void
  showBackground?: boolean
  onBackgroundClick?: () => void
  showBorder?: boolean
  onBorderClick?: () => void
  showAddImage?: boolean
  onAddImageClick?: () => void
  showAddText?: boolean
  onAddTextClick?: () => void
  showDownload?: boolean
  onDownloadClick?: () => void
}

type TActionRibbonItemProps = {
  show?: boolean
  label: string
  clickAction?: () => void
  children?: React.ReactNode
}

const ActionRibbonItem: FunctionComponent<TActionRibbonItemProps> = (props: TActionRibbonItemProps) => {
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    setShow(props.show)
  }, [props.show])

  return (
    <div>
      {show ? (
        <div
          className="flex flex-col justify-between text-center items-center hover:cursor-pointer w-24 h-20 p-1 text-gray-700 hover:text-acs-blue-700 hover:bg-acs-blue-100"
          onClick={props.clickAction}
        >
          {props.children}
          <p className="my-1 text-xs font-medium">{props.label}</p>
        </div>
      ) : null}
    </div>
  )
}

const ActionRibbon = (props: TActionRibbon) => {
  const { t } = useTranslation(['actionRibbon'])

  const [showSign, setShowSign] = useState<boolean>(props.showSign)
  const [showComplete, setShowComplete] = useState<boolean>(props.showComplete)
  const [showDecline, setShowDecline] = useState<boolean>(props.showDecline)
  const [showReassign, setShowReassign] = useState<boolean>(props.showReassign)

  useEffect(() => {
    setShowSign(props.showSign)
    setShowComplete(props.showComplete)
    setShowDecline(props.showDecline)
    setShowReassign(props.showReassign)
  }, [props.showSign, props.showDecline, props.showComplete, props.showReassign])

  return (
    <div className="flex w-full justify-start bg-acs-blue-50">
      <ActionRibbonItem show={showSign} label={t('actionRibbon:sign')} clickAction={props.onSignClick}>
        <IoRibbonOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem show={showComplete} label={t('actionRibbon:complete')} clickAction={props.onCompleteClick}>
        <IoCheckboxOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem show={showDecline} label={t('actionRibbon:decline')} clickAction={props.onDeclineClick}>
        <IoBanOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem show={showReassign} label={t('actionRibbon:reassign')} clickAction={props.onReassignClick}>
        <IoArrowRedoOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem show={props.showComment} label={t('actionRibbon:comment')} clickAction={props.onCommentClick}>
        <IoChatboxEllipsesOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem show={props.showStart} label={t('actionRibbon:start')} clickAction={props.onStartClick}>
        <IoCaretForwardCircleOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem show={props.showStart} label={t('actionRibbon:suspend')} clickAction={props.onSuspendClick}>
        <IoPauseCircleOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem
        show={props.showContinue}
        label={t('actionRibbon:continue')}
        clickAction={props.onContinueClick}
      >
        <IoCaretForwardCircleOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem show={props.showCancel} label={t('actionRibbon:cancel')} clickAction={props.onCancelClick}>
        <IoStopCircleOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem
        show={props.showTimeline}
        label={t('actionRibbon:timeline')}
        clickAction={props.onTimelineClick}
      >
        <IoTimeOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem
        show={props.showFontStyle}
        label={t('actionRibbon:fontStyle')}
        clickAction={props.onFontStyleClick}
      >
        <IoColorPaletteOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem
        show={props.showBackground}
        label={t('actionRibbon:background')}
        clickAction={props.onBackgroundClick}
      >
        <IoColorFillOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem show={props.showBorder} label={t('actionRibbon:border')} clickAction={props.onBorderClick}>
        <BiBorderOuter className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem
        show={props.showAddImage}
        label={t('actionRibbon:addImage')}
        clickAction={props.onAddImageClick}
      >
        <IoImageOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem show={props.showAddText} label={t('actionRibbon:addText')} clickAction={props.onAddTextClick}>
        <IoTextOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>

      <ActionRibbonItem
        show={props.showDownload}
        label={t('actionRibbon:download')}
        clickAction={props.onDownloadClick}
      >
        <IoDownloadOutline className="mt-2 w-7 h-7" />
      </ActionRibbonItem>
    </div>
  )
}

export default ActionRibbon
