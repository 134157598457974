/* eslint-disable import/first */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  accessTokenState,
  activityListState,
  currentTaskUserState,
  documentListState,
  fastSignatureState,
  requestState,
  showTaskUserDialogState,
  signatureListState,
  taskListState,
  taskState,
  userInfoState,
  viewerAddressedByState,
  viewerState,
  processingInviteTaskState,
} from '../recoil/atoms'
import { useEffect } from 'react'
import { useState } from 'react'
import {
  CreateConsentUseCase,
  DeclineTaskUseCase,
  IActivity,
  IComment,
  IDocument,
  ISignature,
  ITask,
  QueryActivitiesUseCase,
  QueryCommentsUseCase,
  QueryDocumentsUseCase,
  QueryProcessesUseCase,
  QuerySignaturesUseCase,
  QueryTasksUseCase,
  StartProcessUseCase,
  SupercedeTaskUseCase,
} from '@axelity/actasign-sic'
import DocumentList from '../components/DocumentList'
import ActionRibbon from '../components/ActionRibbon'
import { Spin, Modal, Input, Comment, message, Alert } from 'antd'
import FieldTitle from '../elements/FieldTitle'
import useSignIn from '../hooks/useSignIn'
import SectionTitle from '../elements/SectionTitle'
import TaskUser from '../components/TaskUser'
import { ClockIcon } from '@heroicons/react/outline'

const { TextArea } = Input

const Task = () => {
  const { t } = useTranslation(['task'])

  const search = useLocation().search
  // const authMethod = new URLSearchParams(search).get('auth_method')
  const taskId = new URLSearchParams(search).get('task')
  const queryAccessToken = new URLSearchParams(search).get('access_token')
  const queryFastSignature = new URLSearchParams(search).get('fast_signature')

  const [noAccessToken] = useSignIn()

  const history = useHistory()

  const userInfo = useRecoilValue(userInfoState)
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState)
  const [request, setRequest] = useRecoilState(requestState)
  const resetRequest = useResetRecoilState(requestState)
  const resetTask = useResetRecoilState(taskState)
  const [task, setTask] = useRecoilState(taskState)
  const setTaskList = useSetRecoilState(taskListState)
  const setActivityList = useSetRecoilState(activityListState)
  const [fastSignature, setFastSignature] = useRecoilState(fastSignatureState)
  const setViewerState = useSetRecoilState(viewerState)
  const setViewerAddressedBy = useSetRecoilState(viewerAddressedByState)
  const currentTaskUser = useRecoilValue(currentTaskUserState)
  const [showTaskUserDialog, setShowTaskUserDialog] = useRecoilState(showTaskUserDialogState)
  const resetAccessToken = useResetRecoilState(accessTokenState)
  const resetUserInfo = useResetRecoilState(userInfoState)
  const [processingInviteTask, setProcessingInviteTask] = useRecoilState(processingInviteTaskState)

  const setDocumentList = useSetRecoilState(documentListState)
  const [signatureList, setSignatureList] = useRecoilState(signatureListState)

  const [isLoading, setLoading] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [created, setCreated] = useState<string>('')
  const [sender, setSender] = useState<string>('')
  const [dueOn, setDueOn] = useState<string>('')
  const [taskMessage, setTaskMessage] = useState<string>('')
  const [declineReason, setDeclineReason] = useState<string>('')
  const [canEdit, setEdit] = useState<boolean>(false)
  const [comments, setComments] = useState<IComment[]>([])

  const [showDeclineReasonDialog, setShowDeclineReasonDialog] = useState<boolean>(false)

  const [doReassign, setDoReassign] = useState<boolean>(false)
  //const [doAddUserTask, setAddUserTask] = useState<boolean>(false)

  const [showActionSign, setShowActionSign] = useState<boolean>(false)
  const [showActionComplete, setShowActionComplete] = useState<boolean>(false)
  const [showActionDecline, setShowActionDecline] = useState<boolean>(false)
  const [showActionReassign, setShowActionReassign] = useState<boolean>(false)

  const retrieveData = async (): Promise<void> => {
    const _requests = await new QueryProcessesUseCase().query({
      id: task.process as string,
    })
    if (_requests && _requests.length > 0) {
      setRequest(_requests[0])
    }

    let _activities: IActivity[] = []
    _activities = await new QueryActivitiesUseCase().query({
      process: task.process as string,
    })
    if (_activities && _activities.length > 0) {
      setActivityList(_activities)
    }

    let _tasks: ITask[] = []
    _tasks = await new QueryTasksUseCase().query({
      process: _requests[0]._id,
    })
    if (_tasks && _tasks.length > 0) {
      setTaskList(_tasks)
    }

    let _documents: IDocument[] = []
    _documents = await new QueryDocumentsUseCase().query({
      process: _requests[0]._id,
    })
    if (_documents && _documents.length > 0) {
      setDocumentList(_documents)
    }

    let _signatures: ISignature[] = []
    _signatures = await new QuerySignaturesUseCase().query({
      task: task._id,
    })
    if (_signatures && _signatures.length > 0) {
      setSignatureList(_signatures)
    }

    let _comments: IComment[] = []
    _comments = await new QueryCommentsUseCase().query({
      process: _requests[0]._id,
    })
    setComments(_comments)
  }

  const handleBack = () => {
    resetRequest()
    if (processingInviteTask) {
      resetUserInfo()
      resetAccessToken()
      sessionStorage.removeItem('pivt')
      history.push('/')
    } else {
      history.push('/tasks')
    }
  }

  const handleSign = async () => {
    // Fix 2022-08-29: Check if signatures are available
    if (task && (!signatureList || signatureList.length < 1)) {
      message.error(t('task:error.incomplete'))
      return
    }

    if (fastSignature) {
      setLoading(true)
      if (request.status === 'PENDING') {
        await new StartProcessUseCase({
          processId: request._id,
        }).start()
      }
      setLoading(false)
    }

    history.push('/consent')
  }

  const handleComplete = async () => {
    setLoading(true)
    await new CreateConsentUseCase().create({
      tasks: [task._id],
      user: userInfo._id,
    })

    resetTask()
    setLoading(false)

    if (processingInviteTask) {
      resetUserInfo()
      resetAccessToken()
      sessionStorage.removeItem('pivt')
      history.push('/')
    } else {
      history.push('/tasks')
    }
  }

  const handleDecline = async () => {
    const _reason = declineReason.trim()
    if (!_reason) {
      message.error(t('task:error.noDeclineReason'))
    } else {
      setShowDeclineReasonDialog(false)
      setLoading(true)
      await new DeclineTaskUseCase({
        taskId: task._id,
      }).decline({
        comment: _reason,
      })
      setLoading(false)
      if (processingInviteTask) {
        resetUserInfo()
        resetAccessToken()
        sessionStorage.removeItem('pivt')
        history.push('/')
      } else {
        history.push('/tasks')
      }
    }
  }

  const handleReassign = () => {
    setDoReassign(true)
    setShowTaskUserDialog(true)
  }

  useEffect(() => {
    if (currentTaskUser) {
      if (doReassign) {
        new SupercedeTaskUseCase({
          taskId: task._id,
        })
          .supercede({
            user: currentTaskUser._id,
          })
          .then(() => {
            if (processingInviteTask) {
              resetUserInfo()
              resetAccessToken()
              sessionStorage.removeItem('pivt')
              history.push('/')
            } else {
              history.push('/tasks')
            }
          })
        // } else if (doAddUserTask) {
        // TODO: implement
      }
    }
  }, [currentTaskUser])

  useEffect(() => {
    if (request) {
      setTitle(request.title)
    }
  }, [request])

  useEffect(() => {
    if (task) {
      if (fastSignature) {
        setEdit(true)
      }

      setCreated(
        Intl.DateTimeFormat(task.userInfo.language, { dateStyle: 'medium', timeStyle: 'short' }).format(
          new Date(`${task.created}`),
        ),
      )

      setDueOn(
        Intl.DateTimeFormat(task.userInfo.language, { dateStyle: 'medium', timeStyle: 'short' }).format(
          new Date(`${task.dueOn}`),
        ),
      )

      setSender(`${task.assignerInfo.firstName} ${task.assignerInfo.familyName} (${task.assignerInfo.emailAddress})`)
      setTaskMessage(task.messages?.initial || '')

      setLoading(true)

      if (task.activityType === 'SIGN' && (task.status === 'ACTIVE' || task.status === 'PENDING')) {
        setShowActionSign(true)
      }

      if (task.activityType !== 'SIGN' && (task.status === 'ACTIVE' || task.status === 'PENDING')) {
        setShowActionComplete(true)
      }

      if (task.status === 'ACTIVE') {
        setShowActionDecline(true)
        setShowActionReassign(true)
      }

      retrieveData()
        .then(() => {
          //console.debug('Data retrieved successfully')
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [task])

  useEffect(() => {
    if (queryAccessToken) {
      setAccessToken(queryAccessToken)
    }
  }, [queryAccessToken])

  useEffect(() => {
    if (queryFastSignature && queryFastSignature === 'true') {
      setEdit(true)
      setFastSignature('MULTIPLE')
      setViewerState('CHANGE')
    }
  }, [queryFastSignature])

  useEffect(() => {
    if (accessToken && taskId) {
      new QueryTasksUseCase()
        .query({
          id: taskId,
        })
        .then((_tasks) => {
          if (_tasks && _tasks.length > 0) {
            setTask(_tasks[0])
            setProcessingInviteTask(true)
            sessionStorage.setItem('pivt', 'true')
            history.replace('/task')
          } else {
            console.warn(`Task ${taskId} could not be found`)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      if (sessionStorage.getItem('pivt') === 'true') {
        setProcessingInviteTask(true)
      }
    }
  }, [accessToken])

  useEffect(() => {
    if (noAccessToken && !taskId) history.replace('/signin')
  }, [noAccessToken])

  useEffect(() => {
    // This is because history.goBack() is not working with other Browsers than Chrome
    setViewerAddressedBy('/task')
  }, [])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={title}
        pages={[
          {
            name: t('task:title'),
          },
        ]}
        buttons={[
          {
            label: t('task:action.back'),
            type: 'ghost',
            href: '#',
            onClick: handleBack,
          },
        ]}
      />

      <Modal
        title={t('task:decline.title')}
        visible={showDeclineReasonDialog}
        onOk={handleDecline}
        onCancel={() => setShowDeclineReasonDialog(false)}
        okText={t('task:decline.apply')}
        cancelText={t('task:decline.cancel')}
      >
        <p className="my-4">{t('task:decline.instruction')}</p>
        <FieldTitle title={t('task:decline.reason')} />
        <TextArea
          className="rounded"
          size="middle"
          rows={5}
          value={declineReason}
          onChange={(e) => setDeclineReason(e.target.value)}
        ></TextArea>
      </Modal>

      {showTaskUserDialog ? <TaskUser /> : null}

      {task && task.status === 'ACTIVE' ? (
        <div className="container max-w-4xl mx-auto mt-2">
          <Alert
            className="rounded shadow-lg bg-acs-blue-100 border-acs-blue-500"
            message={<p className="font-semibold text-xl text-acs-blue-700">{t('task:alert.notice')}</p>}
            description={<p className="font-normal text-lg text-acs-blue-700">{t('task:alert.instructions')}</p>}
            showIcon
            type="info"
          />
        </div>
      ) : null}

      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 my-4 shadow-lg rounded-md">
        <Spin spinning={isLoading} size="large">
          <div>
            {task ? (
              <div className="w-full border-b">
                <ActionRibbon
                  showSign={showActionSign}
                  onSignClick={handleSign}
                  showComplete={showActionComplete}
                  onCompleteClick={handleComplete}
                  showDecline={showActionDecline}
                  onDeclineClick={() => setShowDeclineReasonDialog(true)}
                  showReassign={showActionReassign}
                  onReassignClick={handleReassign}
                />
              </div>
            ) : null}

            <div className="p-5 border-b">
              <div className="flex flex-row items-center justify-between">
                <h2 className="font-semibold text-lg">{title}</h2>
                <p className="text-sm">{created}</p>
              </div>

              <div className="flex flex-row items-center justify-between">
                <h3 className="font-semibold text-sm text-gray-500">{sender}</h3>
                <div className="flex flex-row items-center justify-start">
                  <ClockIcon className="w-4 h-4 text-gray-500" />
                  <p className="ml-2 text-sm">{dueOn}</p>
                </div>
              </div>
            </div>

            {comments && comments.length > 0 ? (
              <div className="p-5 border-b">
                <SectionTitle title={t('task:comments')} />
                {comments.map((taskComment) => {
                  return (
                    <Comment key={taskComment._id} author={taskComment.userFullname} content={taskComment.comment} />
                  )
                })}
              </div>
            ) : null}

            {taskMessage ? (
              <div className="p-5 border-b">
                <SectionTitle title={t('task:message')} />

                {taskMessage.split('\n').map((item, key) => {
                  return (
                    <span key={key} className="text-sm">
                      {item}
                      <br />
                    </span>
                  )
                })}
              </div>
            ) : null}

            <div className="w-full p-5">
              <DocumentList
                canView={true}
                canDownload={!canEdit}
                canPrepare={canEdit}
                canRemove={canEdit}
                showStatus={task && task.status !== 'ACTIVE'}
              />
            </div>
          </div>
        </Spin>
      </div>
    </div>
  )
}

export default Task
