const LandingPage = () => {

  return (
    <main>
      <div className="flex flex-col items-center">
        <div className="flex justify-right mt-20">
          <span className="text-8xl font-light">acta</span>
          <span className="text-8xl font-bold">SIGN</span>
          <span className="text-xl font-light">&copy;</span>
          <span className="text-8xl font-bold pl-2">ONE</span>
        </div>
        <div className="mt-20 w-6/12 flex flex-row justify-around w-3xl">
            <img style={{ width: '80%' }} src={`${process.env.PUBLIC_URL}/assets/images/notfound.svg`} alt="empty" />
        </div>
      </div>
    </main>
  )
}

export default LandingPage
