import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip, Skeleton, Spin } from 'antd'
import { EyeIcon, TrashIcon, PencilIcon, DownloadIcon } from '@heroicons/react/outline'
import {
  DeleteDocumentUseCase,
  DeleteSignatureUseCase,
  DownloadDocumentUseCase,
  IDocument,
  IRenderDocumentPagesResponse,
  QuerySignaturesUseCase,
  RenderDocumentPagesUseCase,
} from '@axelity/actasign-sic'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { documentListState, documentState, signatureListState, userInfoState, viewerState } from '../recoil/atoms'

type DocumentItemProps = {
  content?: IDocument
  canPrepare?: boolean
  canView?: boolean
  canRemove?: boolean
  canDownload?: boolean
  showStatus?: boolean
}

type TDocumentItemActionProps = {
  show?: boolean
  label: string
  clickAction?: () => void
  icon?: React.ReactNode
}

const DocumentItemAction: React.FunctionComponent<TDocumentItemActionProps> = ({
  label,
  clickAction,
  icon,
  show,
}: TDocumentItemActionProps) => {
  if (show) {
    return (
      <div
        className="flex flex-col items-center p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
        onClick={clickAction}
      >
        {icon}
        <p style={{ fontSize: '9px' }} className="mt-1">
          {label}
        </p>
      </div>
    )
  } else {
    return null
  }
}

const DocumentItem = ({
  content,
  canDownload = false,
  canPrepare = false,
  canRemove = false,
  canView = false,
  showStatus = true,
}: DocumentItemProps) => {
  const { t } = useTranslation(['documentItem'])

  const history = useHistory()

  const userInfo = useRecoilValue(userInfoState)

  const [documentList, setDocumentList] = useRecoilState(documentListState)
  const setRequestDocument = useSetRecoilState(documentState)
  const resetSignatures = useResetRecoilState(signatureListState)
  const setViewType = useSetRecoilState(viewerState)

  const [page, setPage] = useState<IRenderDocumentPagesResponse>(undefined)
  const [inProgress, setInProgress] = useState<boolean>(false)

  const handlePrepareDocument = () => {
    resetSignatures()
    setRequestDocument(content)
    setViewType('CHANGE')
    history.push('/viewer')
  }

  const handleViewDocument = () => {
    resetSignatures()
    setRequestDocument(content)
    if (canPrepare) {
      setViewType('CHANGE')
    } else {
      setViewType('VIEW')
    }
    history.push('/viewer')
  }

  const handleDownloadDocument = () => {
    let filename: string = ''
    if (content.status === 'SIGNED') {
      const extension = content.filename.substr(content.filename.length - 4)
      const baseName = content.filename.substr(0, content.filename.length - 4)
      filename = filename.concat(baseName, userInfo.otherSettings.suffixSignedDocuments, extension)
    } else {
      filename = content.filename
    }

    new DownloadDocumentUseCase({
      documentId: content._id,
    })
      .download()
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.style.display = 'none'
        a.click()
        a.remove()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const removeDocument = async () => {
    const signatures = await new QuerySignaturesUseCase().query({
      document: content._id,
    })

    if (signatures) {
      for (let i = 0; i < signatures.length; i++) {
        try {
          await new DeleteSignatureUseCase({
            signatureId: signatures[i]._id,
          }).delete()
        } catch (error) {
          console.error(`Cannot delete signature ${signatures[i]._id}: ${error.message}`)
        }
      }
    }

    await new DeleteDocumentUseCase({
      documentId: content._id,
    }).delete()

    setDocumentList([
      ...documentList.filter((doc) => {
        return doc._id !== content._id
      }),
    ])
  }

  const handleRemoveDocument = () => {
    setInProgress(true)
    removeDocument()
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  useEffect(() => {
    if (content) {
      new RenderDocumentPagesUseCase({
        documentId: content._id,
      })
        .render({
          dpi: 72,
          fileType: 'png',
          setOfPages: '1',
        })
        .then((pages) => {
          setPage(pages[0])
        })
    }
  }, [content])

  return (
    <div className="container w-full md:w-1/2 lg:w-4/10 m-1 border-2 rounded-md shadow-md bg-white border-gray-100 hover:border-acs-blue-600">
      <div className="w-full mt-4 px-4">
        <div className="flex w-full justify-between items-center">
          {/**
           * display name
           */}
          <Tooltip placement="top" title={content.displayName}>
            <p className="text-sm font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis cursor-pointer">
              {content.displayName}
            </p>
          </Tooltip>

          {/**
           * Activity type
           */}
          <div className="flex justify-end">
            {showStatus && (
              <div
                style={{ fontSize: '10px' }}
                className={`rounded-md ml-2 px-1 py-1 text-xs font-semibold ${
                  content.status === 'ATTACHED' ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'
                }`}
              >
                {t(`documentItem:status.${content.status.toLowerCase()}`)}
              </div>
            )}
          </div>
        </div>
      </div>

      {/**
       * Document preview (1st page)
       */}
      <Spin spinning={inProgress} size="large">
        {page ? (
          <img
            className="w-full px-6 py-3 hover:cursor-pointer"
            src={page.dataUrl}
            alt={content.displayName}
            onClick={handleViewDocument}
          />
        ) : (
          <div className="w-full h-auto px-6 py-3">
            {/* 
          <img className="" src={`https://via.placeholder.com/595x842.png?text=...loading...`} alt="document" />
        */}
            <Skeleton active paragraph={{ rows: 8 }} />
          </div>
        )}
      </Spin>

      {/**
       * Actions
       */}
      <div className="flex justify-evenly w-full">
        <DocumentItemAction
          show={canView}
          label={t('action.view')}
          clickAction={handleViewDocument}
          icon={<EyeIcon className="w-5 h-5" />}
        />
        <DocumentItemAction
          show={canPrepare}
          label={t('action.prepare')}
          clickAction={handlePrepareDocument}
          icon={<PencilIcon className="w-5 h-5" />}
        />
        <DocumentItemAction
          show={canDownload}
          label={t('action.download')}
          clickAction={handleDownloadDocument}
          icon={<DownloadIcon className="w-5 h-5" />}
        />
        <DocumentItemAction
          show={canRemove}
          label={t('action.remove')}
          clickAction={handleRemoveDocument}
          icon={<TrashIcon className="w-5 h-5" />}
        />
      </div>
    </div>
  )
}

export default DocumentItem
