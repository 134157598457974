/* eslint-disable react-hooks/exhaustive-deps */
import {
  AuthorizeUserUseCase,
  Configuration,
  GetUserUseCase,
  QueryAccountsUseCase,
  QueryWorkspacesUseCase,
} from '@axelity/actasign-sic'
import { useCallback, useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  accessTokenState,
  userInfoState,
  currentAccountState,
  currentPlanState,
  currentWorkspaceState,
  currentRolesState,
  userLanguageState,
} from '../recoil/atoms'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

type TUseSignInProps = {
  queryAccessToken: string
}

const useSignIn = (props?: TUseSignInProps): [noAccessToken: boolean, forceUpdate: () => void] => {
  const search = useLocation().search
  const queryAccessToken = new URLSearchParams(search).get('access_token')
  const challenge = new URLSearchParams(search).get('challenge')
  const otp = new URLSearchParams(search).get('otp')

  //  const { queryAccessToken } = props || { undefined }

  const history = useHistory()

  const { i18n } = useTranslation()

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState)
  const [userInfo, setUserInfo] = useRecoilState(userInfoState)
  const setUserLanguage = useSetRecoilState(userLanguageState)
  const setCurrentAccount = useSetRecoilState(currentAccountState)
  const setCurrentWorkspace = useSetRecoilState(currentWorkspaceState)
  const setCurrentPlan = useSetRecoilState(currentPlanState)
  const setCurrentRoles = useSetRecoilState(currentRolesState)

  const [noAccessToken, setNoAccessToken] = useState<boolean>(false)

  type UpdateSignInDataProps = {
    forceUpdate: boolean
  }

  const updateSignInData = async ({ forceUpdate = false }: UpdateSignInDataProps) => {
    if (accessToken) {
      sessionStorage.setItem('access_token', accessToken)
      Configuration.ACCESS_TOKEN = accessToken

      if (!userInfo || forceUpdate) {
        try {
          const _userInfo = await new GetUserUseCase().getUser()

          if (_userInfo) {
            setUserInfo(_userInfo)
            setUserLanguage(_userInfo.language)
            i18n.changeLanguage(_userInfo.language)

            if (_userInfo.memberships && _userInfo.memberships.length > 0) {
              setCurrentPlan(_userInfo.memberships[0].plan)
              setCurrentRoles(_userInfo.memberships[0].roles)
            }

            if (_userInfo.currentAccount) {
              const accounts = await new QueryAccountsUseCase().query({
                id: _userInfo.currentAccount,
              })
              if (accounts.length) {
                setCurrentAccount(accounts[0])
              }
            }

            if (_userInfo.currentWorkspace) {
              const workspaces = await new QueryWorkspacesUseCase().query({
                id: _userInfo.currentWorkspace,
              })
              if (workspaces.length) {
                setCurrentWorkspace(workspaces[0])
              }
            }
          } else {
            if (!challenge && !otp) {
              history.push('/signin')
            }
          }
        } catch (error) {
          history.push('/signin')
        }
      }
    }
  }

  const forceUpdate = useCallback(() => {
    updateSignInData({ forceUpdate: true })
  }, [])

  useEffect(() => {
    if (!accessToken) {
      const sessionAccessToken = sessionStorage.getItem('access_token')
      const envAccessToken = process.env.REACT_APP_ACCESS_TOKEN

      let _accessToken: string

      if (queryAccessToken) {
        _accessToken = queryAccessToken.trim()
      } else if (sessionAccessToken) {
        _accessToken = sessionAccessToken.trim()
      } else if (envAccessToken) {
        _accessToken = decodeURIComponent(envAccessToken.trim())
      }

      if (_accessToken) {
        setAccessToken(_accessToken)
        setNoAccessToken(false)
      } else {
        setNoAccessToken(true)
        if (!challenge && !otp) {
          history.push('/signin')
        }
      }
    } else {
      updateSignInData({ forceUpdate: false }).catch((error) => {
        console.error('Sign-in failed')
        console.error(error)
      })
    }
  }, [accessToken])

  useEffect(() => {
    if (queryAccessToken) {
      setAccessToken(queryAccessToken)
    }
  }, [queryAccessToken])

  useEffect(() => {
    if (challenge && otp) {
      new AuthorizeUserUseCase()
        .authorize({
          challenge,
          otp,
        })
        .then((response) => {
          setAccessToken(response.access_token)
          updateSignInData({ forceUpdate: true })
        })
        .catch((error) => {
          console.error(error)
          history.replace('/signin')
        })
    }
  }, [challenge, otp])

  return [noAccessToken, forceUpdate]
}

export default useSignIn
