import { ApplicationException, ISignUpRequest, SignUpUseCase } from '@axelity/actasign-sic'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import i18n from '../i18n'
import { userLanguageState, userInfoState } from '../recoil/atoms'
import { Button, Input, Select, Switch, message } from 'antd'
import Terms from '../elements/Terms'
import AppLogo from '../components/AppLogo'
import FieldTitle from '../elements/FieldTitle'
import * as yup from 'yup'

const { Option } = Select

const SignUp = () => {
  const { t } = useTranslation(['common', 'signup'])

  const history = useHistory()

  const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState)
  const setUserInfo = useSetRecoilState(userInfoState)

  const [firstName, setFirstName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [accepted, setAccepted] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false)

  type TFormErrors = {
    firstName: string
    familyName: string
    emailAddress: string
  }

  const [errors, setErrors] = useState<TFormErrors>({
    firstName: '',
    familyName: '',
    emailAddress: '',
  })

  const validateForm = (): boolean => {
    let valid = true
    let errors: TFormErrors = {
      firstName: '',
      familyName: '',
      emailAddress: '',
    }

    if (!yup.string().min(2).required().isValidSync(firstName)) {
      errors = { ...errors, firstName: t('signup:validation.inputRequired') }
      valid = false
    }

    if (!yup.string().min(2).required().isValidSync(familyName)) {
      errors = { ...errors, familyName: t('signup:validation.inputRequired') }
      valid = false
    }

    if (!yup.string().required().matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).isValidSync(emailAddress)) {
      errors = { ...errors, emailAddress: t('signup:validation.inputRequired') }
      valid = false
    }

    setErrors(errors)

    return valid
  }
  
  const toggleAccepted = (checked: boolean) => {
    if (checked) {
      setAccepted(true)
    } else {
      setAccepted(false)
    }
  }

  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value)
    setUserLanguage(i18n.language.substr(0,2))
  }

  const signUpHandler = async () => {
    if (!validateForm()) return

    try {
      setIsInProgress(true)
      const signUpRequest: ISignUpRequest = {
        emailAddress,
        familyName,
        firstName,
        language: userLanguage,
      }

      const _userInfo = await new SignUpUseCase().signUp(signUpRequest)
      setUserInfo(_userInfo)

      setIsInProgress(false)
      history.push('/signin')
    } catch (error) {
      setIsInProgress(false)
      const err = error as ApplicationException
      if (err.message.includes(emailAddress)) {
        message.error(t('signup:validation.userAlreadyExists', { emailAddress }))
      } else {
        message.error(t('signup:validation.unexpectedError'))
        console.dir(err.problem)
      }
    }
  }

  return (
    <div className="container max-w-6xl mx-auto text-base p-10 mb-10">
      <div className="mt-10 hover:cursor-pointer" onClick={() => history.push('/')}>
        <AppLogo />
      </div>

      <div className="bg-white border-2 border-gray-100 p-4 mt-10 shadow-sm rounded-md">
        <div className="flex-1 px-10 text-base">
          {/**
           * Title
           */}
          <div className="flex justify-left">
            <h1 className="text-xl font-bold">{t('common:sign-up')}</h1>
          </div>

          {/**
           * Instructions
           */}
          <div className="pt-12 flex justify-left">
            <h2 className="text-lg font-light">{t('signup:instructions')}</h2>
          </div>
        </div>

        <div className="min-h-full bg-white flex flex-row">
          <div className="pt-12 px-10 w-1/2 text-base">
            {/**
             * First name
             */}
            <div className="my-2">
              <FieldTitle title={t('signup:firstName')} />
              <Input
                className="rounded"
                autoComplete="given-name"
                size="large"
                placeholder={t('signup:firstName')}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              ></Input>
            </div>
            <div className="my-1 font-semibold text-md text-red-500">{errors.firstName}</div>

            {/**
             * Family name
             */}
            <div className="my-2">
              <FieldTitle title={t('signup:familyName')} />
              <Input
                className="rounded"
                autoComplete="family-name"
                size="large"
                placeholder={t('signup:familyName')}
                value={familyName}
                onChange={(e) => setFamilyName(e.target.value)}
              ></Input>
            </div>
            <div className="my-1 font-semibold text-md text-red-500">{errors.familyName}</div>

            {/**
             * Email address
             */}
            <div className="my-2">
              <FieldTitle title={t('signup:emailAddress')} />
              <Input
                className="rounded"
                autoComplete="email"
                type="email"
                size="large"
                placeholder={t('signup:emailAddress')}
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              ></Input>
            </div>
            <div className="my-1 font-semibold text-md text-red-500">{errors.emailAddress}</div>

            {/**
             * Language
             */}
            <div className="my-2">
              <FieldTitle title={t('signup:language')} />
              <div className="w-full">
                <Select
                  className="rounded"
                  value={userLanguage}
                  size="large"
                  style={{ width: '100%' }}
                  onChange={changeLanguage}
                >
                  <Option value="de">{t('common:language.german')}</Option>
                  <Option value="en">{t('common:language.english')}</Option>
                  {/* <Option value="fr">{t('common:language.french')}</Option> */}
                </Select>
              </div>
            </div>

            {/**
             * Terms and condition
             */}
            <div className="mt-5">
              <div className="flex flex-auto content-center flex-nowrap">
                <div>
                  <Switch checked={accepted} onClick={toggleAccepted} />
                </div>
                <p className="pl-2 text-md font-medium whitespace-nowrap">{t('signup:accept')}</p>
                <Terms />
                {/* <Link to="/terms" className="px-2 font-medium text-blue-500 hover:text-blue-800">
                  {t('signup:terms')}
                </Link> */}
              </div>
            </div>

            {/**
             * Register Button
             */}
            <div className="mt-10">
              <Button
                className="rounded font-semibold"
                type="primary"
                disabled={!accepted}
                size="large"
                style={{ width: '100%' }}
                onClick={signUpHandler}
                loading={isInProgress}
              >
                {t('signup:register')}
              </Button>
            </div>

            {/**
             * Sign-in Link
             */}
            <div className="mt-5">
              <div className="flex content-center">
                <p>{t('signup:alreadyRegistered')}</p>
                <Link to="/signin" className="px-2 font-medium  text-blue-500 hover:text-blue-800">
                  {t('common:sign-in')}
                </Link>
              </div>
            </div>
          </div>

          <div className="hidden lg:block pt-12 px-10 w-1/2 text-base">
            <div className="flex flex-row justify-center">
              <img
                style={{ width: '75%' }}
                src={`${process.env.PUBLIC_URL}/assets/images/adduser.svg`}
                alt="Online illustrations by Storyset"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp
