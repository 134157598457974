import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { activityListState, currentProcessStepState, requestState, showRequestActivityDialogState } from '../../recoil/atoms'
import ActivityList from '../ActivityList'

const RequestStep2 = () => {
  const { t } = useTranslation(['common', 'request', 'settings'])

  const request = useRecoilValue(requestState)
  const setCurrentProcessStep = useSetRecoilState(currentProcessStepState)

  const activityList = useRecoilValue(activityListState)
  const setShowRequestActivityDialog = useSetRecoilState(showRequestActivityDialogState)

  const nextHandler = () => {
    setShowRequestActivityDialog(false)
    setCurrentProcessStep(2)
  }

  const previousHandler = () => {
    setShowRequestActivityDialog(false)
    setCurrentProcessStep(0)
  }

  return (
    <div>
      <div className="flex flex-auto my-8 justify-between">
        <Button className="rounded font-semibold" size="large" type="primary" onClick={previousHandler}>
          <div className="inline-flex items-center whitespace-nowrap">
            <LeftCircleOutlined />
            <p className="ml-2">{t('request:step.request.title')}</p>
          </div>
        </Button>

        {activityList.length !== 0 ? (
          <Button className="rounded font-semibold" size="large" type="primary" onClick={nextHandler}>
            <div className="inline-flex items-center whitespace-nowrap">
              <p className="mr-2">{t('request:step.documents.title')}</p>
              <RightCircleOutlined />
            </div>
          </Button>
        ) : null}
      </div>

      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        <ActivityList
          canAdd={request && request.status === 'PENDING'}
          canChange={request && request.status === 'PENDING'}
          canDelete={request && request.status === 'PENDING'}
          canRearrange={request && request.status === 'PENDING'}
        />
      </div>
    </div>
  )
}

export default RequestStep2
