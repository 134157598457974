import { IVisual, TUserId, TVisualStatus } from '@axelity/actasign-sic'
import VisualItem from './VisualItem'

type VisualListProps = {
  visuals: IVisual[]
  status?: TVisualStatus | undefined
  user?: TUserId | undefined
}

const VisualList = (props: VisualListProps) => {
  let filteredVisuals: IVisual[] = []
  if (props.status) {
    filteredVisuals = props.visuals.filter((visual) => {
      return visual.status === props.status
    })
  } else {
    filteredVisuals = props.visuals.map((visual) => {
      return visual
    })
  }

  if (props.user) {
    filteredVisuals = filteredVisuals.filter((visual) => {
      return visual.user === props.user
    })
  }

  return (
    <ul>
      {filteredVisuals.map((visual) => {
        return <VisualItem key={visual._id} visual={visual} />
      })}
    </ul>
  )
}

export default VisualList
