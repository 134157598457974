type TNoDataProps = {
  note?: string
}

const NoData = ({ note = undefined }: TNoDataProps) => {
  return (
    <div className="h-full m-16 flex flex-col justify-center items-center">
      <img
        className="object-scale-down w-48 h-auto opacity-50"
        src={`${process.env.PUBLIC_URL}/assets/images/nodata.png`}
        alt="empty"
      />

      {note ? <p className="text-sm text-gray-600 font-light">{note}</p> : null}
    </div>
  )
}

export default NoData
