import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { RightCircleOutlined } from '@ant-design/icons'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { currentProcessStepState, requestState } from '../../recoil/atoms'
import RequestDetails from '../RequestDetails'
import { IProcess } from '@axelity/actasign-sic'

const RequestStep1 = () => {
  const { t } = useTranslation(['common', 'request', 'settings'])

  const setCurrentProcessStep = useSetRecoilState(currentProcessStepState)

  const request = useRecoilValue(requestState)

  const handleApply = (request: IProcess) => {
    nextHandler()
  }

  const nextHandler = () => {
    setCurrentProcessStep(1)
  }

  return (
    <div>
      <div className="flex flex-auto my-8 justify-end">
        <Button
          disabled={!request}
          className="rounded font-semibold"
          size="large"
          type="primary"
          onClick={nextHandler}
        >
          <div className="inline-flex items-center whitespace-nowrap">
            <p className="mr-2">{t('request:step.activities.title')}</p>
            <RightCircleOutlined />
          </div>
        </Button>
      </div>
      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        <RequestDetails onApply={handleApply} />
      </div>
    </div>
  )
}

export default RequestStep1
