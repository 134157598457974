/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Button, Input, message } from 'antd'
import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { useRecoilValue } from 'recoil'
import { currentAccountState, userInfoState } from '../recoil/atoms'
import { CreateClientTokenUseCase } from '@axelity/actasign-sic'
import useSignIn from '../hooks/useSignIn'
import SectionTitle from '../elements/SectionTitle'
import FieldTitle from '../elements/FieldTitle'
import moment from 'moment'
import * as yup from 'yup'

const { TextArea } = Input

const SettingsApiKey = () => {
  const { t } = useTranslation(['settingsApiKey', 'settings'])

  const [noAccessToken] = useSignIn()

  const history = useHistory()

  const displayNameRef = useRef(null)

  const userInfo = useRecoilValue(userInfoState)
  const currentAccount = useRecoilValue(currentAccountState)

  const [durationDays, setDurationDays] = useState<number>(365)
  const [displayName, setDisplayName] = useState<string>('')
  const [expires, setExpires] = useState<string>('')
  const [tokenCode, setTokenCode] = useState<string>('')


  type TFormErrors = {
    displayName: string
    validity: string
  }

  const [errors, setErrors] = useState<TFormErrors>({
    displayName: '',
    validity: '',
  })

  const validateForm = (): boolean => {
    let valid = true
    let errors: TFormErrors = {
      displayName: '',
      validity: '',
      }

    if (!yup.string().min(2).required().isValidSync(displayName)) {
      errors = { ...errors, displayName: t('settingsApiKey:error.inputRequired') }
      valid = false
    }

    if (!yup.number().min(1).max(3650).required().isValidSync(durationDays)) {
      errors = { ...errors, validity: t('settingsApiKey:error.invalidValidity') }
      valid = false
    }

    setErrors(errors)

    return valid
  }

  const generateHandler = () => {
    if (!validateForm()) return

    new CreateClientTokenUseCase()
      .create({
        account: currentAccount._id,
        displayName,
        durationDays,
        expires: moment().add(durationDays, 'days').toDate(),
        description: '',
      })
      .then((response) => {
        setTokenCode(response.tokenCode)
      })
      .then(() => {
        message.success(t('settingsApiKey:creationSuccessful'))
      })
      .catch((error) => {
        message.error(t('settingsApiKey:creationFailed'))
        console.error(error)
      })
  }

  const copyToClipboardHandler = () => {
    const cb = navigator.clipboard
    cb.writeText(tokenCode).then(() => message.info(t('settingsApiKey:copySuccessful')))
  }

  const saveHandler = () => {
    history.push('/settings/apikeys')
  }

  useEffect(() => {
    setExpires(
      Intl.DateTimeFormat(userInfo.language, { dateStyle: 'medium' }).format(
        moment().add(durationDays, 'days').toDate()),
      )
  }, [durationDays])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  useEffect(() => {
    displayNameRef.current.focus()
  }, [])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('settingsApiKey:title')}
        pages={[
          {
            name: t('settings:accountSettings.name'),
          },
          {
            name: t('settings:apiKeys.name'),
          },
        ]}
      />

      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        {/**
         * Title
         */}
        <SectionTitle title={t('settingsApiKey:title')} />

        {/**
         * Instructions
         */}
        <div>
          <p className="mt-2">{t('settingsApiKey:instructions')}</p>
        </div>

        <div className="mt-5">

          {/**
           * Display name
           */}
          <FieldTitle title={t('settingsApiKey:displayName')} />
          <div className="w-full">
            <Input
              type="text"
              size="large"
              placeholder=""
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              style={{ borderRadius: '5px' }}
              ref={displayNameRef}
            ></Input>
            <div className="my-1 font-semibold text-md text-red-500">{errors.displayName}</div>
          </div>

          <div>
            <FieldTitle title={t('settingsApiKey:validityDays')} />

            <div className="inline-flex mt-1">
              <Input
                type="number"
                size="large"
                placeholder={t('settingsApiKey:validityDays')}
                value={durationDays}
                onChange={(e) => setDurationDays(parseInt(e.target.value))}
              ></Input>
              <div>
                <Button className="ml-2 rounded font-semibold" size="large" type="primary" onClick={generateHandler}>
                  {t('settingsApiKey:generate')}
                </Button>
              </div>
            </div>
            <p className="my-2 font-semibold">{`${t('settingsApiKey:validUntil')}: ${expires}`}</p>
            <div className="my-1 font-semibold text-md text-red-500">{errors.validity}</div>

            <p className="mt-8 mb-4 uppercase font-semibold">{t('settingsApiKey:warning')}</p>
            <TextArea
              id="magicLink"
              size="middle"
              rows={10}
              placeholder=""
              value={tokenCode}
              readOnly={true}
            ></TextArea>

            <div className="flex flex-auto mt-4 justify-between">
              <Button className="ml-2 rounded font-semibold" size="large" type="default" onClick={copyToClipboardHandler}>
                {t('settingsApiKey:copy')}
              </Button>
              <Button className="ml-2 rounded font-semibold" size="large" type="primary" onClick={saveHandler}>
                {t('settingsApiKey:back')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsApiKey
