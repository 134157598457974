import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { TFileInfo } from '../types/appTypes'
import {
  activityListState,
  currentPlanState,
  documentListState,
  documentState,
  fastSignatureState,
  requestState,
  taskListState,
  taskState,
  userInfoState,
  viewerState,
} from '../recoil/atoms'
import {
  CreateProcessUseCase,
  TTrustLevel,
  CreateActivityUseCase,
  CreateTaskUseCase,
  RegisterDocumentUseCase,
  UploadDocumentUseCase,
  IDocument,
} from '@axelity/actasign-sic'
import Dropzone from '../components/Dropzone'
import React, { useState } from 'react'
import { Spin } from 'antd'
import moment from 'moment'

const CONTENT_TYPE = 'application/pdf'

const FastSignature = () => {
  const { t } = useTranslation(['common', 'myTasks'])

  const history = useHistory()

  const userInfo = useRecoilValue(userInfoState)
  const currentPlan = useRecoilValue(currentPlanState)
  const setRequest = useSetRecoilState(requestState)
  const setActivities = useSetRecoilState(activityListState)
  const setTask = useSetRecoilState(taskState)
  const setTasks = useSetRecoilState(taskListState)
  const setFastSignature = useSetRecoilState(fastSignatureState)
  const setDocument = useSetRecoilState(documentState)
  const setDocuments = useSetRecoilState(documentListState)
  const setViewerState = useSetRecoilState(viewerState)

  const [dueOn] = useState<Date>(moment().add(2, 'weeks').toDate())
  const [retainUntil] = useState<Date>(moment().add(3, 'months').toDate())

  const [inProgress, setInProgress] = useState<boolean>(false)

  const addRequestData = async (files: TFileInfo[]) => {
    if (files && files.length > 0 && userInfo.currentAccount && userInfo.currentWorkspace) {
      // Add process
      const _request = await new CreateProcessUseCase().create({
        account: userInfo.currentAccount,
        workspace: userInfo.currentWorkspace,
        owner: userInfo._id,
        title: files[0].filename.toLowerCase().endsWith('.pdf')
          ? files[0].filename.substr(0, files[0].filename.length - 4)
          : files[0].filename,
        dueOn,
        retainUntil,
        settings: {
          notifyAllProcessRecalled: false,
          notifyAssigneeTaskStarted: false,
          notifyAssigneesProcessCompleted: false,
          notifyAssignerProcessCompleted: false,
          notifyAssignerProcessStarted: false,
          notifyAssignerTaskCompleted: false,
          notifyAssignerTaskDeclined: false,
          notifyAssignerTaskReassigned: false,
          notifyAssignerTaskStarted: false,
          ownerIsAssignee: true,
          permitAssignActivityOrder: false,
          useIndividualMessages: false,
        },
      })

      setRequest(_request)

      let _trustLevel: TTrustLevel = 'SES'
      if (userInfo.memberships) {
        if (userInfo.memberships[0].plan === 'AES') {
          _trustLevel = 'AES'
        } else if (
          userInfo.memberships[0].plan === 'QES' ||
          userInfo.memberships[0].plan === 'PREMIUM' ||
          userInfo.memberships[0].plan === 'PARTNER'
        ) {
          _trustLevel = 'QES'
        }
      }

      // Add activity
      const _activity = await new CreateActivityUseCase().create({
        account: userInfo.currentAccount,
        workspace: userInfo.currentWorkspace,
        process: _request._id,
        activityType: 'SIGN',
        settings: {
          authMethod: 'EMAIL-LINK',
          permitAddAdditionalTask: false,
          permitReassignment: false,
          trustLevel: _trustLevel,
        },
      })

      setActivities([_activity])

      // Add task
      const _task = await new CreateTaskUseCase().create({
        account: userInfo.currentAccount,
        workspace: userInfo.currentWorkspace,
        process: _request._id,
        activity: _activity._id,
        priority: 3,
        taskType: 'SIGN',
        user: userInfo._id,
        trustLevel: _trustLevel,
      })

      setTask(_task)
      setTasks([_task])

      // Add document
      const _documents: IDocument[] = []
      for (let i = 0; i < files.length; i++) {
        const _fileInfo = files[i]
        const _document = await new RegisterDocumentUseCase().register({
          account: userInfo.currentAccount,
          workspace: userInfo.currentWorkspace,
          process: _request._id,
          contentType: CONTENT_TYPE,
          filename: _fileInfo.filename,
          toBeSigned: true,
          displayName: _fileInfo.filename.toLowerCase().endsWith('.pdf')
            ? _fileInfo.filename.substr(0, _fileInfo.filename.length - 4)
            : _fileInfo.filename,
        })

        const content = new Blob([_fileInfo.content as ArrayBuffer])
        await new UploadDocumentUseCase({
          documentId: _document._id,
        }).upload({
          content,
        })

        if (files.length === 1) {
          setDocument(_document)
        }
        _documents.push(_document)
      }
      setDocuments(_documents)
    }
  }

  const handleFastSignature = async (files: TFileInfo[]) => {
    setInProgress(true)

    addRequestData(files)
      .then(() => {
        setInProgress(false)
        if (files.length === 1) {
          setViewerState('CHANGE')
          setFastSignature('SINGLE')
          history.push('/viewer')
        } else {
          setFastSignature('MULTIPLE')
          history.push('/task')
        }
      })
      .catch((error) => {
        console.error(error)
        setInProgress(false)
      })
  }

  return (
    <div>
      {currentPlan !== 'GUEST' && currentPlan !== 'PARTNER' ? (
        <div className="container max-w-4xl mx-auto text-base px-4 rounded-sm">
          <Spin spinning={inProgress} size="large">
            <Dropzone
              mimeType={CONTENT_TYPE}
              multiple={true}
              onSelect={handleFastSignature}
              upLoadInstructions={t('myTasks:uploadInstructions')}
            />
          </Spin>
        </div>
      ) : null}
    </div>
  )
}

export default FastSignature
