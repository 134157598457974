/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  CloseProcessUseCase,
  DeleteProcessUseCase,
  IProcess,
  QueryDocumentsUseCase,
  QueryProcessesUseCase,
  QuerySignaturesUseCase,
  QueryTasksUseCase,
  RecallProcessUseCase,
  RemindProcessUseCase,
  ResumeProcessUseCase,
  StartProcessUseCase,
} from '@axelity/actasign-sic'
import {
  MailIcon,
  ClockIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon,
  PlayIcon,
  StopIcon,
  XCircleIcon,
  LightBulbIcon,
  ArrowCircleRightIcon,
} from '@heroicons/react/outline'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  closeAllTimelinesState,
  currentProcessStepState,
  requestListState,
  requestState,
  userInfoState,
} from '../recoil/atoms'
import { useEffect, useState } from 'react'
import { Spin, message } from 'antd'
import Timeline from './Timeline'
import { setTimeout } from 'timers'

type RequestItemProps = {
  request: IProcess
}
const RequestItem = ({ request }: RequestItemProps) => {
  const { t } = useTranslation(['requestItem'])

  const userInfo = useRecoilValue(userInfoState)
  const setRequest = useSetRecoilState(requestState)
  const resetRequest = useResetRecoilState(requestState)
  const setRequests = useSetRecoilState(requestListState)
  const [closeAllTimelines, setCloseAllTimelines] = useRecoilState(closeAllTimelinesState)
  const resetCurrentProcessStep = useResetRecoilState(currentProcessStepState)

  const [inProgress, setInProgress] = useState<boolean>(false)
  const [showTimeline, setShowTimeline] = useState<boolean>(false)

  const history = useHistory()

  let created = ''
  if (request && request.created) {
    created = Intl.DateTimeFormat(request.ownerInfo.language, { dateStyle: 'medium', timeStyle: 'short' }).format(
      new Date(`${request.created}`),
    )
  }

  const toggleTimeline = () => {
    if (showTimeline) {
      setShowTimeline(false)
      resetRequest()
    } else {
      setCloseAllTimelines(true)
      setTimeout(() => {
        setCloseAllTimelines(false)
        setRequest(request)
        setShowTimeline(true)
      }, 100)
    }
  }

  const handleViewRequest = () => {
    resetCurrentProcessStep()

    setRequest(request)
    history.push('/request')
  }

  const updateRequestList = async () => {
    const _processes = await new QueryProcessesUseCase().query({
      owner: userInfo._id,
    })

    setRequests(_processes)
  }

  const handleStartRequest = async () => {
    setInProgress(true)

    try {
      const _documents = await new QueryDocumentsUseCase().query({
        process: request._id,
      })

      if (!_documents || _documents.length === 0) {
        message.error(t('requestItem:error.incomplete'))
        throw new Error(t('requestItem:error.incomplete'))
      }

      const _tasks = await new QueryTasksUseCase().query({
        process: request._id,
      })

      const _signTasks = _tasks.filter((_task) => {
        return _task.activityType === 'SIGN'
      })

      if (_signTasks && _signTasks.length > 0) {
        const _signatures = await new QuerySignaturesUseCase().query({
          process: request._id,
        })

        if (!_signatures || _signatures.length < _signTasks.length) {
          message.error(t('requestItem:error.signaturesIncomplete'))
          throw new Error(t('requestItem:error.signaturesIncomplete'))
        }
      }

      await new StartProcessUseCase({
        processId: request._id,
      }).start()

      await updateRequestList()
    } catch (error) {
      console.error(error)
    } finally {
      setInProgress(false)
    }
  }

  const handleChangeRequest = () => {
    resetCurrentProcessStep()

    setRequest(request)
    history.push('/request')
  }

  const remindRequest = async () => {
    try {
      await new RemindProcessUseCase({
        processId: request._id,
      }).remind()
    } catch (error) {
      console.error(`Cannot remind request ${request._id}: ${error.message}`)
    }
  }

  const removeRequest = async () => {
    try {
      await new DeleteProcessUseCase({
        processId: request._id,
      }).delete()
    } catch (error) {
      console.error(`Cannot delete request ${request._id}: ${error.message}`)
    }

    const requests = await new QueryProcessesUseCase().query({
      owner: userInfo._id,
    })

    setRequests(requests)
  }

  const handleRemindRequest = () => {
    setInProgress(true)
    remindRequest()
      .then(() => {
        message.info(t('requestItem:info.processReminded'))
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  const handleRemoveRequest = () => {
    setInProgress(true)
    removeRequest()
      .then()
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  const handleCancelRequest = () => {
    setInProgress(true)
    new RecallProcessUseCase({
      processId: request._id,
    })
      .recall()
      .then(() => {
        return updateRequestList()
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  const handleResumeRequest = () => {
    setInProgress(true)
    new ResumeProcessUseCase({
      processId: request._id,
    })
      .resume()
      .then(() => {
        updateRequestList()
        message.info(t('requestItem:info.processResumed'))
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  const handleCloseRequest = () => {
    setInProgress(true)
    new CloseProcessUseCase({
      processId: request._id,
    })
      .close()
      .then(() => {
        return updateRequestList()
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  useEffect(() => {
    if (closeAllTimelines) {
      setShowTimeline(false)
    }
  }, [closeAllTimelines])

  return (
    <div className="container my-2 border-2 shadow-sm rounded-md border-gray-200 hover:bg-gray-50 hover:border-acs-blue-600 hover:cursor-pointer">
      <Spin spinning={inProgress} size="large">
        <div className="flex justify-start p-2">
          <MailIcon className="w-7 h-7 text-acs-blue-600" />
          <div className="ml-5 w-full">
            <div className="flex justify-between items-center">
              <div className="truncate text-md font-semibold">{request.title}</div>
              <div className="flex justify-end items-center">
                <div
                  className={`rounded-md px-2 py-1 ${
                    request.status === 'ACTIVE' ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'
                  }  text-xs font-semibold`}
                >
                  {t(`requestItem:status.${request.status.toLowerCase()}`)}
                </div>
                <div className="mx-2 text-xs font-normal text-gray-500">{created}</div>
              </div>
            </div>
          </div>
        </div>

        {/**
         * Actions
         */}
        <div className="flex justify-end rounded-b-md w-full pr-2 bg-acs-blue-50">
          {request.status !== 'PENDING' ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleViewRequest}
            >
              <EyeIcon className="w-4 h-4 mr-1" />
              {t('requestItem:action.view')}
            </div>
          ) : null}

          {request.status !== 'PENDING' ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={toggleTimeline}
            >
              <ClockIcon className="w-4 h-4 mr-1" />
              {t('requestItem:action.timeline')}
            </div>
          ) : null}

          {request.status === 'PENDING' ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleStartRequest}
            >
              <PlayIcon className="w-4 h-4 mr-1" />
              {t('requestItem:action.start')}
            </div>
          ) : null}

          {request.status === 'PENDING' ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleChangeRequest}
            >
              <PencilIcon className="w-4 h-4 mr-1" />
              {t('requestItem:action.edit')}
            </div>
          ) : null}

          {request.status === 'PENDING' ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleRemoveRequest}
            >
              <XCircleIcon className="w-4 h-4 mr-1" />
              {t('requestItem:action.remove')}
            </div>
          ) : null}

          {request.status === 'ACTIVE' ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleRemindRequest}
            >
              <LightBulbIcon className="w-4 h-4 mr-1" />
              {t('requestItem:action.remind')}
            </div>
          ) : null}

          {request.status === 'ACTIVE' ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleCancelRequest}
            >
              <StopIcon className="w-4 h-4 mr-1" />
              {t('requestItem:action.cancel')}
            </div>
          ) : null}

          {request.status === 'SUSPENDED' ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleResumeRequest}
            >
              <ArrowCircleRightIcon className="w-4 h-4 mr-1" />
              {t('requestItem:action.resume')}
            </div>
          ) : null}

          {request.status !== 'ACTIVE' && request.status !== 'PENDING' ? (
            <div
              className="flex ml-2 p-2 rounded-md text-xs font-semibold text-acs-blue-600 hover:bg-acs-blue-100 hover:text-acs-blue-700 hover:cursor-pointer"
              onClick={handleCloseRequest}
            >
              <TrashIcon className="w-4 h-4 mr-1" />
              {t('requestItem:action.clean')}
            </div>
          ) : null}
        </div>
      </Spin>
      {showTimeline ? <Timeline /> : null}
    </div>
  )
}

export default RequestItem
