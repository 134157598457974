/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Select, Input, message } from 'antd'
import { Transition } from '@headlessui/react'
import {
  CreateActivityUseCase,
  CreateTaskUseCase,
  IActivity,
  ITask,
  IUserInfo,
  QueryActivitiesUseCase,
  QueryProcessesUseCase,
  QueryTasksUseCase,
  TActivityType,
  TTaskType,
  TTrustLevel,
  UpdateActivityUseCase,
  UpdateTaskUseCase,
} from '@axelity/actasign-sic'
import UserRetrieval from './UserRetrieval'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  currentAccountState,
  currentWorkspaceState,
  requestState,
  activityState,
  activityListState,
  taskListState,
  showRequestActivityDialogState,
} from '../recoil/atoms'
import ActivityTaskItem from './ActivityTaskItem'
import SectionTitle from '../elements/SectionTitle'
import FieldTitle from '../elements/FieldTitle'

const { Option } = Select
const { TextArea } = Input

type TRequestActivityProps = {
  onCancel: () => void
  onApply: (activity: IActivity) => void
}

const RequestActivity = ({ onApply, onCancel }: TRequestActivityProps) => {
  const { t } = useTranslation(['requestActivity'])

  const messageRef = useRef(null)

  const account = useRecoilValue(currentAccountState)
  const workspace = useRecoilValue(currentWorkspaceState)
  const [request, setRequest] = useRecoilState(requestState)
  const [activity, setActivity] = useRecoilState(activityState)
  const [tasks, setTasks] = useRecoilState(taskListState)
  const [activities, setActivities] = useRecoilState(activityListState)

  const showRequestActivityDialog = useRecoilValue(showRequestActivityDialogState)

  const [activityType, setActivityType] = useState<TActivityType>('SIGN')
  const [trustLevel, setTrustLevel] = useState<TTrustLevel>('SES')
  const [activityMessage, setActivityMessage] = useState<string>('')
  const [activityTasks, setActivityTasks] = useState<ITask[]>([])

  const [inProgress, setInProgress] = useState<boolean>(false)

  const handleCancelUserRetrieval = () => {}

  const createTask = (user: IUserInfo, activity: IActivity): Promise<void> => {
    return new Promise((resolve, reject) => {
      let taskType: TTaskType

      switch (activity.activityType) {
        case 'SIGN':
          taskType = 'SIGN'
          break
        case 'APPROVE':
          taskType = 'APPROVE'
          break
        case 'ACKNOWLEDGE':
          taskType = 'ACKNOWLEDGE'
          break
        default:
          taskType = 'INFO'
          break
      }

      // Create Task for user
      new CreateTaskUseCase()
        .create({
          account: account._id,
          workspace: workspace._id,
          process: request._id,
          activity: activity._id,
          priority: 3,
          trustLevel: activity.activityType === 'SIGN' ? trustLevel : undefined,
          taskType,
          user: user._id,
        })
        .then((_task) => {
          setTasks([...tasks, _task])
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const createActivity = (): Promise<IActivity> => {
    return new Promise((resolve, reject) => {
      new CreateActivityUseCase()
        .create({
          account: account._id,
          workspace: workspace._id,
          process: request._id,
          activityType,
          settings: {
            authMethod: 'EMAIL-LINK',
            permitAddAdditionalTask: true,
            permitReassignment: true,
            trustLevel,
          },
          messages: {
            initial: activityMessage,
          },
        })
        .then((_activity) => {
          setActivities([...activities, _activity])
          setActivity(_activity)
          resolve(_activity)
        })
        .then(() => {
          return new QueryProcessesUseCase().query({
            id: request._id,
          })
        })
        .then((_requests) => {
          setRequest(_requests[0])
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const updateActivity = async (): Promise<void> => {
    try {
      // This updates the tasks as well
      const _activity = await new UpdateActivityUseCase({
        activityId: activity._id,
      }).update({
        activityType,
        settings: {
          authMethod: activity.settings.authMethod,
          password: activity.settings.password,
          permitAddAdditionalTask: activity.settings.permitAddAdditionalTask,
          permitReassignment: activity.settings.permitReassignment,
          nextReminderAfter: activity.settings.nextReminderAfter,
          finalReminderAfter: activity.settings.finalReminderAfter,
          jurisdiction: activity.settings.jurisdiction,
          trustLevel,
        },
        messages: {
          initial: activityMessage,
        },
      })

      setActivity(_activity)

    } catch (error) {
      console.error(error)
      message.error(t('requestActivity:fail.updateActivity'))
      throw error
    }
  }

  const handleCreateTask = (user: IUserInfo) => {
    setInProgress(true)
    if (activity) {
      createTask(user, activity)
        .then(() => {
          message.success(t('requestActivity:success.createTask'))
          setInProgress(false)
        })
        .catch((error) => {
          console.error(error)
          message.error(t('requestActivity:fail.createTask'))
        })
        .finally(() => {
          setInProgress(false)
        })
    } else {
      createActivity()
        .then((_activity) => {
          message.success(t('requestActivity:success.createActivity'))
          return createTask(user, _activity)
        })
        .then(() => {
          message.success(t('requestActivity:success.createTask'))
          setInProgress(false)
        })
        .catch((error) => {
          console.error(error)
          message.error(t('requestActivity:error.unexpected'))
        })
        .finally(() => {
          setInProgress(false)
        })
    }
  }

  const handleApply = () => {
    setInProgress(true)

    if (activity) {
      updateActivity()
        .then(() => {
          message.success(t('requestActivity:success.updateActivity'))
        })
        .then(() => {
          return new QueryActivitiesUseCase().query({
            process: request._id,
          })
        })
        .then((_activities) => {
          setActivities(_activities)
        })
        .catch((error) => {
          console.error(error)
          message.error(t('requestActivity:fail.updateActivity'))
        })
        .finally(() => {
          setInProgress(false)
        })
    }
    onApply(activity)
  }

  const handleCancel = () => {
    onCancel()
  }

  useEffect(() => {
    if (showRequestActivityDialogState) {
      if (activity) {
        setActivityType(activity.activityType)
        setActivityMessage(activity.messages?.initial || '')
        setTrustLevel(activity.settings.trustLevel || 'SES')
        setActivityTasks([
          ...tasks.filter((task) => {
            return task.activity === activity._id
          }),
        ])
      } else {
        setActivityType('SIGN')
        setActivityMessage('')
        setTrustLevel('SES')
        setActivityTasks([])
      }
    }
  }, [showRequestActivityDialog])

  useEffect(() => {
    if (activity) {
      setActivityTasks([
        ...tasks.filter((task) => {
          return task.activity === activity._id
        }),
      ])
    }
  }, [tasks])

  return (
    <div>
      <Transition
        show={showRequestActivityDialog}
        enter="transition ease duration-700 transform"
        enterFrom="opacity-0 -translate-y-0"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease duration-300 transform"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-0"
        afterEnter={() => messageRef.current.focus()}
      >
        <div className="mt-3 p-2 rounded shadow-sm border bg-white border-gray-100">
          <SectionTitle title={t('requestActivity:title')} />

          {/**
           * activity type
           */}
          <div className="my-2">
            <FieldTitle title={t('requestActivity:activityType.label')} />
            <div className="w-full">
              <Select
                className="rounded"
                value={activityType}
                size="large"
                style={{ width: '100%' }}
                onChange={(value: TActivityType) => {
                  setActivityType(value)
                }}
              >
                <Option value={'SIGN' as TActivityType}>{t('requestActivity:activityType.sign.name')}</Option>
                <Option value={'APPROVE' as TActivityType}>{t('requestActivity:activityType.approve.name')}</Option>
                <Option value={'ACKNOWLEDGE' as TActivityType}>
                  {t('requestActivity:activityType.acknowledge.name')}
                </Option>
              </Select>
            </div>
          </div>

          {activityType === 'SIGN' ? (
            <div>
              <div className="my-2">
                <FieldTitle title={t('requestActivity:trustLevel.label')} />
                <div className="w-full">
                  <Select
                    className="rounded"
                    value={trustLevel}
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(value: TTrustLevel) => {
                      setTrustLevel(value)
                    }}
                  >
                    <Option value={'SES' as TTrustLevel}>{t('requestActivity:trustLevel.ses.name')}</Option>
                    <Option value={'AES' as TTrustLevel}>{t('requestActivity:trustLevel.aes.name')}</Option>
                    <Option value={'QES' as TTrustLevel}>{t('requestActivity:trustLevel.qes.name')}</Option>
                  </Select>
                </div>
              </div>
            </div>
          ) : null}

          {/**
           * Message
           */}
          <FieldTitle title={t('requestActivity:message')} />
          <div className="flex w-full my-2">
            <TextArea
              ref={messageRef}
              className="rounded"
              size="middle"
              rows={5}
              value={activityMessage}
              onChange={(e) => setActivityMessage(e.target.value)}
            ></TextArea>
          </div>

          {/**
           * Add recipients (search field)
           */}
          <div>
            {/**
             * User retrieval
             */}
            <UserRetrieval onApply={handleCreateTask} onCancel={handleCancelUserRetrieval} />

            {/**
             * Activity tasks
             */}
            <div className="flex flex-row flex-wrap w-full mt-4">
              {activityTasks.map((task) => {
                return <ActivityTaskItem key={task._id} task={task} />
              })}
            </div>
          </div>

          <div className="flex flex-auto mt-8 justify-between">
            <Button className="rounded font-semibold" size="large" type="ghost" onClick={handleCancel}>
              {t('requestActivity:action.cancel')}
            </Button>

            {activity && activityTasks.length > 0 ? (
              <Button
                className="rounded font-semibold"
                size="large"
                type="primary"
                onClick={handleApply}
                loading={inProgress}
              >
                {t('requestActivity:action.apply')}
              </Button>
            ) : null}
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default RequestActivity
