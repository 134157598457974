import { useTranslation } from 'react-i18next'

const Terms = () => {
  const { t, i18n } = useTranslation('signup')

  return (
    <span className="pl-2 text-md font-medium">
      {i18n.language.toLowerCase().startsWith('de') ? (
        <a href={`${process.env.PUBLIC_URL}/assets/docs/actaSIGN_Nutzungsbestimmungen_Juli_2021_signiert.pdf`} rel="noreferrer" target="_blank">
          {t('signup:terms')}
        </a>
      ) : (
        <a href={`${process.env.PUBLIC_URL}/assets/docs/actaSIGN_Terms_of_use_July_2021_signed.pdf`} rel="noreferrer" target="_blank">
          {t('signup:terms')}
        </a>
      )}
    </span>
  )
}

export default Terms
