/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Button, Input, Select, Alert, message } from 'antd'
import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { useRecoilValue } from 'recoil'
import { userInfoState } from '../recoil/atoms'
import {
  CreateAccountUseCase,
  QueryAccountsUseCase,
  UpdateAccountBasicUseCase,
  UpdateAccountOrganizationUseCase,
} from '@axelity/actasign-sic'
import useSignIn from '../hooks/useSignIn'
import SectionTitle from '../elements/SectionTitle'
import FieldTitle from '../elements/FieldTitle'
import countries from 'i18n-iso-countries'
import * as yup from 'yup'

const { TextArea } = Input
const { Option } = Select

const ProfileAccounts = () => {
  const { t } = useTranslation(['profileAccounts', 'profile'])

  const [noAccessToken, forceUpdate] = useSignIn()

  const history = useHistory()

  const accountNameRef = useRef(null)

  const userInfo = useRecoilValue(userInfoState)

  const [accountName, setAccountName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [accountId, setAccountId] = useState<string>('')

  const [businessName, setBusinessName] = useState<string>('')
  const [country, setCountry] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [contactPerson, setContactPerson] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [website, setWebsite] = useState<string>('')

  const [countryList, setCountryList] = useState<countries.LocalizedCountryNames<{ select: 'official' }>>()

  type TFormErrors = {
    accountName: string
    description: string
    businessName: string
    country: string
    address: string
    contactPerson: string
    email: string
    phone: string
    website: string
  }

  const [errors, setErrors] = useState<TFormErrors>({
    accountName: '',
    description: '',
    businessName: '',
    country: '',
    address: '',
    contactPerson: '',
    email: '',
    phone: '',
    website: '',
  })

  const validateForm = (): boolean => {
    let valid = true
    let errors: TFormErrors = {
      accountName: '',
      description: '',
      businessName: '',
      country: '',
      address: '',
      contactPerson: '',
      email: '',
      phone: '',
      website: '',
    }

    if (!yup.string().min(4).required().isValidSync(accountName)) {
      errors = { ...errors, accountName: t('profileAccounts:validation.inputRequired') }
      valid = false
    }

    if (!yup.string().min(4).required().isValidSync(businessName)) {
      errors = { ...errors, businessName: t('profileAccounts:validation.inputRequired') }
      valid = false
    }

    if (!yup.string().min(2).max(2).required().isValidSync(country)) {
      errors = { ...errors, country: t('profileAccounts:validation.inputRequired') }
      valid = false
    }

    if (!yup.string().min(4).required().isValidSync(address)) {
      errors = { ...errors, address: t('profileAccounts:validation.inputRequired') }
      valid = false
    }

    if (!yup.string().min(4).required().isValidSync(contactPerson)) {
      errors = { ...errors, contactPerson: t('profileAccounts:validation.inputRequired') }
      valid = false
    }

    if (!yup.string().min(10).required().isValidSync(phone)) {
      errors = { ...errors, phone: t('profileAccounts:validation.inputRequired') }
      valid = false
    }

    if (
      !yup
        .string()
        .required()
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
        .isValidSync(email)
    ) {
      errors = { ...errors, email: t('profileAccounts:validation.inputRequired') }
      valid = false
    }

    setErrors(errors)

    return valid
  }
  const saveHandler = () => {
    if (!validateForm()) return

    if (accountId) {
      new UpdateAccountBasicUseCase({
        accountId,
      })
        .update({
          accountName,
          displayName: accountName,
          description,
        })
        .then(() => {
          return new UpdateAccountOrganizationUseCase({
            accountId,
          }).update({
            businessName,
            country,
            address,
            contactPerson,
            email,
            phone,
            website,
          })
        })
        .then(() => {
          return forceUpdate()
        })
        .then(() => {
          message.success(t('profileAccounts:updateSuccessful'))
          history.push('/home')
        })
        .catch((error) => {
          message.error(t('profileAccounts:updateFailed'))
          console.error(error)
        })
    } else {
      new CreateAccountUseCase()
        .create({
          accountName,
          displayName: accountName,
          description,
        })
        .then((response) => {
          setAccountId(response._id as string)

          return new UpdateAccountOrganizationUseCase({
            accountId: response._id,
          }).update({
            businessName,
            country,
            address,
            contactPerson,
            email,
            phone,
            website,
          })
        })
        .then(() => {
          return forceUpdate()
        })
        .then(() => {
          message.success(t('profileAccounts:creationSuccessful'))
          history.push('/home')
        })
        .catch((error) => {
          message.error(t('profileAccounts:creationFailed'))
          console.error(error)
        })
    }
  }

  const cancelHandler = () => {
    history.push('/home')
  }

  useEffect(() => {
    if (userInfo) {
      setCountryList(countries.getNames(userInfo.language, { select: 'official' }))

      // Lookup account the user is the primary admin (owner)
      if (userInfo.memberships) {
        const membership = userInfo.memberships.find((ms) => {
          return ms.roles.includes('PRIMARY-ADMIN')
        })

        if (membership) {
          new QueryAccountsUseCase()
            .query({
              id: membership.account,
            })
            .then((accounts) => {
              const account = accounts[0]
              setAccountId(account._id as string)
              setAccountName(account.accountName)
              setDescription(account.description || '')

              if (account.organization) {
                setBusinessName(account.organization.businessName)
                setAddress(account.organization.address || '')
                setCountry(account.organization.country || '')
                setContactPerson(account.organization.contactPerson || '')
                setPhone(account.organization.phone || '')
                setEmail(account.organization.email || '')
                setWebsite(account.organization.website || '')
              } else {
                setContactPerson(`${userInfo.firstName} ${userInfo.familyName}`)
                setEmail(`${userInfo.email?.emailAddress || ''}`)
              }
            })
            .catch((error) => {
              message.error(t('profileAccounts:creationFailed'))
              console.error(error)
            })
        }
      } else {
        setContactPerson(`${userInfo.firstName} ${userInfo.familyName}`)
        setEmail(`${userInfo.email?.emailAddress || ''}`)
      }

      accountNameRef.current.focus()
    }
  }, [userInfo])

  useEffect(() => {
    if (noAccessToken) history.replace('/signin')
  }, [noAccessToken])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={t('profile:accounts.name')}
        pages={[
          {
            name: t('profile:userProfile.name'),
          },
          {
            name: t('profile:accounts.name'),
          },
        ]}
      />

      <div className="container max-w-4xl mx-auto text-base bg-white border-2 border-gray-100 p-4 mt-4 shadow-sm rounded-md">
        {!accountId && userInfo ? (
          <Alert
            className="rounded my-1"
            message={t('profileAccounts:alert.warn')}
            description={t('profileAccounts:alert.addAccount')}
            showIcon
            type="warning"
          />
        ) : null}

        {/**
         * Title
         */}
        <SectionTitle title={t('profile:accounts.name')} />

        {/**
         * Name
         */}
        <FieldTitle title={t('profileAccounts:name')} />
        <div className="inline-flex w-full">
          <Input
            ref={accountNameRef}
            className="rounded"
            type="text"
            size="large"
            placeholder={t('profileAccounts:name')}
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
          ></Input>
        </div>
        <div className="my-1 font-semibold text-md text-red-500">{errors.accountName}</div>

        {/**
         * Description
         */}
        <FieldTitle title={t('profileAccounts:description')} />
        <div className="inline-flex w-full">
          <TextArea
            className="rounded"
            size="large"
            rows={3}
            placeholder={t('profileAccounts:description')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></TextArea>
        </div>
        <div className="my-1 font-semibold text-md text-red-500">{errors.description}</div>

        <div className="mt-8">
          {/**
           * Title
           */}
          <SectionTitle title={t('profileAccounts:organization')} />

          {/**
           * Business name
           */}
          <FieldTitle title={t('profileAccounts:businessName')} />
          <div className="inline-flex w-full">
            <Input
              className="rounded"
              autoComplete="organization"
              type="text"
              size="large"
              placeholder={t('profileAccounts:businessName')}
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            ></Input>
          </div>
          <div className="my-1 font-semibold text-md text-red-500">{errors.businessName}</div>

          {/**
           * Address
           */}
          <FieldTitle title={t('profileAccounts:address')} />
          <div className="inline-flex w-full">
            <TextArea
              className="rounded"
              autoComplete="street-address"
              size="large"
              rows={4}
              placeholder={t('profileAccounts:address')}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            ></TextArea>
          </div>
          <div className="my-1 font-semibold text-md text-red-500">{errors.address}</div>

          {/**
           * Country
           */}
          <div className="my-2">
            <FieldTitle title={t('profileAccounts:country')} />
            {countryList ? (
              <div className="w-full">
                <Select
                  className="rounded"
                  value={country}
                  size="large"
                  style={{ width: '100%' }}
                  onChange={(value) => setCountry(value)}
                >
                  {Object.entries(countryList).map(([key, value]) => {
                    return <Option key={key} value={key}>{value}</Option>
                  })}
                </Select>
              </div>
            ) : null}
          </div>
          <div className="my-1 font-semibold text-md text-red-500">{errors.country}</div>

          {/**
           * Contact person
           */}
          <FieldTitle title={t('profileAccounts:contactPerson')} />
          <div className="inline-flex w-full">
            <Input
              className="rounded"
              autoComplete="name"
              type="text"
              size="large"
              placeholder={t('profileAccounts:contactPerson')}
              value={contactPerson}
              onChange={(e) => setContactPerson(e.target.value)}
            ></Input>
          </div>
          <div className="my-1 font-semibold text-md text-red-500">{errors.contactPerson}</div>

          <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            {/**
             * Phone
             */}
            <div className="sm:col-span-3">
              <FieldTitle title={t('profileAccounts:phone')} />
              <div className="inline-flex w-full">
                <Input
                  className="rounded"
                  autoComplete="tel"
                  type="tel"
                  size="large"
                  placeholder={t('profileAccounts:phone')}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                ></Input>
              </div>
              <div className="my-1 font-semibold text-md text-red-500">{errors.phone}</div>
            </div>

            {/**
             * Email
             */}
            <div className="sm:col-span-3">
              <FieldTitle title={t('profileAccounts:email')} />
              <div className="inline-flex w-full">
                <Input
                  className="rounded"
                  autoComplete="email"
                  type="email"
                  size="large"
                  placeholder={t('profileAccounts:email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Input>
              </div>
              <div className="my-1 font-semibold text-md text-red-500">{errors.email}</div>
            </div>
          </div>

          {/**
           * Website
           */}
          <FieldTitle title={t('profileAccounts:website')} />
          <div className="inline-flex w-full">
            <Input
              className="rounded"
              autoComplete="url"
              addonBefore="https://"
              type="text"
              size="large"
              placeholder={t('profileAccounts:website')}
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            ></Input>
          </div>
        </div>
        <div className="my-1 font-semibold text-md text-red-500">{errors.website}</div>

        <div className="flex flex-auto mt-4 justify-end">
          <Button className="ml-2 rounded font-semibold" size="large" type="ghost" onClick={cancelHandler}>
            {t('profileAccounts:back')}
          </Button>

          <Button className="ml-2 rounded font-semibold" size="large" type="primary" onClick={saveHandler}>
            {accountId ? t('profileAccounts:update') : t('profileAccounts:save')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ProfileAccounts
