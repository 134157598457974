import { useEffect, useState, useRef } from 'react'
import { Input, Modal, Select, Button } from 'antd'
import { useRecoilState } from 'recoil'
import { currentTextLineState, showVisualTextLineDialogState, visualState } from '../../recoil/atoms'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'
import FieldTitle from '../../elements/FieldTitle'

const { Option } = Select

const VisualTextLine = () => {
  const { t } = useTranslation(['profileVisual'])

  const displayTextRef = useRef(null)

  const [visual, setVisual] = useRecoilState(visualState)
  const [currentTextLine, setCurrentTextLine] = useRecoilState(currentTextLineState)

  const [showVisualTextLineDialog, setShowVisualTextLineDialog] = useRecoilState(showVisualTextLineDialogState)

  const [displayText, setDisplayText] = useState<string>('')
  const [fontStyle, setFontStyle] = useState<string>('A')
  const [x, setX] = useState<number>(0)
  const [y, setY] = useState<number>(0)

  const handleTextLineOk = () => {
    const _visual = cloneDeep(visual)
    if (!_visual.textLines) {
      _visual.textLines = []
    }
    if (currentTextLine !== -1) {
      _visual.textLines[currentTextLine] = {
        displayText,
        fontStyle,
        x,
        y,
      }
    } else {
      _visual.textLines = [
        ..._visual.textLines,
        {
          displayText,
          fontStyle,
          x,
          y,
        },
      ]
    }
    setVisual(_visual)

    setShowVisualTextLineDialog(false)
  }

  const handleTextLineRemove = () => {
    const _visual = cloneDeep(visual)
    _visual.textLines?.splice(currentTextLine, 1)
    setVisual(_visual)
    setCurrentTextLine(-1)
    setShowVisualTextLineDialog(false)
  }

  const handleTextLineCancel = () => {
    setShowVisualTextLineDialog(false)
  }

  const addVariable = (text: string) => {
    setDisplayText(displayText.concat(text))
    displayTextRef.current.focus()
  }

  useEffect(() => {
    if (currentTextLine !== -1 && visual && visual.textLines && visual.textLines.length > 0) {
      setDisplayText(visual.textLines[currentTextLine].displayText)
      setFontStyle(visual.textLines[currentTextLine].fontStyle)
      setX(visual.textLines[currentTextLine].x)
      setY(visual.textLines[currentTextLine].y)
    } else {
      setDisplayText('')
      setFontStyle('A')
      setX(0)
      setY(0)
    }
  }, [visual, currentTextLine, showVisualTextLineDialog])

  return (
    <Modal
      title={t('profileVisual:textLine.title')}
      visible={showVisualTextLineDialog}
      footer={
        currentTextLine === -1
          ? [
              <Button className="rounded font-semibold" key="cancel" onClick={handleTextLineCancel}>
                {t('profileVisual:textLine.cancel')}
              </Button>,
              <Button className="rounded font-semibold" key="apply" type="primary" onClick={handleTextLineOk}>
                {t('profileVisual:textLine.apply')}
              </Button>,
            ]
          : [
              <Button
                className="rounded font-semibold"
                type="primary"
                danger={true}
                key="remove"
                onClick={handleTextLineRemove}
              >
                {t('profileVisual:textLine.remove')}
              </Button>,
              <Button className="rounded font-semibold" key="cancel" onClick={handleTextLineCancel}>
                {t('profileVisual:textLine.cancel')}
              </Button>,
              <Button className="rounded font-semibold" key="apply" type="primary" onClick={handleTextLineOk}>
                {t('profileVisual:textLine.apply')}
              </Button>,
            ]
      }
    >
      {/**
       * Display text
       */}
       <FieldTitle title={t('profileVisual:textPlaceholder.title')} />
      <div className="grid grid-cols-2 text-xs">
        <p>{t('profileVisual:textPlaceholder.signer')}</p>
        <p className="hover:cursor-pointer" onClick={() => addVariable(`{{SIGNER}}`)}>{`{{SIGNER}}`}</p>
        <p>{t('profileVisual:textPlaceholder.date')}</p>
        <p className="hover:cursor-pointer" onClick={() => addVariable(`{{DATE}}`)}>{`{{DATE}}`}</p>
        <p>{t('profileVisual:textPlaceholder.role')}</p>
        <p className="hover:cursor-pointer" onClick={() => addVariable(`{{ROLE}}`)}>{`{{ROLE}}`}</p>
        <p>{t('profileVisual:textPlaceholder.organization')}</p>
        <p className="hover:cursor-pointer" onClick={() => addVariable(`{{ORGANIZATION}}`)}>{`{{ORGANIZATION}}`}</p>
        <p>{t('profileVisual:textPlaceholder.reason')}</p>
        <p className="hover:cursor-pointer" onClick={() => addVariable(`{{REASON}}`)}>{`{{REASON}}`}</p>
        <p>{t('profileVisual:textPlaceholder.place')}</p>
        <p className="hover:cursor-pointer" onClick={() => addVariable(`{{PLACE}}`)}>{`{{PLACE}}`}</p>
        <p>{t('profileVisual:textPlaceholder.userText')}</p>
        <p className="hover:cursor-pointer" onClick={() => addVariable(`{{USERTEXT}}`)}>{`{{USERTEXT}}`}</p>
      </div>

      <FieldTitle title={t('profileVisual:textLine.displayText')} />
      <Input
        ref={displayTextRef}
        className="rounded"
        size="large"
        type="text"
        value={displayText}
        onChange={(e) => setDisplayText(e.target.value)}
      ></Input>

      {/**
       * Font style
       */}
      <FieldTitle title={t('profileVisual:textLine.fontStyle')} />
      <div className="w-full">
        <Select
          className="rounded"
          value={fontStyle}
          size="large"
          style={{ width: '100%' }}
          onChange={(value) => setFontStyle(value)}
        >
          <Option value={'A'}>{t('profileVisual:fontStyle.typeA')}</Option>
          <Option value={'B'}>{t('profileVisual:fontStyle.typeB')}</Option>
        </Select>
      </div>
    </Modal>
  )
}

export default VisualTextLine
