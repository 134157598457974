import { useEffect, useState } from 'react'
import { Button, Input, Modal, Radio } from 'antd'
import { useRecoilState } from 'recoil'
import { showVisualBackgroundDialogState, visualState } from '../../recoil/atoms'
import { useTranslation } from 'react-i18next'
import { InboxInIcon } from '@heroicons/react/outline'
import { cloneDeep } from 'lodash'
import { TFileInfo } from '../../types/appTypes'

type TBackground = 'color' | 'transparent' | 'image'

const VisualBackground = () => {
  const { t } = useTranslation(['profileVisual'])

  const [visual, setVisual] = useRecoilState(visualState)

  const [showVisualBackgrounDialog, setShowVisualBackgroundDialog] = useRecoilState(showVisualBackgroundDialogState)

  const [backgroundType, setBackgroundType] = useState<TBackground>('color')
  const [isTransparent, setTransparent] = useState<boolean>(true)
  const [colorHex, setColorHex] = useState<string | undefined>('#ffffff')
  const [imageDataUrl, setImageDataUrl] = useState<string | undefined>(undefined)

  const handleBackgroundOk = () => {
    const _visual = cloneDeep(visual)
    setVisual(
      Object.assign(_visual, {
        background: {
          colorHex,
          imageDataUrl,
          isTransparent,
        },
      }),
    )

    setShowVisualBackgroundDialog(false)
  }

  const handleBackgroundCancel = () => {
    setShowVisualBackgroundDialog(false)
  }

  const handleChangeBackgroundType = (e: any) => {
    setBackgroundType(e.target.value)

    if ((e.target.value as TBackground) === 'color') {
      setTransparent(false)
      setColorHex(colorHex)
      setImageDataUrl(undefined)
    } else if ((e.target.value as TBackground) === 'image') {
      setTransparent(false)
      setColorHex(undefined)
      setImageDataUrl(imageDataUrl)
    } else {
      setTransparent(true)
      setColorHex(undefined)
      setImageDataUrl(undefined)
    }
  }

  const handleDragOver = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    event.dataTransfer.dropEffect = 'copy'
  }

  const openBackgroundImageDialog = () => {
    const ele = document.getElementById('inputBackgroundImageFile')
    if (ele) ele.click()
  }

  const handleDropBackgroundImage = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      loadFiles(event.dataTransfer.files).then((loadedFiles: TFileInfo[]) => {
        setImageDataUrl(loadedFiles[0].content as string)
      })
    }
  }

  const handleBackgroundImageSelect = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    if (event.target && event.target.files && event.target.files.length > 0) {
      loadFiles(event.target.files).then((loadedFiles: TFileInfo[]) => {
        setImageDataUrl(loadedFiles[0].content as string)
      })
    }
  }

  const loadFiles = async (files: any): Promise<TFileInfo[]> => {
    let loadedFiles: TFileInfo[] = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      let promise: any = new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = (result: any) => {
          let fileInfo: TFileInfo = {
            content: result?.currentTarget?.result,
            mimeType: file.type,
            filename: file.name,
            filesize: file.size,
            lastModified: file.lastModified,
          }
          resolve(fileInfo)
        }
        reader.readAsDataURL(file)
      })

      let fileInfo = await promise
      loadedFiles = [...loadedFiles, fileInfo]
    }
    return loadedFiles
  }

  useEffect(() => {
    if (visual && visual.background) {
      if (visual.background.isTransparent) {
        setBackgroundType('transparent')
      } else if (visual.background.colorHex) {
        setBackgroundType('color')
      } else {
        setBackgroundType('image')
      }
      setTransparent(visual.background.isTransparent)
      setColorHex(visual.background.colorHex || '#ffffff')
      setImageDataUrl(visual.background.imageDataUrl || '')
    } else {
      setTransparent(true)
      setColorHex('#ffffff')
      setImageDataUrl('')
    }
  }, [visual, showVisualBackgrounDialog])

  return (
    <Modal
      title={t('profileVisual:background.title')}
      visible={showVisualBackgrounDialog}
      footer={[
        <Button className="rounded font-semibold" key="cancel" onClick={handleBackgroundCancel}>
          {t('profileVisual:background.cancel')}
        </Button>,
        <Button className="rounded font-semibold" key="apply" type="primary" onClick={handleBackgroundOk}>
          {t('profileVisual:background.apply')}
        </Button>,
      ]}
    >
      {/**
       * Background type
       */}
      <Radio.Group
        buttonStyle="solid"
        defaultValue={backgroundType}
        onChange={handleChangeBackgroundType}
        className="mb-4"
      >
        <Radio.Button value={'transparent' as TBackground}>{t('profileVisual:background.transparent')}</Radio.Button>
        <Radio.Button value={'color' as TBackground}>{t('profileVisual:background.color')}</Radio.Button>
        <Radio.Button value={'image' as TBackground}>{t('profileVisual:background.image')}</Radio.Button>
      </Radio.Group>

      {backgroundType === 'color' ? (
        <div>
          {/**
           * Background color
           */}
          <p className="mt-3 font-semibold">{t('profileVisual:background.color')}</p>
          <div className="flex w-full">
            <div className="w-11">
              <Input
                type="color"
                size="large"
                placeholder={t('profileVisual:background.color')}
                value={colorHex}
                onChange={(e) => setColorHex(e.target.value)}
              ></Input>
            </div>
            <p className="ml-2 mt-2 text-base font-medium">{colorHex}</p>
          </div>
        </div>
      ) : null}

      {/**
       * Background image
       */}
      {backgroundType === 'image' ? (
        <div className="mt-2">
          {/**
           * Drop zone
           */}
          <p className="mt-2 font-semibold">{t('profileVisual:background.upload')}</p>
          <div className="w-full my-4">
            <div
              className="relative cursor-pointer p-4 border-2 border-dotted flex flex-col content-center items-center justify-center"
              onDragOver={handleDragOver}
              onDrop={handleDropBackgroundImage}
              onClick={openBackgroundImageDialog}
            >
              <InboxInIcon className="h-5 w-5 text-antd-blue" />
              <p className="text-base text-center mt-2">{t('profileVisual:background.uploadInstructions')}</p>
              <p className="text-sm mt-2">{t('profileVisual:background.picturesOnly')}</p>
              <input
                type="file"
                id="inputBackgroundImageFile"
                accept="image/*"
                multiple={false}
                onChange={handleBackgroundImageSelect}
                hidden
              />
            </div>
          </div>

          {/**
           * Preview
           */}
          <div className="w-full my-4">
            <p className="mt-2 font-semibold">{t('profileVisual:background.preview')}</p>
            {imageDataUrl ? (
              <img src={imageDataUrl} className="object-scale-down h-24 w-full" alt="background" />
            ) : null}
          </div>
        </div>
      ) : null}
    </Modal>
  )
}

export default VisualBackground
