import { useTranslation } from 'react-i18next'
import { Spin } from 'antd'
import SectionTitle from '../elements/SectionTitle'

type TConsentAuthenticationProps = {
  inProgress: boolean
  consentUrl?: string
  message?: string
}

const ConsentAuthentication = ({ inProgress, consentUrl, message }: TConsentAuthenticationProps) => {
  const { t } = useTranslation(['consent'])

  return (
    <div>
      {inProgress ? (
        <div className="border-2 border-gray-100 mt-4 p-4 shadow-sm rounded-md">
          <SectionTitle title={t('consent:authentication')}/>
          <div className="mt-2 mb-4 font-normal text-base text-gray-700">{t('consent:instructions.general')}</div>

          <div style={{ width: '600px', height: '500px' }} className="bg-gray-50 m-auto">
            {!consentUrl ? (
              <div className="flex flex-col justify-between text-center p-8">
                <p className="text-2xl">{t('consent:instructions.followMobile')}</p>
                <p className="my-4 ">{message}</p>
                <div className="pt-8">
                  <Spin spinning={inProgress} size="large" />
                </div>
              </div>
            ) : (
              <object data={consentUrl} type="text/html" width="600px" height="500px">
                Swisscom AIS Authentication Dialog
              </object>
            )}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default ConsentAuthentication
