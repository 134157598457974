import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Transition } from '@headlessui/react'
import { AutoComplete, Button, Select, Input, message } from 'antd'
import * as yup from 'yup'
import { CreateUserUseCase, IUserInfo, QueryUsedRecipientsUseCase, QueryUsersUseCase } from '@axelity/actasign-sic'
import SectionTitle from '../elements/SectionTitle'
import FieldTitle from '../elements/FieldTitle'
import { useRecoilValue } from 'recoil'
import { userInfoState } from '../recoil/atoms'

const { Option } = Select

type TUserRetrievalProps = {
  onCancel: () => void
  onApply: (user: IUserInfo) => void
}

const UserRetrieval = ({ onCancel, onApply }: TUserRetrievalProps) => {
  const { t, i18n } = useTranslation(['common', 'userRetrieval'])

  const firstNameRef = useRef(null)
  const searchFieldRef = useRef(null)
  const searchButtonRef = useRef(null)

  const currentUser = useRecoilValue(userInfoState)

  const [usedRecipients, setUsedRecipients] = useState<{ value: string }[]>([])

  const [searchValue, setSearchValue] = useState<string>('')
  const [canSearch, setCanSearch] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)

  const [showNewUserDialog, setShowNewUserDialog] = useState<boolean>(false)

  const [firstName, setFirstName] = useState<string>('')
  const [familyName, setFamilyName] = useState<string>('')
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [language, setLanguage] = useState<string>(i18n.language.substr(0, 2))

  type TNewUserFormErrors = {
    firstName: string
    familyName: string
    emailAddress: string
    language: string
  }

  const [newUserErrors, setNewUserErrors] = useState<TNewUserFormErrors>({
    firstName: '',
    familyName: '',
    emailAddress: '',
    language: '',
  })

  const resetFields = () => {
    setFirstName('')
    setFamilyName('')
    setEmailAddress('')
    setLanguage(i18n.language.substr(0, 2))
  }

  const resetErrors = () => {
    setNewUserErrors({
      firstName: '',
      familyName: '',
      emailAddress: '',
      language: '',
    })
  }

  const handleChangeSearchValue = (value: string) => {
    setSearchValue(value)
    if (
      yup
        .string()
        .required()
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
        .isValidSync(value)
    ) {
      setCanSearch(true)
    } else {
      setCanSearch(false)
    }
  }

  const handleSelectSearchValue = (value: string) => {
    console.log(`handleSelectSearchValue: ${value}`)
    lookupUser(value)
  }

  const addMyself = () => {
    setLoading(true)

    new QueryUsersUseCase()
      .query({
        emailAddress: currentUser.email.emailAddress,
      })
      .then((users) => {
        if (users.length > 0) {
          setSearchValue('')
          searchFieldRef.current.focus()
          onApply(users[0])
        } else {
          setFirstName('')
          setFamilyName('')
          setEmailAddress(searchValue)
          setShowNewUserDialog(true)
          setCanSearch(false)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const lookupUser = (value?: string) => {
    if (!value && !canSearch) return

    setLoading(true)

    new QueryUsersUseCase()
      .query({
        emailAddress: value ?? searchValue,
      })
      .then((users) => {
        if (users.length > 0) {
          setSearchValue('')
          searchFieldRef.current.focus()
          onApply(users[0])
        } else {
          setFirstName('')
          setFamilyName('')
          setEmailAddress(searchValue)
          setShowNewUserDialog(true)
          setCanSearch(false)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCancel = () => {
    resetFields()
    resetErrors()
    setShowNewUserDialog(false)
    onCancel()
  }

  const handleApply = () => {
    // validpplyion
    if (validateNewUserForm()) {
      new CreateUserUseCase()
        .create({
          emailAddress,
          familyName,
          firstName,
          language,
        })
        .then((userInfo) => {
          setShowNewUserDialog(false)
          onApply(userInfo)
          message.success(t('userRetrieval:success.newUser'))
        })
        .catch((error) => {
          console.error(error)
          message.error(t('userRetrieval:fail.newUser'))
        })
    }
  }

  const validateNewUserForm = (): boolean => {
    let valid = true
    let errors: TNewUserFormErrors = {
      firstName: '',
      familyName: '',
      emailAddress: '',
      language: '',
    }

    if (!yup.string().trim().min(1).required().isValidSync(firstName)) {
      errors = { ...errors, firstName: t('userRetrieval:validation.validInputRequired') }
      valid = false
    }

    if (!yup.string().trim().min(1).required().isValidSync(familyName)) {
      errors = { ...errors, familyName: t('userRetrieval:validation.validInputRequired') }
      valid = false
    }

    if (
      !yup
        .string()
        .required()
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
        .isValidSync(emailAddress)
    ) {
      errors = { ...errors, emailAddress: t('userRetrieval:validation.validInputRequired') }
      valid = false
    }

    if (!yup.string().trim().min(2).isValidSync(language)) {
      errors = { ...errors, language: t('userRetrieval:validation.validInputRequired') }
      valid = false
    }

    setNewUserErrors(errors)

    return valid
  }

  useEffect(() => {
    new QueryUsedRecipientsUseCase()
      .query()
      .then((emailAddresses) => {
        setUsedRecipients(
          emailAddresses.map((value) => {
            return { value }
          }),
        )
      })
      .catch((error) => {
        console.error(error)
        setUsedRecipients([])
      })
  }, [])

  return (
    <div className="mt-8">
      {/**
       * Add recipients (search field)
       */}
      <div className="flex items-center">
        <SectionTitle title={t('userRetrieval:title')} />

        <Button
          className="ml-4 mt-3 rounded font-semibold"
          size="small"
          type="primary"
          onClick={(e) => addMyself()}
          loading={isLoading}
        >
          {t('userRetrieval:action.addMyself')}
        </Button>
      </div>

      {!showNewUserDialog ? (
        <div className="flex w-full my-2">
          {/* <Input
            className="rounded"
            ref={searchFieldRef}
            type="email"
            size="large"
            placeholder={t('userRetrieval:searchInstructions')}
            value={searchValue}
            onChange={(e) => handleChangeSearchValue(e.target.value)}
            onPressEnter={lookupUser}
            disabled={showNewUserDialog}
          ></Input> */}

          <AutoComplete
            style={{ width: '100%'}}
            ref={searchFieldRef}
            className="rounded"
            size="large"
            options={usedRecipients}
            placeholder={t('userRetrieval:searchInstructions')}
            value={searchValue}
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={(value) => handleChangeSearchValue(value)}
            onSelect={(value) => handleSelectSearchValue(value)}
          ></AutoComplete>

          <Button
            ref={searchButtonRef}
            className="ml-2 rounded font-semibold"
            disabled={!canSearch || showNewUserDialog}
            size="large"
            type="primary"
            onClick={(e) => lookupUser()}
            loading={isLoading}
          >
            {t('userRetrieval:action.search')}
          </Button>
        </div>
      ) : null}

      {/**
       * New user
       */}
      <Transition
        show={showNewUserDialog}
        enter="transition ease duration-700 transform"
        enterFrom="opacity-0 -translate-y-0"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease duration-300 transform"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-0"
        afterEnter={() => firstNameRef.current.focus()}
      >
        <div className="mt-3 p-2 rounded shadow-sm border border-gray-100">
          <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            {/**
             * First name
             */}
            <div className="sm:col-span-3">
              <FieldTitle title={t('userRetrieval:firstName')} />
              <Input
                ref={firstNameRef}
                className="rounded"
                size="large"
                placeholder={t('userRetrieval:firstName')}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <div className="my-1 font-semibold text-md text-red-500">{newUserErrors.firstName}</div>
            </div>

            {/**
             * Family name
             */}
            <div className="sm:col-span-3">
              <FieldTitle title={t('userRetrieval:familyName')} />
              <Input
                className="rounded"
                size="large"
                placeholder={t('userRetrieval:familyName')}
                value={familyName}
                onChange={(e) => setFamilyName(e.target.value)}
                onPressEnter={handleApply}
              />
              <div className="my-1 font-semibold text-md text-red-500">{newUserErrors.familyName}</div>
            </div>
          </div>

          <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            {/**
             * Email address
             */}
            <div className="sm:col-span-3">
              <FieldTitle title={t('userRetrieval:emailAddress')} />
              <Input
                className="rounded"
                type="email"
                size="large"
                placeholder={t('userRetrieval:emailAddress')}
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
              <div className="my-1 font-semibold text-md text-red-500">{newUserErrors.emailAddress}</div>
            </div>

            {/**
             * Language
             */}
            <div className="sm:col-span-3">
              <FieldTitle title={t('userRetrieval:language')} />
              <div className="w-full">
                <Select
                  className="rounded"
                  value={language}
                  size="large"
                  style={{ width: '100%' }}
                  onChange={(value) => setLanguage(value)}
                >
                  <Option value="de">{t('common:language.german')}</Option>
                  <Option value="en">{t('common:language.english')}</Option>
                  {/* <Option value="fr">{t('common:language.french')}</Option> */}
                </Select>
              </div>
              <div className="my-1 font-semibold text-md text-red-500">{newUserErrors.language}</div>
            </div>
          </div>

          <div className="flex flex-auto mt-4 justify-between">
            <Button className="rounded font-semibold" size="large" type="ghost" onClick={handleCancel}>
              {t('userRetrieval:action.cancel')}
            </Button>

            <Button className="rounded font-semibold" size="large" type="primary" onClick={handleApply}>
              {t('userRetrieval:action.apply')}
            </Button>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default UserRetrieval
