/* eslint-disable import/first */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import NavBar from '../components/NavBar'
import PageHeader from '../components/PageHeader'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  accessTokenState,
  currentAccountState,
  fastSignatureState,
  taskState,
  userInfoState,
  visualListState,
  processingInviteTaskState,
} from '../recoil/atoms'
import useSignIn from '../hooks/useSignIn'
import { useEffect } from 'react'
import { useState } from 'react'
import {
  CreateConsentUseCase,
  GetUserUseCase,
  IConsent,
  ICreateConsentRequest,
  IVisual,
  PollConsentUseCase,
  QueryActivitiesUseCase,
  QueryTasksUseCase,
  QueryVisualsUseCase,
  ReleaseActivityUseCase,
  TJurisdiction,
  TMemberPlan,
  TTrustLevel,
  UpdateUserEvidenceUseCase,
} from '@axelity/actasign-sic'
import { Button, Input, Select, Spin, message, Alert, Progress } from 'antd'
import SignaturePreview from '../components/SignaturePreview'
import ConsentAuthentication from '../components/ConsentAuthentication'
import { PlusCircleIcon, MinusCircleIcon, CheckCircleIcon } from '@heroicons/react/outline'
import moment from 'moment'
import SectionTitle from '../elements/SectionTitle'
import FieldTitle from '../elements/FieldTitle'
import MobileConfirmation from '../components/MobileConfirmation'

const { Option } = Select

const MAX_POLLING = 90

const Consent = () => {
  const search = useLocation().search

  let queryTaskId = new URLSearchParams(search).get('task')
  let queryAccessToken = new URLSearchParams(search).get('access_token')

  const { t } = useTranslation(['consent'])

  const [noAccessToken] = useSignIn()

  const history = useHistory()

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState)

  const [userInfo, setUserInfo] = useRecoilState(userInfoState)
  const account = useRecoilValue(currentAccountState)
  const [task, setTask] = useRecoilState(taskState)

  const [visuals, setVisuals] = useRecoilState(visualListState)
  const setFastSignature = useSetRecoilState(fastSignatureState)
  const resetAccessToken = useResetRecoilState(accessTokenState)
  const resetUserInfo = useResetRecoilState(userInfoState)
  const processingInviteTask = useRecoilValue(processingInviteTaskState)

  const [inProgress, setInProgress] = useState<boolean>(false)
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [showAuthentication, setShowAuthentication] = useState<boolean>(false)
  const [consentUrl, setConsentUrl] = useState<string>(undefined)

  const [title, setTitle] = useState<string>('')
  const [trustLevel, setTrustLevel] = useState<TTrustLevel>('SES')
  const [jurisdiction, setJurisdiction] = useState<TJurisdiction>('ZERTES')
  const [visualId, setVisualId] = useState<string>('')
  const [visual, setVisual] = useState<IVisual>(undefined)
  const [organization, setOrganization] = useState<string>('')
  const [reason, setReason] = useState<string>('')
  const [role, setRole] = useState<string>('')
  const [place, setPlace] = useState<string>('')
  const [userText, setUserText] = useState<string>('')
  const [consentMessage, setConsentMessage] = useState<string>('')
  const [timeLeft, setTimeLeft] = useState<number>(100)

  const [useFasttrack, setUseFasttrack] = useState<boolean>(false)

  const [alertMobile, setAlertMobile] = useState<boolean>(false)
  const [alertSwissMobile, setAlertSwissMobile] = useState<boolean>(false)
  const [alertIdentification, setAlertIdentification] = useState<boolean>(false)
  const [alertBlocked, setAlertBlocked] = useState<boolean>(false)
  const [scrollDown, setScrollDown] = useState<boolean>(false)

  const isActivityBlocked = async (): Promise<boolean> => {
    setAlertBlocked(false)
    const _activities = await new QueryActivitiesUseCase().query({
      id: task.activity as string,
    })

    if (_activities[0].status !== 'ACTIVE' && moment(_activities[0].updated).diff(new Date(), 'seconds') * -1 < 60) {
      setAlertBlocked(true)
      setScrollDown(true)
      return true
    }

    return false
  }

  const verifyEvidence = async () => {
    let _isSwiss = false

    setAlertMobile(false)
    setAlertSwissMobile(false)
    setAlertIdentification(false)
    setScrollDown(false)

    // if (!userInfo.memberships) {
    //   // Guest
    //   _isGuest = true
    // } else {
    //   // Member
    // }

    // With SES we don't need a mobile number
    if (trustLevel === 'SES') {
      return true
    }

    // Check if mobile number is available
    if (!userInfo.mobile || !userInfo.mobile.lastVerified) {
      setAlertMobile(true)
      setScrollDown(true)
      return
    }

    // Check, if mobile number is a Swiss one
    if (
      userInfo.mobile.phoneNumber.startsWith('00417') ||
      userInfo.mobile.phoneNumber.startsWith('+417') ||
      userInfo.mobile.phoneNumber.startsWith('417')
    ) {
      _isSwiss = true
    }

    // We go with the fast track option
    // if (trustLevel === 'AES' && _isGuest && _isSwiss) {
    if (trustLevel === 'AES' && _isSwiss) {
      setJurisdiction('ZERTES')
      setUseFasttrack(true)
      return true
    }
    if (trustLevel === 'AES' && !_isSwiss) {
      setAlertSwissMobile(true)
      return true
    }

    // We update the evidence information once a day
    let doUpdate = false
    if (userInfo.evidence && userInfo.evidence.lastVerified) {
      if (!moment().isSame(userInfo.evidence.lastVerified, 'day')) {
        doUpdate = true
      }
    } else {
      doUpdate = true
    }

    let _trustLevel: TTrustLevel
    let _trustLevelEIDAS: TTrustLevel
    let _jurisdictions: TJurisdiction[]
    if (doUpdate) {
      const response = await new UpdateUserEvidenceUseCase({
        userId: userInfo._id,
      }).update()

      _trustLevel = response.trustLevel as TTrustLevel
      _trustLevelEIDAS = response.trustLevelEIDAS as TTrustLevel
      _jurisdictions = response.jurisdictions as TJurisdiction[]

      // Update user information
      const _userInfo = await new GetUserUseCase().getUser()
      setUserInfo(_userInfo)
    } else {
      _trustLevel = userInfo.evidence.trustLevel
      _trustLevelEIDAS = userInfo.evidence.trustLevelEIDAS
      _jurisdictions = userInfo.evidence.jurisdictions
    }

    // User has not undergone an identification (yet) but has a Swiss mobile number
    // so we use the fast track method
    // if (_isSwiss && trustLevel === 'AES' && jurisdiction === 'ZERTES') {
    // if (_isSwiss && trustLevel === 'AES') {
    //   setAESnoIdent(true)
    //   return true
    // }

    // ZertES advanced or qualified
    if (jurisdiction === 'ZERTES' && _jurisdictions.includes('ZERTES')) {
      // if (trustLevel === 'AES' && (_trustLevel === 'AES' || _trustLevel === 'QES')) {
      //   return true
      // }
      if (trustLevel === 'QES' && _trustLevel === 'QES') {
        return true
      }
    }

    // eIDAS advanced or qualified
    if (jurisdiction === 'EIDAS' && _jurisdictions.includes('EIDAS')) {
      // if (trustLevel === 'AES' && (_trustLevelEIDAS === 'AES' || _trustLevelEIDAS === 'QES')) {
      //   return true
      // }
      if (trustLevel === 'QES' && _trustLevelEIDAS === 'QES') {
        return true
      }
    }

    setAlertIdentification(true)
    setScrollDown(true)

    // otherwise
    return false
  }

  const retrieveVisuals = async (): Promise<void> => {
    let _visuals = await new QueryVisualsUseCase().query({})
    if (_visuals && _visuals.length > 0) {
      _visuals = _visuals.filter((_visual) => {
        return _visual.status !== 'CLOSED'
      })
      setVisuals(_visuals)

      const _visualId = localStorage.getItem(`${userInfo._id}.visualId`)
      const _visual = _visuals.find((_visual) => {
        return _visual._id === _visualId
      })
      if (_visual) {
        setVisualId(_visual._id)
        setVisual(_visual)
      } else {
        setVisualId(_visuals[0]._id)
        setVisual(_visuals[0])
        localStorage.setItem(`${userInfo._id}.visualId`, _visuals[0]._id)
      }
    } else {
      setVisualId('')
    }
  }

  const pollAuthentication = async (consent: IConsent): Promise<IConsent> => {
    let pollConsent: IConsent
    let pollStatus = 'PENDING'
    let done = false
    let counter = MAX_POLLING - 1 // -1 so that the tick is not displayed

    do {
      counter--

      if (counter === 0) {
        pollStatus = 'TIMEOUT'
        done = true
      } else {
        setTimeLeft(Math.round((counter * 100) / MAX_POLLING))
        pollConsent = await new Promise((resolve) =>
          setTimeout(
            () => {
              new PollConsentUseCase({
                consentId: consent._id,
              })
                .poll()
                .then((_consent: IConsent) => {
                  resolve(_consent)
                })
            },
            1000,
            consent,
          ),
        )
        pollStatus = pollConsent.status
      }

      if (pollStatus !== 'PENDING') {
        done = true
      }
    } while (!done)

    return pollConsent
  }

  const signDocument = async () => {
    if (await isActivityBlocked()) return

    // reason with SES
    let _reason = localStorage.getItem(`${userInfo._id}.reason`)

    if (!_reason || _reason === null || _reason.trim() === '') {
      _reason = t('consent:defaultReason', {
        fullname: `${userInfo.firstName} ${userInfo.familyName}`,
        emailAddress: `${userInfo.email?.emailAddress}`,
      })
      // _reason = t('consent:defaultReasonMobile', {
      //   fullname: `${userInfo.firstName} ${userInfo.familyName}`,
      //   emailAddress: `${userInfo.email?.emailAddress}`,
      //   mobileNumber: `${userInfo.mobile?.phoneNumber}`,
      // })
    }

    const evidenceOk = await verifyEvidence()
    if (!evidenceOk) return

    const _transactionId = `${task._id.substr(0, 6)}-${task._id.substr(task._id.length - 6)}`.toUpperCase()
    const _message = t('consent:instructions.giveConsent', { transactionId: _transactionId })
    setConsentMessage(_message)

    let createConsentRequest: ICreateConsentRequest = {
      tasks: [task._id],
      user: userInfo._id,
      visual: visual._id,
      jurisdiction: trustLevel === 'SES' ? 'EIDAS' : jurisdiction,
      language: userInfo.language,
      message: trustLevel !== 'SES' ? _message : undefined,
      mobile: trustLevel !== 'SES' ? userInfo.mobile.phoneNumber : undefined,
      useFasttrack: trustLevel === 'AES' && useFasttrack ? true : false,
      organization,
      place,
      reason: _reason,
      role,
      trustLevel,
      userText,
    }

    let _consent = await new CreateConsentUseCase().create(createConsentRequest)

    if (_consent.status === 'PENDING') {
      if (_consent.providerInfo.consentUrl) {
        setConsentUrl(_consent.providerInfo.consentUrl)
      }
      setShowAuthentication(true)

      _consent = await pollAuthentication(_consent)
    }

    if (_consent.status === 'FAILED') {
      setShowAuthentication(false)
      console.error('consent failed')
      message.error(t('consent:result.fail'))
    } else if (_consent.status === 'CANCELLED') {
      setShowAuthentication(false)
      console.error('consent was cancelled')
      message.error(t('consent:result.cancel'))
    } else if (_consent.status === 'PENDING') {
      // Save coding - happens with AES and Swiss mobile if SMS has not reached recipient and consent has not been cancelled
      await new ReleaseActivityUseCase({
        activityId: task.activity as string,
      }).release()
      setShowAuthentication(false)
      console.error('consent is still pending')
      message.error(t('consent:result.pending'))
    } else if (_consent.status === 'SUCCEEDED') {
      message.success(t('consent:result.success'))
      setShowAuthentication(false)
      if (task.redirectUrl) {
        window.location.href = task.redirectUrl
      } else {
        if (processingInviteTask) {
          resetUserInfo()
          resetAccessToken()
          sessionStorage.removeItem('pivt')
          history.push('/')
        } else {
          history.push('/tasks')
        }
      }
    } else {
      setShowAuthentication(false)
      console.error('reached timeout for consent')
      message.warn(t('consent:result.timeout'))
    }
  }

  const handleSign = () => {
    setInProgress(true)
    signDocument()
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setFastSignature(undefined)
        setInProgress(false)
      })
  }

  const handleChangeVisual = (id: string) => {
    setVisualId(id)
    localStorage.setItem(`${userInfo._id}.visualId`, id)
    setVisual(
      visuals.find((_visual) => {
        return _visual._id === id
      }),
    )
  }

  const handleChangeTrustLevel = (value: string) => {
    setTrustLevel(value as TTrustLevel)
  }

  const handleOrganizationChange = (value: string) => {
    setOrganization(value)
    localStorage.setItem(`${userInfo._id}.organization`, value)
  }

  const handleReasonChange = (value: string) => {
    setReason(value)
    localStorage.setItem(`${userInfo._id}.reason`, value)
  }

  const handleRoleChange = (value: string) => {
    setRole(value)
    localStorage.setItem(`${userInfo._id}.role`, value)
  }

  const handlePlaceChange = (value: string) => {
    setPlace(value)
    localStorage.setItem(`${userInfo._id}.place`, value)
  }

  const handleUserTextChange = (value: string) => {
    setUserText(value)
    localStorage.setItem(`${userInfo._id}.userText`, value)
  }

  const toggleShowDetails = () => {
    setShowDetails(!showDetails)
  }

  const handleChangeJurisdiction = (_jurisdiction: TJurisdiction) => {
    setJurisdiction(_jurisdiction)
    localStorage.setItem(`${userInfo._id}.jurisdiction`, _jurisdiction)
  }

  const handleMobileConfirmed = () => {
    setAlertMobile(false)
  }

  useEffect(() => {
    if (scrollDown) {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }, [scrollDown])

  useEffect(() => {
    verifyEvidence()
  }, [trustLevel, jurisdiction])

  useEffect(() => {
    if (task && userInfo) {
      let _userPlan: TMemberPlan = 'SES'
      if (userInfo.memberships) {
        _userPlan = userInfo.memberships[0].plan
      }

      // if the task has a trust level set we use this
      // otherwise we set the trust level according to the membership
      // fallback is always SES
      if (task.trustLevel) {
        setTrustLevel(task.trustLevel)
      } else {
        switch (_userPlan) {
          case 'QES':
            setTrustLevel('QES')
            break
          case 'PREMIUM':
            setTrustLevel('QES')
            break
          case 'PARTNER':
            setTrustLevel('QES')
            break
          case 'AES':
            setTrustLevel('AES')
            break
          default:
            setTrustLevel('SES')
            break
        }
      }

      setTitle(task.title)
      setJurisdiction(
        task.jurisdiction || (localStorage.getItem(`${userInfo._id}.jurisdiction`) as TJurisdiction) || 'ZERTES',
      )
      setOrganization(account?.organization?.businessName || localStorage.getItem(`${userInfo._id}.organization`) || '')
      setReason(localStorage.getItem(`${userInfo._id}.reason`) || '')
      setRole(localStorage.getItem(`${userInfo._id}.role`) || '')
      setPlace(localStorage.getItem(`${userInfo._id}.place`) || '')
      setUserText(localStorage.getItem(`${userInfo._id}.userText`) || '')
    }
  }, [task])

  useEffect(() => {
    if (userInfo) {
      retrieveVisuals()
    }

    if (userInfo && !task && queryTaskId) {
      new QueryTasksUseCase()
        .query({
          id: queryTaskId,
        })
        .then((_tasks) => {
          if (_tasks && _tasks.length > 0) {
            setTask(_tasks[0])
            history.replace('/consent')
          } else {
            console.warn(`Task ${queryTaskId} could not be found`)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [userInfo])

  useEffect(() => {
    if (accessToken) {
      new GetUserUseCase().getUser().then((_userInfo) => {
        setUserInfo(_userInfo)
      })
    }
  }, [accessToken])

  useEffect(() => {
    if (queryAccessToken) {
      setAccessToken(queryAccessToken)
      queryAccessToken = undefined
    }
  }, [queryAccessToken])

  useEffect(() => {
    if (noAccessToken && !queryTaskId) history.replace('/signin')
  }, [noAccessToken])

  return (
    <div>
      <NavBar />

      <PageHeader
        title={title}
        pages={[
          {
            name: t('consent:title'),
          },
        ]}
      />

      {/**
       * Steps
       */}
      <div className="container max-w-3xl mx-auto text-base bg-white border-2 border-gray-100 mt-4 p-4 shadow-sm rounded-md">
        <SectionTitle title={t('consent:title')} />

        <Alert
          className="rounded mt-2"
          message={t('consent:alert.notice')}
          description={t('consent:alert.documentViewed')}
          showIcon
          type="info"
        />

        {inProgress ? <Progress percent={timeLeft} showInfo={false} status="active" /> : null}

        <ConsentAuthentication inProgress={showAuthentication} consentUrl={consentUrl} message={consentMessage} />

        {!showAuthentication ? (
          <Spin spinning={inProgress} size="large">
            {/**
             * Visual
             */}
            <div className="my-2">
              <FieldTitle title={t('consent:visual')} />
              <div className="w-full">
                <Select
                  className="rounded"
                  value={visualId}
                  size="large"
                  style={{ width: '100%' }}
                  onChange={(value) => handleChangeVisual(value)}
                >
                  {visuals.map((_visual) => {
                    return (
                      <Option key={_visual._id} value={_visual._id}>
                        {_visual.displayName}
                      </Option>
                    )
                  })}
                </Select>
              </div>
            </div>

            {/**
             * Preview
             */}
            {visual ? (
              <SignaturePreview
                visual={visual}
                fullName={`${userInfo.firstName} ${userInfo.familyName}`}
                organization={organization}
                place={place}
                role={role}
                reason={reason}
                userText={userText}
              />
            ) : (
              <div className="bg-white w-full h-24 md:h-52" />
            )}

            <div className="my-2">
              <FieldTitle title={t('consent:trustLevel.label')} />
              <div className="w-full">
                <Select
                  className="rounded"
                  value={trustLevel}
                  size="large"
                  style={{ width: '100%' }}
                  onChange={(value: TTrustLevel) => {
                    handleChangeTrustLevel(value)
                  }}
                >
                  {task.trustLevel === 'SES' ? (
                    <Option value={'SES' as TTrustLevel}>{t('consent:trustLevel.ses.name')}</Option>
                  ) : null}
                  {task.trustLevel === 'SES' || task.trustLevel === 'AES' ? (
                    <Option value={'AES' as TTrustLevel}>{t('consent:trustLevel.aes.name')}</Option>
                  ) : null}
                  {task.trustLevel === 'SES' || task.trustLevel === 'AES' || task.trustLevel === 'QES' ? (
                    <Option value={'QES' as TTrustLevel}>{t('consent:trustLevel.qes.name')}</Option>
                  ) : null}
                </Select>
              </div>
            </div>

            {/**
             * Jurisdiction
             */}
            {trustLevel !== 'SES' && trustLevel !== 'AES' ? (
              <div className="my-4">
                <FieldTitle title={t('consent:jurisdiction.label')} />
                <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  {/**
                   * Switzerland, ZertES
                   */}
                  <div
                    className={`col-span-3 p-2 rounded border-2 shadow-sm hover:border-acs-blue-500 hover:cursor-pointer ${
                      jurisdiction === 'ZERTES' ? 'border-acs-blue-500' : 'border-gray-200'
                    }`}
                  >
                    <div
                      className="w-full flex flex-row items-center justify-between"
                      onClick={() => handleChangeJurisdiction('ZERTES')}
                    >
                      <div className="w-full flex flex-row items-center ">
                        <img
                          className="w-12 h-auto"
                          src={`${process.env.PUBLIC_URL}/assets/images/switzerland.png`}
                          alt="switzerland"
                        />
                        <div
                          className={`ml-2 ${
                            jurisdiction === 'ZERTES' ? 'font-semibold text-black' : 'font-normal text-gray-500'
                          }`}
                        >
                          {t('consent:jurisdiction.zertes.name')}
                        </div>
                      </div>
                      <div>
                        {jurisdiction === 'ZERTES' ? <CheckCircleIcon className="w-7 h-7 text-green-600" /> : null}
                      </div>
                    </div>
                  </div>

                  {/**
                   * Europe, eIDAS
                   */}
                  <div
                    className={`col-span-3 p-2 rounded border-2 shadow-sm hover:border-acs-blue-500 hover:cursor-pointer ${
                      jurisdiction === 'EIDAS' ? 'border-acs-blue-500' : 'border-gray-200'
                    }`}
                  >
                    <div
                      className="w-full flex flex-row items-center justify-between"
                      onClick={() => handleChangeJurisdiction('EIDAS')}
                    >
                      <div className="w-full flex flex-row items-center ">
                        <img
                          className="w-12 h-auto"
                          src={`${process.env.PUBLIC_URL}/assets/images/europe.png`}
                          alt="switzerland"
                        />
                        <div
                          className={`ml-2 ${
                            jurisdiction === 'EIDAS' ? 'font-semibold text-black' : 'font-normal text-gray-500'
                          }`}
                        >
                          {t('consent:jurisdiction.eidas.name')}
                        </div>
                      </div>
                      <div>
                        {jurisdiction === 'EIDAS' ? <CheckCircleIcon className="w-7 h-7 text-green-600" /> : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="flex flex-row justify-between mt-4 items-center">
              <SectionTitle title={t('consent:details')} />
              {!showDetails ? (
                <PlusCircleIcon
                  className="w-6 h-6 text-gray-500 hover:cursor-pointer hover:text-acs-blue-500"
                  onClick={toggleShowDetails}
                />
              ) : (
                <MinusCircleIcon
                  className="w-6 h-6 text-gray-500 hover:cursor-pointer hover:text-acs-blue-500"
                  onClick={toggleShowDetails}
                />
              )}
            </div>

            {showDetails ? (
              <div>
                {/**
                 * Organization
                 */}
                <div className="my-2">
                  <FieldTitle title={t('consent:organization')} />
                  <Input
                    className="rounded"
                    size="large"
                    placeholder={t('consent:organization')}
                    value={organization}
                    onChange={(e) => handleOrganizationChange(e.target.value)}
                  ></Input>
                </div>

                {/**
                 * Reason
                 */}
                {/* {trustLevel === 'QES' || (trustLevel === 'AES' && !isAESnoIdent) ? ( */}
                <div className="my-2">
                  <FieldTitle title={t('consent:reason')} />
                  <Input
                    className="rounded"
                    size="large"
                    placeholder={t('consent:reason')}
                    value={reason}
                    onChange={(e) => handleReasonChange(e.target.value)}
                  ></Input>
                </div>
                {/* ) : null} */}

                {/**
                 * Role
                 */}
                <div className="my-2">
                  <FieldTitle title={t('consent:role')} />
                  <Input
                    className="rounded"
                    size="large"
                    placeholder={t('consent:role')}
                    value={role}
                    onChange={(e) => handleRoleChange(e.target.value)}
                  ></Input>
                </div>

                {/**
                 * Place
                 */}
                <div className="my-2">
                  <FieldTitle title={t('consent:place')} />
                  <Input
                    className="rounded"
                    size="large"
                    placeholder={t('consent:place')}
                    value={place}
                    onChange={(e) => handlePlaceChange(e.target.value)}
                  ></Input>
                </div>

                {/**
                 * User text
                 */}
                <div className="my-2">
                  <FieldTitle title={t('consent:userText')} />
                  <Input
                    className="rounded"
                    size="large"
                    placeholder={t('consent:userText')}
                    value={userText}
                    onChange={(e) => handleUserTextChange(e.target.value)}
                  ></Input>
                </div>
              </div>
            ) : null}

            {alertMobile ? (
              <Alert
                className="rounded mt-4"
                message={t('consent:alert.warn')}
                description={t('consent:alert.confirmMobile')}
                showIcon
                type="error"
              />
            ) : null}

            {alertSwissMobile ? (
              <Alert
                className="rounded mt-4"
                message={t('consent:alert.warn')}
                description={t('consent:alert.swissMobileRequired')}
                showIcon
                type="error"
              />
            ) : null}

            <MobileConfirmation show={alertMobile} onConfirmed={handleMobileConfirmed} />

            {alertIdentification ? (
              <Alert
                className="rounded mt-4"
                message={t('consent:alert.warn')}
                description={t('consent:alert.identificationRequired')}
                showIcon
                type="error"
              />
            ) : null}

            {alertBlocked ? (
              <Alert
                className="rounded mt-4"
                message={t('consent:alert.warn')}
                description={t('consent:alert.activityBlocked')}
                showIcon
                type="error"
              />
            ) : null}

            {!alertBlocked && !alertMobile && !alertSwissMobile && !alertIdentification ? (
              <div className="flex flex-auto mt-4 justify-end">
                <Button
                  className="rounded font-semibold"
                  size="large"
                  type="primary"
                  onClick={handleSign}
                  loading={inProgress}
                >
                  {t('consent:action.sign')}
                </Button>
              </div>
            ) : null}
          </Spin>
        ) : null}
      </div>
    </div>
  )
}

export default Consent
